<template>
	<MobileNavTemplate :styles="styles" :show-add-funds="showAddFunds">
		<template #flyout-content>
			<div class="mobile-nav__flyout-content">
				<div
					class="mobile-nav__item display-flex align-items-center delta pt-100 pl-100"
					:class="{ 'bg-color-gold-20': isProUser }"
				>
					<MobileNavUserHeader :nav="nav" />
				</div>
				<ul>
					<li class="mobile-nav__item">
						<BaseLink
							:link="{ router: 'profile-personal-information-view' }"
							classes="mobile-nav__item-link display-flex align-items-center p-100"
							data-dd-action-name="mobile menu Profile and Preferences"
							><BaseSvgIcon name="user-outline" width="24" height="24" class="mr-50 dark" />Profile &amp;
							Preferences</BaseLink
						>
					</li>
					<li v-if="nav.inviteLink" class="mobile-nav__item">
						<BaseLink
							classes="mobile-nav__item-link display-flex align-items-center p-100 bt"
							data-dd-action-name="mobile menu referral"
							:link="{ href: (nav.inviteLink as Link).href }"
						>
							<BaseSvgIcon name="gift" class="mr-50 text-color-rust" width="24" height="24" />
							Earn a {{ currency(referralAmount, 0) }} voucher
						</BaseLink>
					</li>
				</ul>
				<ul v-if="nav.multipleInvestmentEntities" class="bb bt">
					<span class="eta py-25 display-block text-color-content-tertiary ml-100"> Your accounts </span>
					<NavInvestmentEntityList />
				</ul>
				<div v-for="(link, id) in nav.links" :key="id">
					<NavigationLinkMobile :link="link" />
				</div>
				<ul class="bt">
					<li class="mobile-nav__item bb">
						<BaseLink
							v-if="!isAdvisor"
							:link="{ router: 'settings-account-information-redirect' }"
							data-dd-action-name="mobile menu settings"
							classes="mobile-nav__item-link display-flex align-items-center p-100"
							><BaseSvgIcon name="gear-outline" width="24" height="24" class="mr-50" />Settings</BaseLink
						> </li
					><li class="mobile-nav__item bb">
						<BaseLink
							classes="mobile-nav__item-link display-flex align-items-center p-100"
							mixpanel-target="Log out"
							data-dd-action-name="mobile menu log out"
							:link="{ router: 'logout-success' }"
						>
							<BaseSvgIcon name="logout" width="20" height="20" class="mr-100" />
							Log out
						</BaseLink>
					</li>
				</ul>
			</div>
		</template>
	</MobileNavTemplate>
</template>

<script setup lang="ts">
import AdvisorAuthenticatedNav from '@classes/AdvisorAuthenticatedNav';
import AuthenticatedNav from '@classes/AuthenticatedNav';
import { computed } from 'vue';
import { currency } from '@filters/shared-filters';
import { HeaderStyle } from 'types/header';
import { InvestmentEntity } from 'types/investment-entity';
import { isAdvisor } from '@utils/composables';
import { Link } from 'types/layout';
import MobileNavTemplate from '@components/header/navigation/mobile/mobile-nav-template.vue';
import MobileNavUserHeader from './mobile-nav-user-header.vue';
import NavigationLinkMobile from '@components/header/navigation/mobile/navigation-link-mobile.vue';
import NavInvestmentEntityList from '../nav-investment-entity-list.vue';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useUserStore } from '@stores/user';

interface Props {
	styles: HeaderStyle;
}
defineProps<Props>();

const investmentEntityStore = useInvestmentEntityStore();
const userStore = useUserStore();

const isProUser = computed((): boolean => userStore.isSubscriptionActive);

const investmentEntityObject = computed((): InvestmentEntity => {
	return investmentEntityStore.investmentEntityObject;
});

const nav = computed((): AuthenticatedNav | AdvisorAuthenticatedNav => {
	return isAdvisor.value
		? new AdvisorAuthenticatedNav(userStore.user)
		: new AuthenticatedNav(userStore.user, investmentEntityObject.value);
});

const referralAmount = computed((): string => {
	return userStore.invitationDetails?.invitationDetails.rewardPerInvite ?? '';
});

const showAddFunds = computed((): boolean => {
	return !isAdvisor.value && !investmentEntityStore.hasNeverBeenAnInvestor;
});
</script>
<style lang="scss" scoped>
@use '../../../../styles/constants/colors.scss' as *;

.mobile-nav__item.bg-color-gold-light {
	border-bottom: 1px solid gold(90);
}
</style>
