import { clearCookie, getCookieValue, setItemInSessionStorage } from '@utils/web-storage';
import { computed, markRaw, ref } from 'vue';
import { CurrentBanner, DefaultBanner } from 'types/banner';
import { getAccessTokenFromCornice, verifyPassword } from '@api/login';
import { getLDUserContext, recordLD } from '@utils/launch-darkly';
import { initialize, LDClient, LDContext, LDEvaluationDetail, LDOptions } from 'launchdarkly-js-client-sdk';
import { OauthTokenResponse, TokenScope } from 'types/security';
import { RouteTransition, Toast } from 'types/layout';
import { checkThirdParty } from '@utils/bot-detection';
import { CurrentModal } from 'types/modal';
import { datadogRum } from '@datadog/browser-rum';
import { defineStore } from 'pinia';
import { getDistinctId } from '@utils/analytics';
import { getUserEntityById } from '@utils/user';
import { LDFeatureFlagGroup } from 'types/launch-darkly';
import { logError } from '@utils/error-tracking';
import mixpanel from 'mixpanel-browser';
import { revokeToken } from '@api/logout';
import { RouteLocationNormalized } from 'vue-router';
import router from '@router';
import { ScreenSizeType } from 'types/client';
import { storageKeyLoginRedirect } from '@constants/storage-keys';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useUserStore } from '@stores/user';

export const useAppStore = defineStore('app', () => {
	const currentModal = ref<CurrentModal | null>(null);
	const currentBanner = ref<CurrentBanner | null>(null);
	const defaultBanner = ref<DefaultBanner | null>(null);

	const intercomEnabled = ref(false);
	const ldClient = ref<LDClient | null>(null);

	const userId = ref('');
	const oauthToken = ref('');
	const tokenScope = ref<TokenScope>('UNAUTHENTICATED');
	const refreshTokenTimeout = ref<undefined | number>(undefined);
	const isCorniceSubstituteUser = ref(false);
	const isMobileWebview = ref(false);
	const mixpanelInitialized = ref(false);
	const userIsIdle = ref(false);
	const wafIsLoaded = ref(false);
	const isMultipleAumMilestonesEnabled = ref(false);

	const routeTransition = ref<RouteTransition>({
		scope: 'fullpage',
		name: 'fade'
	});

	const screenSize = ref<ScreenSizeType>('DESKTOP');

	const subPageHeight = ref<number | null>(null);

	const toasts = ref<Array<Toast>>([]);

	const isAdvisor = computed((): boolean => {
		const userStore = useUserStore();
		return (
			!!userStore.user?.isInvestmentAdvisor ||
			tokenScope.value.includes('ADVISOR_ACCESS') ||
			tokenScope.value === 'ADVISOR_PRIVILEGED_ACCESS' ||
			tokenScope.value === 'ADVISOR_READ_ACCESS'
		);
	});

	const isDesktop = computed((): boolean => {
		return screenSize.value === 'DESKTOP';
	});

	const isMobile = computed((): boolean => {
		return screenSize.value === 'MOBILE';
	});

	const isAuthenticated = computed((): boolean => {
		const unAuthenticatedScopes: TokenScope[] = [
			'GOOGLE_AUTH_SETUP',
			'APPLE_AUTH_SETUP',
			'GOOGLE_AUTH_SETUP REQUIRES_ADDITIONAL_AUTH',
			'APPLE_AUTH_SETUP REQUIRES_ADDITIONAL_AUTH',
			'REQUIRES_ADDITIONAL_AUTH',
			'UNAUTHENTICATED'
		];
		// Order is not guaranteed in the case of multiple scopes
		const reversedTokenScope = tokenScope.value.split(' ').reverse().join(' ') as TokenScope;
		return (
			!!oauthToken.value &&
			!unAuthenticatedScopes.includes(tokenScope.value) &&
			!unAuthenticatedScopes.includes(reversedTokenScope)
		);
	});

	const isVerified = computed((): boolean => {
		return isAuthenticated.value && !tokenScope.value.includes('UNVERIFIED');
	});

	const isThirdPartyLogin = computed((): boolean => {
		return isAuthenticated.value && tokenScope.value === 'THIRD_PARTY_ACCESS';
	});

	const isReadOnlyAccess = computed((): boolean => {
		return (
			tokenScope.value === 'READ_ACCESS' || tokenScope.value === 'ADVISOR_READ_ACCESS' || isThirdPartyLogin.value
		);
	});

	const isProUser = computed((): boolean => {
		const userStore = useUserStore();
		return userStore.isSubscriptionActive;
	});

	const currentRefreshTokenTimeout = computed((): number | undefined => {
		return refreshTokenTimeout.value;
	});

	function setRefreshTokenTimeout(timeout: number): void {
		clearRefreshTokenTimeout();

		const refreshTimeout = setTimeout(async () => {
			if (userIsIdle.value && isAuthenticated.value) {
				setItemInSessionStorage(storageKeyLoginRedirect, router.currentRoute.value.fullPath);
				resetAuthData();
				await router.push({ name: 'login', query: { message: 'expired-session' } });
			}
		}, timeout);

		refreshTokenTimeout.value = refreshTimeout as unknown as number;
	}

	function clearRefreshTokenTimeout(): void {
		clearTimeout(currentRefreshTokenTimeout.value);
		refreshTokenTimeout.value = undefined;
	}

	async function setUserAuthData(loginResponse: OauthTokenResponse): Promise<void> {
		userId.value = loginResponse.user_id;
		oauthToken.value = loginResponse.access_token;
		tokenScope.value = loginResponse.scope;
		const refreshTokenTimeoutInMs = Number(loginResponse.refresh_expires_in) * 1000;
		setRefreshTokenTimeout(refreshTokenTimeoutInMs);
		initializeLaunchDarklyInstance();

		if (loginResponse.scope === 'THIRD_PARTY_ACCESS') {
			mixpanel.disable();
		}
	}

	async function authenticateFromMobileWebview(): Promise<void> {
		const investmentEntityStore = useInvestmentEntityStore();
		const accessToken = getCookieValue('access_token');
		const investmentEntityId = getCookieValue('mobileInvestmentEntityId');

		if (accessToken && isMobileWebview.value) {
			const userStore = useUserStore();
			oauthToken.value = accessToken;
			tokenScope.value = 'FULL_ACCESS';
			isMobileWebview.value = true;
			clearCookie('access_token');
			clearCookie('mobileInvestmentEntityId');
			if (!userStore.user) {
				await userStore.getUser();
			}

			if (investmentEntityId) {
				const entity = getUserEntityById(investmentEntityId);
				if (entity) await investmentEntityStore.setInvestmentEntity(entity);
			}
		} else {
			return Promise.reject(new Error());
		}
	}

	async function revokeMobileWebviewToken(): Promise<void> {
		clearCookie('access_token');
		oauthToken.value = '';
		tokenScope.value = 'UNAUTHENTICATED';
	}

	async function authenticateFromCornice(): Promise<void> {
		const corniceResponse = await getAccessTokenFromCornice();
		if (corniceResponse.accessToken) {
			oauthToken.value = corniceResponse.accessToken;
			tokenScope.value = corniceResponse.scope;
			isCorniceSubstituteUser.value = true;
		} else {
			oauthToken.value = '';
			tokenScope.value = 'UNAUTHENTICATED';
			isCorniceSubstituteUser.value = false;
			return Promise.reject(new Error());
		}
	}

	async function revokeUserToken(): Promise<void> {
		try {
			await revokeToken();
		} catch {
			// This failure is usually a 401 error where token is already expired.
		} finally {
			resetAuthData();
		}
	}

	async function isFeatureEnabled(featureFlag: string): Promise<boolean> {
		if (!ldClient.value) {
			await initializeLaunchDarklyInstance();
		}

		if (ldClient.value) {
			await ldClient.value.waitUntilReady();
			return ldClient.value.variation(featureFlag, false) as boolean;
		}

		return false;
	}

	async function getLdAbTestGroup(featureFlag: string): Promise<string> {
		if (!ldClient.value) {
			await initializeLaunchDarklyInstance();
		}

		if (!getDistinctId()) {
			return '';
		}

		if (ldClient.value) {
			await ldClient.value.waitUntilReady();
			await recordLD(featureFlag);
			const variation = ldClient.value.variation(featureFlag) as string;

			if (!variation) {
				const details = ldClient.value.variationDetail(featureFlag);

				logError(`Launch Darkly Test Group Config Issue: ${featureFlag}`, 'info', {
					key: 'Error details',
					data: details
				});
			}

			return variation;
		}

		return '';
	}

	async function isUserInLDTestGroup(params: LDFeatureFlagGroup): Promise<boolean> {
		if (checkThirdParty()) {
			return false;
		}

		const testGroup = await getLdAbTestGroup(params.featureFlag);
		return testGroup === params.testGroup;
	}

	async function initializeLaunchDarklyInstance(): Promise<void> {
		const investmentEntityStore = useInvestmentEntityStore();
		if (!ldClient.value) {
			const mixpanelId = getDistinctId();
			const clientId = `${import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID}`;
			const ldContext: LDContext = getLDUserContext(mixpanelId);
			const ldOptions: LDOptions = {
				inspectors: [
					{
						name: 'dd-inspector',
						type: 'flag-used',
						method: (flagKey: string, flagDetail: LDEvaluationDetail) => {
							datadogRum.addFeatureFlagEvaluation(flagKey, flagDetail.value);
						}
					}
				]
			};

			if (mixpanelId) {
				const ldClientValue = initialize(clientId, ldContext, ldOptions);
				ldClient.value = ldClientValue;
			}
		} else {
			const ldUserContext: LDContext = getLDUserContext(getDistinctId());

			const ldIeContext: LDContext = {
				kind: 'investment_entity',
				key: investmentEntityStore.externalId,
				client_type: 'web'
			};
			if (isAuthenticated.value && investmentEntityStore.externalId) {
				const multiContext: LDContext = {
					kind: 'multi',
					user: ldUserContext,
					investment_entity: ldIeContext
				};
				ldClient.value.identify(multiContext);
			} else {
				ldClient.value.identify(ldUserContext);
			}
		}
	}

	async function getCurrentBanner(routeKey = ''): Promise<void> {
		const hideBannerList = [
			'/acq-plus/start',
			'/campaigns/fund/flagship',
			'/campaigns/fund/income',
			'/campaigns/fund/innovation',
			'/partner',
			'/data-visualizations/',
			'/login',
			'/reits/26/view',
			'/offerings/26/view',
			'/fundriseintervalfund-iframe-only/24',
			'/fundriseintervalfund-iframe-only/25',
			'/fundriseintervalfund-iframe-only/26',
			'/connect-api',
			'/404',
			'/forbidden',
			'/maintenance',
			'/questionnaire',
			'/forgotpassword',
			'/education/2024-year-end-letter-to-investors',
			'/invitation-program/welcome'
		];
		const hideBanner = hideBannerList.filter((hideBannerPath) => {
			return routeKey.includes(hideBannerPath);
		});

		if (hideBanner.length > 0 || isMobileWebview.value) {
			updateCurrentBanner(null);
		} else {
			updateCurrentBanner(defaultBanner.value);
		}
	}

	async function getPrivilegedToken(password: string): Promise<void> {
		const oauthResponse = await verifyPassword(password);
		setUserAuthData(oauthResponse);
	}

	function toggleIntercom(enabled: boolean): void {
		intercomEnabled.value = enabled;
	}

	async function storeIsMultipleAumMilestonesEnabled(): Promise<void> {
		const featureEnabled = await isFeatureEnabled('multiple-aum-milestones');
		isMultipleAumMilestonesEnabled.value = featureEnabled;
	}

	function updateCurrentModal(currentModalValue: CurrentModal | null): void {
		if (currentModalValue?.modal) {
			currentModalValue.modal = markRaw(currentModalValue.modal);
		}

		currentModal.value = currentModalValue;
	}

	function updateCurrentBanner(currentBannerValue: CurrentBanner | null): void {
		if (currentBannerValue?.banner) {
			currentBannerValue.banner = markRaw(currentBannerValue.banner);
		}
		currentBanner.value = currentBannerValue;
	}

	function addToast(toastToAdd: Toast): void {
		const newToast = toasts.value.filter((toast) => toast.message === toastToAdd.message).length <= 0;

		if (newToast) {
			toasts.value.push(toastToAdd);
		}
	}

	function dismissToast(dismissed: Toast): void {
		toasts.value = toasts.value.filter((toast) => toast.message !== dismissed.message);
	}

	function updateRouteTransition(payload: { to: RouteLocationNormalized; from: RouteLocationNormalized }): void {
		let toStepIndex = undefined;
		if (typeof payload?.to?.meta?.step === 'function') {
			toStepIndex = payload.to.meta.step(payload.to);
		} else if (typeof payload?.to?.meta?.step === 'number') {
			toStepIndex = payload.to.meta.step;
		}

		let fromStepIndex = undefined;
		if (typeof payload?.from?.meta?.step === 'function') {
			fromStepIndex = payload.from.meta.step(payload.from);
		} else if (typeof payload?.from?.meta?.step === 'number') {
			fromStepIndex = payload.from.meta.step;
		}

		if (toStepIndex && fromStepIndex) {
			routeTransition.value.scope = 'subpage';
			if (fromStepIndex < toStepIndex) {
				routeTransition.value.name = 'slide-left';
			} else if (fromStepIndex > toStepIndex) {
				routeTransition.value.name = 'slide-right';
			} else {
				routeTransition.value.name = 'fade';
			}
		} else {
			routeTransition.value.scope = 'fullpage';
			routeTransition.value.name = 'fade';
		}
	}

	function updateScreenSize(newScreenSize: ScreenSizeType): void {
		if (newScreenSize !== screenSize.value) {
			screenSize.value = newScreenSize;
		}
	}

	function updateSubPageHeight(height: number): void {
		if (height !== subPageHeight.value) {
			subPageHeight.value = height;
		}
	}

	function resetAuthData(): void {
		const userStore = useUserStore();

		userId.value = '';
		oauthToken.value = '';
		tokenScope.value = 'UNAUTHENTICATED';
		isCorniceSubstituteUser.value = false;
		clearRefreshTokenTimeout();
		updateCurrentModal(null);
		userStore.user = null;
		clearCookie('userLoggedIn');
	}

	function $reset(): void {
		updateCurrentModal(null);
		updateCurrentBanner(null);
		userId.value = '';
		oauthToken.value = '';
		isCorniceSubstituteUser.value = false;
		isMobileWebview.value = false;
		updateScreenSize('DESKTOP');
		tokenScope.value = 'UNAUTHENTICATED';
		refreshTokenTimeout.value = undefined;
		userIsIdle.value = false;
		clearRefreshTokenTimeout();
		wafIsLoaded.value = false;
	}

	return {
		currentModal,
		currentBanner,
		defaultBanner,
		intercomEnabled,
		ldClient,
		userId,
		oauthToken,
		tokenScope,
		refreshTokenTimeout,
		isCorniceSubstituteUser,
		isMobileWebview,
		mixpanelInitialized,
		userIsIdle,
		wafIsLoaded,
		isMultipleAumMilestonesEnabled,
		routeTransition,
		screenSize,
		subPageHeight,
		toasts,
		isAdvisor,
		isAuthenticated,
		isDesktop,
		isProUser,
		isVerified,
		isThirdPartyLogin,
		isReadOnlyAccess,
		currentRefreshTokenTimeout,
		setUserAuthData,
		authenticateFromMobileWebview,
		revokeMobileWebviewToken,
		authenticateFromCornice,
		revokeUserToken,
		isFeatureEnabled,
		getLdAbTestGroup,
		isUserInLDTestGroup,
		initializeLaunchDarklyInstance,
		getCurrentBanner,
		getPrivilegedToken,
		toggleIntercom,
		storeIsMultipleAumMilestonesEnabled,
		updateCurrentModal,
		updateCurrentBanner,
		addToast,
		dismissToast,
		updateRouteTransition,
		updateScreenSize,
		updateSubPageHeight,
		resetAuthData,
		isMobile,
		$reset
	};
});
