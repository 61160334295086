import { NavigationGuard, RouteLocationRaw } from 'vue-router';
import { getNextRoute } from '@utils/checkout';
import { SignupStepName } from 'types/checkout';
import { signupStepToRouteMap } from '@constants/checkout';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useSignupStore } from '@stores/signup';

const featureAppDownMaintenanceWindow = 'app-down-maintenance-window';

export const canEnterCheckout = async (): Promise<RouteLocationRaw | undefined> => {
	const appStore = useAppStore();
	const signupStore = useSignupStore();
	const investmentEntityStore = useInvestmentEntityStore();
	const isMaintenanceWindow = await appStore.isFeatureEnabled(featureAppDownMaintenanceWindow);
	if (isMaintenanceWindow) {
		return { name: 'maintenance' };
	} else {
		await signupStore.getUserCheckoutDetailsData();
		if (investmentEntityStore.isLegacyInvestorWithoutPlan) {
			return { name: 'ria-upgrade', replace: true };
		} else if (investmentEntityStore.isIraEntity) {
			return { name: 'ira-start', replace: true };
		} else if (signupStore.isIraCheckout) {
			return { name: 'ira-signup-start' };
		} else if (investmentEntityStore.isReitInvestor) {
			return { name: 'account-overview', replace: true };
		} else {
			return;
		}
	}
};

export const signupGuard: NavigationGuard = async (to, from, next) => {
	const routeLocation = await canEnterCheckout();

	if (routeLocation) {
		next(routeLocation);
	} else {
		next();
	}
};

export const resumeCheckoutGuard: NavigationGuard = async (to, from, next) => {
	const signupStore = useSignupStore();
	let routeLocation;
	const appStore = useAppStore();
	if (appStore.isAuthenticated) {
		routeLocation = await canEnterCheckout();
	} else {
		routeLocation = { name: 'checkout-start' };
	}

	if (routeLocation) {
		next(routeLocation);
	} else {
		const route = await getNextRoute(signupStore.signupCheckoutSelections?.currentStep);
		next(route);
	}
};

export const navigateToNextStepGuard: NavigationGuard = async (to, from, next) => {
	const signupStore = useSignupStore();
	let currentStep = (Object.keys(signupStepToRouteMap) as Array<SignupStepName>).find(
		(key) => signupStepToRouteMap[key] === to.name
	);

	if (to.name === 'checkout-acknowledgments') {
		if (signupStore.isPresetOfferingFlow) {
			currentStep = 'ORDER_ACKNOWLEDGEMENTS_STEP';
		} else {
			currentStep = 'DRIP_PREFERENCE_STEP';
		}
	}

	if (
		currentStep &&
		['TRUST_INFORMATION_STEP', 'JOINT_INFORMATION_STEP', 'ENTITY_INFORMATION_STEP'].includes(currentStep)
	) {
		next();
	} else {
		await signupStore.getUserCheckoutDetailsData(currentStep);

		if (currentStep === signupStore.signupCheckoutSelections?.currentStep?.signupStepName) {
			next();
		} else {
			next(await getNextRoute(signupStore.signupCheckoutSelections?.currentStep));
		}
	}
};

export const planMenuGuard: NavigationGuard = async (to, from, next) => {
	const appStore = useAppStore();
	const investmentEntityStore = useInvestmentEntityStore();
	const isMaintenanceWindow = await appStore.isFeatureEnabled(featureAppDownMaintenanceWindow);
	if (isMaintenanceWindow) {
		next({ name: 'maintenance' });
	} else if (investmentEntityStore.isLegacyInvestorWithoutPlan) {
		next({ name: 'ria-upgrade', replace: true });
	} else if (investmentEntityStore.isReitInvestor) {
		next({ name: 'account-overview', replace: true });
	} else {
		next();
	}
};

export const checkoutAuthGuard: NavigationGuard = async (to, from, next) => {
	const appStore = useAppStore();
	const isMaintenanceWindow = await appStore.isFeatureEnabled(featureAppDownMaintenanceWindow);
	if (isMaintenanceWindow) {
		next({ name: 'maintenance' });
	} else if (appStore.isAuthenticated) {
		next({ name: 'checkout-resume' });
	} else {
		next();
	}
};
