import { apiRequest, apiSecureRequest, investmentEntityRequest } from '@utils/request';
import axios, { AxiosResponse } from 'axios';
import {
	CheckoutAutoInvestDetails,
	CheckoutOrderGroupDetails,
	CheckoutOrderResponse,
	CheckoutPaymentAmountDetails,
	CheckoutSuccess,
	EntityAccountDetails,
	JointAccountDetails,
	ResumeCheckoutModuleDetails,
	RewardDetails,
	SignupCheckoutOrderReviewResponse,
	SignupCheckoutSelectionResponse,
	SignupStepName,
	TrustAccountDetails
} from 'types/checkout';
import { Country, TaxInfo } from 'types/form';
import { DripStatus, InvestmentEntity } from 'types/investment-entity';
import { PresetOfferingDetails, RiaPlan } from 'types/plan';
import { DocumentAcknowledgmentsResponse } from 'types/documents';
import { getCookieValue } from '@utils/web-storage';
import { invalidCheckoutState } from '@constants/error-codes';
import { OrderGroupResponse } from 'types/invest';
import { PaymentMethodOptions } from 'types/payment';

export async function completeSignupStep(signupStep: SignupStepName): Promise<SignupCheckoutSelectionResponse> {
	const response = await investmentEntityRequest.put('/signup-checkout/complete-step', { signupStep });
	return response.data;
}

export async function getSignupCheckoutSelections(
	signupStep?: SignupStepName
): Promise<SignupCheckoutSelectionResponse> {
	const response = await investmentEntityRequest.get(
		'/signup-checkout',
		signupStep ? { params: { currentStep: signupStep } } : undefined
	);
	return response.data;
}

export async function updateInvestmentSelections(plan: RiaPlan) {
	const response = await apiSecureRequest.post('/signup-checkout/investment-selections', {
		plan: plan.investmentStrategy,
		planType: plan.planType
	});

	return response.data;
}

export async function clearOrderGroup(): Promise<void> {
	await investmentEntityRequest.post('/signup-checkout/clear-order-group');
}

export async function getCountries(): Promise<Array<Country>> {
	const response = await apiRequest.get('/countries');
	return response.data;
}

export async function getSignupOrderDetails(): Promise<CheckoutOrderResponse> {
	const response = await investmentEntityRequest.get('/signup-checkout/order-details');
	return response.data;
}

export async function getSignupPaymentOptions(): Promise<PaymentMethodOptions> {
	const response = await investmentEntityRequest.get('/signup-checkout/payment-options');
	return response.data;
}

export async function isEligibleForAdditionalInvestment(id: string): Promise<boolean> {
	const response = await apiSecureRequest.get(`/investment-entity/${id}/add-funds-eligibility`);
	return response.data.additionalInvestmentEligibility === 'VALID';
}

export async function submitNewEntityAccountDetails(payload: EntityAccountDetails): Promise<InvestmentEntity> {
	const response = await apiSecureRequest.post('/investment-entity/entity-account', payload);
	return response.data;
}

export async function updateExistingEntityAccountDetails(payload: EntityAccountDetails): Promise<void> {
	await investmentEntityRequest.put(`/entity-account`, payload);
}

export async function getEntityAccountDetails(investmentEntityId: string): Promise<EntityAccountDetails> {
	const response = await apiSecureRequest.get(`/investment-entity/${investmentEntityId}/entity-account`);
	return response.data;
}

export async function submitNewJointAccountDetails(payload: JointAccountDetails): Promise<InvestmentEntity> {
	const response = await apiSecureRequest.post('/investment-entity/joint-account', payload);
	return response.data;
}

export async function updateExistingJointAccountDetails(payload: JointAccountDetails): Promise<void> {
	await investmentEntityRequest.put(`/joint-account`, payload);
}

export async function getJointAccountDetails(): Promise<JointAccountDetails> {
	const response = await investmentEntityRequest.get(`/joint-account`);
	return response.data;
}

export async function submitNewTrustAccountDetails(payload: TrustAccountDetails): Promise<InvestmentEntity> {
	const response = await apiSecureRequest.post(`/investment-entity/trust-account`, payload);
	return response.data;
}

export async function updateExistingTrustAccountDetails(payload: TrustAccountDetails): Promise<void> {
	await investmentEntityRequest.put(`/trust-account`, payload);
}

export async function getTrustAccountDetails(): Promise<TrustAccountDetails> {
	const response = await investmentEntityRequest.get(`/trust-account`);
	return response.data;
}

export async function submitIndividualAccountTaxReportingDetails(payload: TaxInfo): Promise<void> {
	await investmentEntityRequest.post('/individual-account/tax-details', payload);
}

export async function getCheckoutDocumentsAndAgreements(): Promise<DocumentAcknowledgmentsResponse> {
	const response = await investmentEntityRequest.get('/signup-checkout/acknowledgments', {
		suppressToast: (resp: AxiosResponse) => resp.data?.errorCode === invalidCheckoutState
	});
	const data = response.data as DocumentAcknowledgmentsResponse;
	return {
		reitDocuments: data.reitDocuments,
		acknowledgments: data.acknowledgments,
		miscDocuments: data.miscDocuments,
		advisoryDocuments: data.advisoryDocuments,
		reviewOrderAcknowledgements: data.reviewOrderAcknowledgements,
		reviewOrderDisclaimer: data.reviewOrderDisclaimer
	};
}

export async function getCheckoutPaymentAmountDetails(planId: string): Promise<CheckoutPaymentAmountDetails> {
	const responses = await axios.all([
		investmentEntityRequest.get(`/ria-plan/${planId}/investment-range`),
		investmentEntityRequest.get('/signup-checkout/suggested-investment-amounts')
	]);

	return {
		investmentRange: responses[0].data,
		suggestedInvestmentAmounts: responses[1].data.investmentAmounts,
		showPremiumAd: responses[1].data.showPremiumAd
	};
}

export async function getCheckoutPaymentAmountDetailsForPresetOfferingFlow(): Promise<CheckoutPaymentAmountDetails> {
	const responses = await axios.all([
		investmentEntityRequest.get('/signup-checkout/preset-offering-investment-range'),
		investmentEntityRequest.get('/signup-checkout/suggested-investment-amounts')
	]);

	return {
		investmentRange: responses[0].data,
		suggestedInvestmentAmounts: responses[1].data.investmentAmounts,
		showPremiumAd: responses[1].data.showPremiumAd
	};
}

export async function createOrderGroup(payload: CheckoutOrderGroupDetails): Promise<OrderGroupResponse> {
	const response = await investmentEntityRequest.post('/investment/order-group', payload);
	return response.data;
}

export async function updateAutoInvest(payload: CheckoutAutoInvestDetails): Promise<void> {
	await investmentEntityRequest.put('/signup-checkout/auto-invest', payload, {
		suppressToast: (resp: AxiosResponse) => resp.data?.errorCode === invalidCheckoutState
	});
}

export async function updateDrip(dripPreference: DripStatus): Promise<void> {
	await investmentEntityRequest.put(
		'/signup-checkout/drip',
		{ reinvestmentPreference: dripPreference },
		{
			suppressToast: (resp: AxiosResponse) => resp.data?.errorCode === invalidCheckoutState
		}
	);
}

export async function getCheckoutReviewOrderDetails(): Promise<SignupCheckoutOrderReviewResponse> {
	const response = await investmentEntityRequest.get('/signup-checkout/order-review', {
		suppressToast: (resp: AxiosResponse) => resp.data?.errorCode === invalidCheckoutState
	});
	return response.data;
}

export const placeOrder = async (
	orderGroupId: string,
	documentIds: Array<string>,
	suppressToast = false
): Promise<OrderGroupResponse> => {
	const response = await investmentEntityRequest.post(
		`/investment/order-group/${orderGroupId}/place-order`,
		{
			reitDocumentRevisionIds: documentIds
		},
		{ suppressToast: () => suppressToast }
	);
	return response.data;
};

export async function placeFirstOrder(reitDocumentRevisionIds: Array<string>): Promise<OrderGroupResponse> {
	const fbcId = getCookieValue('_fbc');
	const body = {
		reitDocumentRevisionIds,
		...(fbcId && { fbcId })
	};

	const response = await investmentEntityRequest.post(`/signup-checkout/place-order`, body, {
		suppressToast: (resp: AxiosResponse) => resp.data?.errorCode === invalidCheckoutState
	});
	return response.data;
}

export async function getCheckoutSuccess(): Promise<CheckoutSuccess> {
	const response = await investmentEntityRequest.get('/checkout-success');
	return response.data as CheckoutSuccess;
}

export async function getPresetOfferingDetails(offeringId: string): Promise<PresetOfferingDetails> {
	const response = await apiSecureRequest.get(`/offering/${offeringId}/preset-offering-details`);
	return response.data;
}

export async function changeToStandardFlow(): Promise<void> {
	await investmentEntityRequest.put('/signup-checkout/offramp-to-standard');
}

export async function clearAccountType(): Promise<void> {
	await apiSecureRequest.post('/signup-checkout/clear-account-type');
}

export async function getResumeSignupCheckoutModuleDetails(): Promise<ResumeCheckoutModuleDetails> {
	const response = await investmentEntityRequest.get('/resume-checkout-module');
	return response.data;
}

export async function createEligiblePromos(): Promise<RewardDetails[]> {
	const response = await investmentEntityRequest.post('/signup-checkout/create-eligible-promos');
	return response.data;
}
