import { AxiosRequestConfig, AxiosRequestInterceptorParams } from 'axios';
import { useAppStore } from '@stores/app';

/**
 * Adds a user’s access token to the authorization headers of a request.
 * It also cancels the request if someone using substitute user has read-only permissions and they attempt anything other than a GET request.
 * Required for authenticated endpoints.
 */
export function oAuthTokenHeader(config: AxiosRequestConfig): AxiosRequestConfig {
	const abortController = new AbortController();
	const appStore = useAppStore();
	if (config.method !== 'get' && appStore.isReadOnlyAccess) {
		abortController.abort('Request aborted due to read-only access');

		appStore.addToast({
			type: 'error',
			message: 'Access level is read only. This action requires full access.'
		});
	}

	if (config.headers) {
		config.headers.authorization = `Bearer ${appStore.oauthToken}`;
	}

	return {
		...config,
		signal: abortController.signal
	};
}

export const oAuthTokenHeaderInterceptor: AxiosRequestInterceptorParams = [
	(config) => oAuthTokenHeader(config),
	(error) => Promise.reject(error)
];
