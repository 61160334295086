import {
	handleAuthenticatedScope,
	handleGoogleAppleAuthSetup,
	handlePrivilegedAccess,
	handleThirdPartyScope,
	handleTwoFactor,
	handleWebviewRoute,
	includesVerifyRoute,
	refreshTokenAndRedirect,
	saveLoginRedirect
} from './auth-guard-helpers';
import { NavigationGuard, RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { checkCookieExists } from '@utils/web-storage';
import { TokenScope } from 'types/security';
import { useAppStore } from '@stores/app';
import { webviewRoutesTest } from '@constants/routes';

export const handleAuthentication = async (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized
): Promise<ReturnType<NavigationGuard>> => {
	const appStore = useAppStore();
	const minTokenScopeRequired: TokenScope = to.meta?.minTokenScopeRequired;
	const userHasRefreshToken = checkCookieExists('userLoggedIn');
	const isMobileWebviewRoute = webviewRoutesTest.test(to.path) || to.query?.webview === 'true';

	appStore.updateRouteTransition({ to, from });

	if (isMobileWebviewRoute) {
		await handleWebviewRoute();
	}

	if (!appStore.isAuthenticated && userHasRefreshToken && !appStore.tokenScope.includes('REQUIRES_ADDITIONAL_AUTH')) {
		return refreshTokenAndRedirect(to, from);
	} else if (minTokenScopeRequired === 'UNAUTHENTICATED') {
		return true;
	} else if (appStore.tokenScope === 'THIRD_PARTY_ACCESS') {
		return handleThirdPartyScope(to, from, minTokenScopeRequired);
	} else if (appStore.tokenScope === 'GOOGLE_AUTH_SETUP') {
		return handleGoogleAppleAuthSetup(to, 'google');
	} else if (appStore.tokenScope === 'APPLE_AUTH_SETUP') {
		return handleGoogleAppleAuthSetup(to, 'apple');
	} else if (appStore.tokenScope.includes('REQUIRES_ADDITIONAL_AUTH')) {
		return handleTwoFactor(to);
	} else if (minTokenScopeRequired === 'PRIVILEGED_ACCESS') {
		return handlePrivilegedAccess(appStore.tokenScope, to, from);
	} else if (appStore.isAuthenticated) {
		return handleAuthenticatedScope(to, from, minTokenScopeRequired);
	} else {
		const loginRoute: RouteLocationRaw = { name: 'login' };
		saveLoginRedirect(to);

		if (includesVerifyRoute(to.fullPath)) {
			loginRoute.query = { message: 'login-verify' };
		}

		return loginRoute;
	}
};
