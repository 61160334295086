import { canViewGrowthCalculator } from '@utils/investment-entity';
import { FundriseRouteConfig } from 'vue-router';
import { useInvestmentEntityStore } from '@stores/investment-entity';

const growthCalculatorRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/growth-calculator',
		name: 'growth-calculator',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async () => {
			const investmentEntityStore = useInvestmentEntityStore();
			if (!canViewGrowthCalculator(investmentEntityStore.investmentEntityObject)) {
				return { name: 'account-overview', replace: true };
			}
		},
		component: () => import('@views/growth-calculator/growth-calculator.vue')
	}
];

export default growthCalculatorRoutes;
