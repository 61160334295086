import { useAppStore } from '@stores/app';

export const customerBetaEnrollRouteGuard = async () => {
	const appStore = useAppStore();
	const testGroup = 'test-eligible';
	const eligibilityTiers = await Promise.all([
		appStore.isUserInLDTestGroup({ featureFlag: 'customer-beta-enrollment-tier-1', testGroup }),
		appStore.isUserInLDTestGroup({ featureFlag: 'customer-beta-enrollment-tier-2', testGroup }),
		appStore.isUserInLDTestGroup({ featureFlag: 'customer-beta-enrollment-tier-3', testGroup }),
		appStore.isUserInLDTestGroup({ featureFlag: 'customer-beta-enrollment-tier-4', testGroup }),
		appStore.isUserInLDTestGroup({ featureFlag: 'customer-beta-enrollment-tier-5', testGroup })
	]);

	if (eligibilityTiers.some(Boolean)) {
		return true;
	} else {
		return { name: 'account-overview', replace: true };
	}
};
