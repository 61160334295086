import { AutoInvestOptions, AutoInvestSchedule, RecurringInvestmentScheduleSelections } from 'types/auto-invest';
import {
	cacheRecurringInvestmentScheduleSelections,
	getAgreements,
	getFrequenciesAndAmounts,
	loadRecurringInvestmentScheduleSelections,
	setRecurringInvestmentSchedule
} from '@api/auto-invest';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useInvestmentEntityStore } from '@stores/investment-entity';

export const useAutoInvestStore = defineStore('autoInvest', () => {
	const agreements = ref<Array<string>>([]);
	const autoInvestSchedule = ref<AutoInvestSchedule | null>(null);
	const formattedFirstInvestmentDay = ref('');
	const frequenciesAndAmounts = ref<AutoInvestOptions>({
		amounts: [],
		frequencies: [],
		investmentRange: {
			maximumInvestment: '0',
			minimumInvestment: '0'
		},
		startDateOptions: []
	});

	async function fetchAgreements(): Promise<void> {
		const response = await getAgreements();
		agreements.value = response;
	}

	async function setAutoInvestSchedule(): Promise<void> {
		const investmentEntityStore = useInvestmentEntityStore();

		if (autoInvestSchedule.value) {
			if (
				autoInvestSchedule.value.frequency === 'MONTHLY' ||
				autoInvestSchedule.value.frequency === 'SEMI_MONTHLY'
			) {
				autoInvestSchedule.value = { ...autoInvestSchedule.value, dayOfWeek: undefined, startDate: undefined };
			} else {
				autoInvestSchedule.value = { ...autoInvestSchedule.value, firstInvestmentDayOfMonth: undefined };
			}

			const response = await setRecurringInvestmentSchedule(autoInvestSchedule.value);
			investmentEntityStore.autoInvestSchedule = response;
			investmentEntityStore.hasActiveRecurringInvestmentSchedule = true;
		}
	}

	async function fetchFrequenciesAndAmounts(): Promise<void> {
		const response = await getFrequenciesAndAmounts();
		frequenciesAndAmounts.value = response;
	}

	async function cacheSelections(): Promise<void> {
		if (autoInvestSchedule.value) {
			let selections: RecurringInvestmentScheduleSelections = {
				amount: autoInvestSchedule.value.amount.toString(),
				frequency: autoInvestSchedule.value.frequency
			};

			if (
				autoInvestSchedule.value?.frequency === 'MONTHLY' ||
				autoInvestSchedule.value?.frequency === 'SEMI_MONTHLY'
			) {
				selections = {
					...selections,
					firstInvestmentDayOfMonth: autoInvestSchedule.value.firstInvestmentDayOfMonth?.toString()
				};
			} else {
				selections = {
					...selections,
					dayOfWeek: autoInvestSchedule.value.dayOfWeek,
					startDate: autoInvestSchedule.value.startDate
				};
			}

			await cacheRecurringInvestmentScheduleSelections(selections);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async function fetchCachedSelections(): Promise<any> {
		try {
			const cachedSelections: RecurringInvestmentScheduleSelections =
				await loadRecurringInvestmentScheduleSelections();

			return cachedSelections;
		} catch {
			return null;
		}
	}

	function $reset(): void {
		agreements.value = [];
		autoInvestSchedule.value = null;
		formattedFirstInvestmentDay.value = '';
		frequenciesAndAmounts.value = {
			amounts: [],
			frequencies: [],
			investmentRange: {
				maximumInvestment: '0',
				minimumInvestment: '0'
			},
			startDateOptions: []
		};
	}

	return {
		agreements,
		autoInvestSchedule,
		formattedFirstInvestmentDay,
		frequenciesAndAmounts,
		fetchAgreements,
		setAutoInvestSchedule,
		fetchFrequenciesAndAmounts,
		cacheSelections,
		fetchCachedSelections,
		$reset
	};
});
