import { NavigationGuard } from 'vue-router';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useUserStore } from '@stores/user';

export const checkAndSwitchToIraEntity: NavigationGuard = async (to, from, next) => {
	const investmentEntityStore = useInvestmentEntityStore();
	const userStore = useUserStore();
	if (!userStore.hasIraEntity) {
		const appStore = useAppStore();
		next({ name: 'account-overview', replace: true });
		appStore.addToast({
			type: 'error',
			message: 'No IRA is available for rollover.'
		});
	} else if (investmentEntityStore.entityType !== 'IRA') {
		await userStore.updateSelectedEntity(userStore.iraEntities[0].investmentEntityId);
	} else {
		next();
	}
};
