import { apiRequest, apiSecureRequest } from '@utils/request';
import { getDistinctId } from '@utils/analytics';
import { LDContext } from 'launchdarkly-js-client-sdk';
import { useAppStore } from '@stores/app';

export async function recordLD(testName: string): Promise<void> {
	const mixpanelId = getDistinctId();
	const payload = {
		testName,
		mixpanelId
	};
	const appStore = useAppStore();

	if (appStore.isAuthenticated && !appStore.isAdvisor && !appStore.isReadOnlyAccess) {
		await apiSecureRequest.post('/ld-ab-test/record', payload);
	} else {
		await apiRequest.post('/ld-ab-test/record', payload);
	}
}

export function getLDUserContext(key: string | undefined): LDContext {
	const ldUserContext: LDContext = { kind: 'user', client_type: 'web', key };

	if (import.meta.env.DEV || import.meta.env.MODE === 'omega') {
		const localUser = localStorage.getItem('LocalUser') ?? import.meta.env.VITE_LD_USER_HEADER ?? '';

		ldUserContext['LocalUser'] = localUser;
	}

	return ldUserContext;
}
