import accountSecurityRoutes from './account-security';
import autoInvestEnableRoutes from './auto-invest-enable';
import { determineUserDashboardRoute } from '@utils/login-redirects';
import { flattenQueryParam } from '@utils/request';
import { FundriseRouteConfig } from 'vue-router';
import { getInvestFlowRouteLocation } from '@utils/invest';
import { isANumber } from '@utils/dev';
import { isValidAccountStatementDate } from '@utils/date';
import { removeItemFromSessionStorage } from '@utils/web-storage';
import riaUpgradeRoutes from './ria-upgrade';
import spacetime from 'spacetime';
import { useAdvisorStore } from '@stores/advisor';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useInvestStore } from '@stores/invest';
import { usePerformancePortfolioStore } from '@stores/performance-portfolio';
import { useTaxStore } from '@stores/tax';
import { useUserStore } from '@stores/user';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const accountRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/account`,
		name: 'account-subpage',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const investmentEntityStore = useInvestmentEntityStore();
			investmentEntityStore.storePositions();
			next();
		},
		component: () => import('@views/account/account-subpage.vue'),
		redirect: { name: 'account-overview' },
		children: [
			{
				path: 'documents',
				name: 'account-documents',
				meta: { minTokenScopeRequired: 'THIRD_PARTY_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const investmentEntityStore = useInvestmentEntityStore();
					if (investmentEntityStore.showTaxCenter) {
						const taxStore = useTaxStore();
						await taxStore.storeTaxData();
					}
					next();
				},
				component: () => import('@views/account/account-documents.vue')
			},
			{
				path: 'portfolio/reit/:reitId',
				name: 'account-portfolio-detail',
				meta: {
					minTokenScopeRequired: 'THIRD_PARTY_ACCESS',
					additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_READ_ACCESS']
				},
				redirect: (to) => ({
					name: 'account-portfolio-fund-detail',
					params: { fundId: to.params.reitId }
				})
			},
			{
				path: 'portfolio/note-investment/:noteId',
				name: 'account-portfolio-notes-detail',
				meta: { minTokenScopeRequired: 'THIRD_PARTY_ACCESS' },
				redirect: { name: 'account-portfolio' }
			},
			{
				path: 'performance',
				meta: { minTokenScopeRequired: 'THIRD_PARTY_ACCESS' },
				name: 'account-performance',
				redirect: { name: 'account-portfolio' }
			},
			{
				path: 'transactions',
				name: 'account-transactions',
				meta: { minTokenScopeRequired: 'THIRD_PARTY_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const investmentEntityStore = useInvestmentEntityStore();
					await investmentEntityStore.storeBankAccounts();
					next();
				},
				component: () => import('@views/account/account-transactions.vue')
			}
		]
	},
	{
		path: `/account/overview`,
		name: 'account-overview',
		meta: {
			minTokenScopeRequired: 'THIRD_PARTY_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_READ_ACCESS']
		},
		beforeEnter: async () => {
			const userDashboardRoute = determineUserDashboardRoute();
			if (userDashboardRoute.name === 'account-overview') {
				return true;
			} else {
				return userDashboardRoute;
			}
		},
		component: () => import('@views/account/account-overview.vue')
	},
	{
		path: `/account/user-dashboard/overview`,
		name: 'user-overview',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		beforeEnter: async () => {
			const userDashboardRoute = determineUserDashboardRoute();
			if (userDashboardRoute.name === 'user-overview') {
				return true;
			} else {
				return userDashboardRoute;
			}
		},
		component: () => import('@views/account/user-overview/user-overview.vue')
	},
	{
		path: '/account/newsfeed',
		name: 'newsfeed',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: (to, from, next) => {
			const investmentEntityStore = useInvestmentEntityStore();
			if (investmentEntityStore.hasNeverBeenAnInvestor) {
				next({ name: 'newsfeed-public' });
			} else {
				next();
			}
		},
		component: () => import('@views/account/newsfeed-view.vue')
	},
	{
		path: '/account/user-dashboard/newsfeed',
		name: 'newsfeed-public',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		beforeEnter: () => {
			const investmentEntityStore = useInvestmentEntityStore();
			if (!investmentEntityStore.hasNeverBeenAnInvestor) {
				return { name: 'newsfeed', replace: true };
			}
		},
		component: () => import('@views/account/newsfeed-public.vue')
	},
	{
		path: '/account/performance/account-value-all-time',
		name: 'account-value-all-time',
		meta: { minTokenScopeRequired: 'THIRD_PARTY_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const investmentEntityStore = useInvestmentEntityStore();
			await investmentEntityStore.storePerformance();
			next();
		},
		component: () => import('@views/account/account-performance-all-time.vue')
	},
	{
		path: `/account/ineligible-for-drip`,
		name: 'ineligible-for-drip',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const investmentEntityStore = useInvestmentEntityStore();
			if (investmentEntityStore.isEligibleForReinvestment) {
				next({
					name: 'settings-reinvestment-view'
				});
			} else {
				next();
			}
		},
		component: () => import('@views/account/drip/ineligible-for-drip.vue')
	},
	{
		path: '/account/invest',
		name: 'account-invest',
		meta: { minTokenScopeRequired: 'FULL_ACCESS', customAnalyticsViewName: 'Invest' },
		beforeEnter: async (to, from, next) => {
			const investStore = useInvestStore();
			await investStore.storeIsInTestGroup();
			const investmentEntityStore = useInvestmentEntityStore();
			const isIRA = investmentEntityStore.isIraEntity;
			if (investmentEntityStore.isRedeemedNoDirectInvestNoPlanAccount) {
				await next({ name: 'ria-upgrade', replace: true });
			} else if (isIRA) {
				if (investmentEntityStore.iraEntityMetadata.isIraOnboardingFinished) {
					await next({ name: 'ira-add-funds-start', replace: true });
				} else {
					await next({ name: 'ira-start', replace: true });
				}
			} else if (investmentEntityStore.currentRiaPlanName) {
				if (investmentEntityStore.additionalInvestmentEligibility === 'VALID') {
					if (investStore.orderComplete) {
						investStore.$reset();
					}
					await next();
				} else {
					await next(getInvestFlowRouteLocation('plan'));
				}
			} else if (investmentEntityStore.isDirectInvestEligible) {
				await next({ name: 'offerings', replace: true });
			} else {
				await next({ name: 'ria-upgrade', replace: true });
			}
		},
		component: () => import('@views/account/account-invest.vue')
	},
	{
		path: `/account/investment-accounts/backup-withholding/resolve`,
		name: 'backup-withholding-resolve',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/backup-withholding/backup-withholding-first-notice.vue')
	},
	{
		path: `/account/investment-accounts/backup-withholding/none-active`,
		name: 'backup-withholding-none-active',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/backup-withholding/backup-withholding-none-active.vue')
	},
	{
		path: `/account/investment-accounts/backup-withholding/in-process`,
		name: 'backup-withholding-in-process',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/backup-withholding/backup-withholding-in-process.vue')
	},
	{
		path: `/account/backup-withholding/document-upload`,
		name: 'backup-withholding-document-upload',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/backup-withholding/backup-withholding-document-upload.vue')
	},
	{
		path: '/account/tax-center',
		name: 'tax-center',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const investmentEntityStore = useInvestmentEntityStore();
			if (!investmentEntityStore.showTaxCenter) {
				next({ name: 'account-overview', replace: true });
			} else {
				const taxStore = useTaxStore();
				await taxStore.storeTaxData();
				next();
			}
		},
		component: () => import('@views/account/account-tax-center.vue')
	},
	{
		path: '/account/documents/monthly-account-statement/:yearMonthDay',
		name: 'monthly-account-statement',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_READ_ACCESS']
		},
		beforeEnter: async (to, from, next) => {
			const yearMonthDay = to.params.yearMonthDay as string;
			const dateParam = spacetime(yearMonthDay);

			if (!dateParam.isValid()) {
				const appStore = useAppStore();
				next({ name: 'account-documents', replace: true });
				appStore.addToast({
					type: 'error',
					message: `The provided account statement date [${yearMonthDay}] has invalid format, the date must be of the form 'YYYY-MM-DD'`
				});
			} else if (!isValidAccountStatementDate(yearMonthDay as string)) {
				const appStore = useAppStore();
				next({ name: 'account-documents', replace: true });
				appStore.addToast({
					type: 'error',
					message: `No account statement exists for the provided date [${yearMonthDay}]`
				});
			} else {
				const investmentEntityId = flattenQueryParam(to.query.account);
				const appStore = useAppStore();
				if (appStore.isAdvisor && investmentEntityId) {
					const advisorStore = useAdvisorStore();
					await advisorStore.getAdvisorData();
					advisorStore.selectedInvestmentEntityId = investmentEntityId;
				}

				next();
			}
		},
		component: () => import('@views/account/documents/investment-entity-account-statement.vue')
	},
	{
		path: '/account/documents/reinvestment/:quarter/confirmation',
		name: 'account-reinvestment-confirmation-document',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const hasQuarterParam = to.params.quarter && (to.params.quarter as string).split('-').length === 2;
			const appStore = useAppStore();

			if (hasQuarterParam) {
				next();
			} else {
				next({ name: 'account-documents', replace: true });
				appStore.addToast({
					type: 'error',
					message: `Error retrieving document for the provided quarter.`
				});
			}
		},
		component: () => import('@views/account/documents/reinvestment-confirmation.vue')
	},
	{
		path: '/account/update-ssn/form',
		name: 'update-ssn-form',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const userStore = useUserStore();
			if (userStore.ssnNeedsUpdating) {
				next();
			} else {
				next({ name: 'update-ssn-save', replace: true });
			}
		},
		component: () => import('@views/account/update-ssn-form.vue')
	},
	{
		path: '/account/update-ssn/save',
		name: 'update-ssn-save',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/account/update-ssn-save.vue')
	},
	{
		path: `/account-checklist`,
		name: 'account-checklist',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/account/account-checklist.vue'),
		beforeEnter: async (to, from, next) => {
			removeItemFromSessionStorage('redirect-to-checklist');
			next();
		}
	},
	{
		path: `/account/security-questions/edit`,
		name: 'security-questions',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS']
		},
		beforeEnter: async (to, from, next) => {
			const userStore = useUserStore();
			if (userStore.user?.additionalAuthFactorsAnswered) {
				next({ name: 'account-overview', replace: true });
			} else {
				next();
			}
		},
		component: () => import('@views/account/security/security-questions.vue')
	},
	{
		path: '/account/portfolio',
		name: 'account-portfolio',
		meta: { minTokenScopeRequired: 'THIRD_PARTY_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const investmentEntityStore = useInvestmentEntityStore();
			const performancePortfolioStore = usePerformancePortfolioStore();
			await investmentEntityStore.storePerformance();
			performancePortfolioStore.clearTabSelections();
			next();
		},
		component: () => import('@views/account/account-portfolio.vue')
	},
	{
		path: '/account/portfolio/asset-class/:assetClass',
		name: 'account-portfolio-asset-class-detail',
		meta: { minTokenScopeRequired: 'THIRD_PARTY_ACCESS' },
		component: () => import('@views/account/account-portfolio-asset-class-detail.vue')
	},
	{
		path: '/account/portfolio/fund/:fundId',
		name: 'account-portfolio-fund-detail',
		meta: {
			minTokenScopeRequired: 'THIRD_PARTY_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_READ_ACCESS']
		},
		beforeEnter: async (to, from, next) => {
			const isId = /^\d+$/.test(to.params.fundId as string);
			if (isId) {
				next();
			} else {
				next({ name: 'account-portfolio' });
			}
		},
		component: () => import('@views/account/account-portfolio-fund-detail.vue')
	},
	{
		path: `/account/non-edirect-investments`,
		name: 'non-edirect-investments-redirect',
		redirect: { name: 'account-documents' },
		meta: { minTokenScopeRequired: 'FULL_ACCESS' }
	},
	{
		path: `/account/change-selected-investment-entity`,
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const userStore = useUserStore();
			const entityIndex = flattenQueryParam(to.query.investmentEntityIndex);
			const hasEntityIndex = entityIndex && isANumber(entityIndex);
			const entityList = userStore.user?.entityDetails?.investmentEntities ?? [];
			const investmentEntityStore = useInvestmentEntityStore();
			if (entityList.length > 1 && hasEntityIndex) {
				const relevantEntity = entityList[Number(entityIndex)];
				if (relevantEntity && relevantEntity.investmentEntityId !== investmentEntityStore.investmentEntityId) {
					await userStore.updateSelectedEntityWithoutReload({
						investmentEntityId: relevantEntity.investmentEntityId ?? '',
						nextRoute: {
							route: {
								name: 'account-overview'
							},
							next
						}
					});
				} else {
					next({ name: 'account-overview', replace: true });
				}
			} else {
				next({ name: 'account-overview', replace: true });
			}
		},
		// revisit using redirect
		component: () => import('@views/account/account-overview.vue')
	},
	...accountSecurityRoutes,
	...autoInvestEnableRoutes,
	...riaUpgradeRoutes
];

export default accountRoutes;
