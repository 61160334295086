import { useAppStore } from '@stores/app';
import { useScriptTag } from '@vueuse/core';

export const initializeUserway = async (): Promise<void> => {
	const appStore = useAppStore();
	const userWayEnabled = await appStore.isFeatureEnabled('userway-accessibility-plugin');

	if (userWayEnabled) {
		const userwayId = 'userway';
		useScriptTag('https://cdn.userway.org/widget.js', undefined, { attrs: { id: userwayId } });

		const userwayScript = document.getElementById(userwayId);
		if (userwayScript) {
			userwayScript.setAttribute('data-account', 'cVMkm5shQu');
		}
	}
};
