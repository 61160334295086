import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Link, Toast } from 'types/layout';
import { RouteLocationNormalized, Router } from 'vue-router';
import { attachOAuthTokenRefreshInterceptor } from '@utils/interceptors/oauth-interceptor-response';
import { attachWafChallengeCaptchaInterceptor } from '@utils/interceptors/waf-challenge-captcha-interceptor';
import { correlationIdHeaderInterceptor } from '@utils/interceptors/correlation-id-interceptor-request';
import { errorHandlerInterceptor } from '@utils/interceptors/error-handler-interceptor';
import { forceCorsGetRequestInterceptor } from '@utils/interceptors/force-cors-get-request';
import { ldUserHeaderInterceptor } from '@utils/interceptors/ld-user-interceptor-request';
import { oAuthTokenHeaderInterceptor } from '@utils/interceptors/oauth-interceptor-request';
import { passwordResetKeyHeaderInterceptor } from '@utils/interceptors/password-reset-key-interceptor-request';
import { passwordResetSessionUuidHeaderInterceptor } from '@utils/interceptors/password-reset-session-uuid-interceptor-request';
import { useAdvisorStore } from '@stores/advisor';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { wafTokenRequestInterceptor } from '@utils/interceptors/waf-token-request';

function createAxiosInstance(isAuthenticated: boolean, requestConfig?: AxiosRequestConfig): AxiosInstance {
	const instance = axios.create(requestConfig);

	if (isAuthenticated) {
		addAuthenticatedInterceptors(instance);
	} else {
		addUnauthenticatedInterceptors(instance);
	}

	attachWafChallengeCaptchaInterceptor(instance);
	instance.interceptors.response.use(...errorHandlerInterceptor);
	instance.interceptors.request.use(...ldUserHeaderInterceptor);

	return instance;
}

function addAuthenticatedInterceptors(instance: AxiosInstance): void {
	instance.interceptors.request.use(...oAuthTokenHeaderInterceptor);
	instance.interceptors.request.use(...correlationIdHeaderInterceptor);
	attachOAuthTokenRefreshInterceptor(instance);
}

function addUnauthenticatedInterceptors(instance: AxiosInstance): void {
	instance.interceptors.request.use(...forceCorsGetRequestInterceptor);
	instance.interceptors.request.use(...wafTokenRequestInterceptor);
}

declare module 'axios' {
	export interface AxiosRequestConfig {
		errorToast?: Toast;
		suppressToast?: (response: AxiosResponse) => boolean;
	}

	export type AxiosRequestInterceptorParams = Parameters<AxiosInterceptorManager<AxiosRequestConfig>['use']>;
	export type AxiosResponseInterceptorParams = Parameters<AxiosInterceptorManager<AxiosResponse>['use']>;
}

export const apiSecureRequest = createAxiosInstance(true, {
	baseURL: `${import.meta.env.VITE_BASE_API_URL}/api-secured`
});

export const investmentEntityRequest = createAxiosInstance(true, {});

investmentEntityRequest.interceptors.request.use(
	(config) => {
		const advisorStore = useAdvisorStore();
		const appStore = useAppStore();
		const investmentEntityStore = useInvestmentEntityStore();
		config.baseURL = `${import.meta.env.VITE_BASE_API_URL}/api-secured/investment-entity/${
			appStore.isAdvisor ? advisorStore.selectedInvestmentEntityId : `${investmentEntityStore.investmentEntityId}`
		}`;
		return config;
	},
	(error: AxiosError) => Promise.reject(error)
);

export const investmentEntityApsRequest = createAxiosInstance(true, {});

investmentEntityApsRequest.interceptors.request.use(
	(config) => {
		const advisorStore = useAdvisorStore();
		const appStore = useAppStore();
		const investmentEntityStore = useInvestmentEntityStore();
		config.baseURL = `${import.meta.env.VITE_BASE_API_URL}/api-secured/aps/investment-entity/${
			appStore.isAdvisor ? advisorStore.selectedInvestmentEntityId : investmentEntityStore.investmentEntityId
		}`;
		return config;
	},
	(error: AxiosError) => Promise.reject(error)
);

export const oauthSecureRequest = createAxiosInstance(true, {
	baseURL: `${import.meta.env.VITE_BASE_OAUTH_API_URL}/api-secured/authentication`
});

export const forgotPasswordApiRequest = createAxiosInstance(false, {
	baseURL: `${import.meta.env.VITE_BASE_API_URL}/api`,
	withCredentials: true
});
forgotPasswordApiRequest.interceptors.request.use(...passwordResetKeyHeaderInterceptor);
forgotPasswordApiRequest.interceptors.request.use(...passwordResetSessionUuidHeaderInterceptor);
forgotPasswordApiRequest.interceptors.request.use(...correlationIdHeaderInterceptor);

export const oauthTokenRequest = createAxiosInstance(false, {
	baseURL: `${import.meta.env.VITE_BASE_OAUTH_API_URL}`,
	withCredentials: true,
	auth: {
		username: `${import.meta.env.VITE_API_CLIENT_ID}`,
		password: `${import.meta.env.VITE_OAUTH_CLIENT_SECRET}`
	}
});

export const oauthRequest = createAxiosInstance(false, {
	baseURL: `${import.meta.env.VITE_BASE_OAUTH_API_URL}`,
	withCredentials: true
});

export const apiRequest = createAxiosInstance(false, {
	baseURL: `${import.meta.env.VITE_BASE_API_URL}/api`,
	withCredentials: true
});

export const constructUrlWithQueryParams = (url: string, params: object): string => {
	const initialSeparator = url.indexOf('?') === -1 ? '?' : '&';
	const entries = Object.entries(params);
	const paramArray: Array<string> = [];

	for (const [key, val] of entries) {
		paramArray.push(`${key}=${val}`);
	}

	return `${url + initialSeparator + paramArray.join('&')}`;
};

export const flattenQueryParam = (queryParam: string | (string | null)[] | null): string | null => {
	if (queryParam === null) {
		return null;
	}

	return Array.isArray(queryParam) ? queryParam[0] : queryParam;
};

export const includesRequiredQueryParams = (requiredParams: Array<string>, to: RouteLocationNormalized): boolean => {
	return requiredParams.every((param) => Object.keys(to.query).includes(param));
};

export function getLinkFromRoute(router: Router, routePath: string, linkText: string): Link {
	const routeResolution = router.resolve(routePath);
	if (routeResolution.matched.length > 0 && routeResolution.name) {
		return {
			router: routeResolution.name as string,
			text: linkText,
			params: routeResolution.params,
			query: routeResolution.query
		};
	} else {
		const startOfPathOptions = ['https://', 'http://', 'mailto:', '/'];
		if (startOfPathOptions.some((option) => routePath.startsWith(option))) {
			return { href: routePath, text: linkText };
		} else {
			return { href: `/${routePath}`, text: linkText };
		}
	}
}

export function triggerDownload(url: string): void {
	const anchor = document.createElement('a');
	anchor.style.display = 'none';
	document.body.appendChild(anchor);
	anchor.href = url;
	anchor.download = 'true';
	anchor.click();
	document.body.removeChild(anchor);
}
