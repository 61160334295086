import { DropdownSubmenuLink, NavigationLink } from 'types/header';
import { InvestmentEntity } from 'types/investment-entity';
import { Link } from 'types/layout';
import { marketingLinks } from '@components/header/navigation/links';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { User } from 'types/user';

export default class AuthenticatedNav {
	public links!: Array<NavigationLink>;

	public firstInvestmentDate!: string;

	public fullName!: string;

	public multipleInvestmentEntities!: boolean;

	public inviteLink!: Link | null;

	public constructor(user: User | null, selectedInvestmentEntity: InvestmentEntity | null) {
		if (!user || !selectedInvestmentEntity) {
			return;
		}
		this.setMultipleInvestmentEntities(user);
		this.setFirstInvestmentDate(user);
		this.setFullName(user);
		this.setInviteLink(selectedInvestmentEntity);
		this.setLinks(selectedInvestmentEntity);
	}

	private setFirstInvestmentDate(user: User): void {
		if (user && user.entityDetails) {
			this.firstInvestmentDate = user.entityDetails?.firstInvestmentDate;
		}
	}

	private setFullName(user: User): void {
		if (user && user.firstName && user.lastName) {
			this.fullName = user.firstName + ' ' + user.lastName;
		} else if (user && user.firstName) {
			this.fullName = user.firstName;
		} else {
			this.fullName = 'Your Account';
		}
	}

	private setMultipleInvestmentEntities(user: User): void {
		if (user.entityDetails?.investmentEntities) {
			this.multipleInvestmentEntities = user.entityDetails.investmentEntities.length > 1;
		} else {
			this.multipleInvestmentEntities = false;
		}
	}

	private setInviteLink(selectedInvestmentEntity: InvestmentEntity): void {
		if (
			selectedInvestmentEntity.userReferralEligibilityStatus &&
			selectedInvestmentEntity.userReferralEligibilityStatus != 'INELIGIBLE'
		) {
			this.inviteLink = {
				router: 'referral-landing',
				query: { cta: 'Invite a Friend CTA' },
				href: '/invitation-program/landing',
				text: 'Invite a Friend'
			};
		} else {
			this.inviteLink = null;
		}
	}

	private setLinks(selectedInvestmentEntity: InvestmentEntity): void {
		if (selectedInvestmentEntity.isReitInvestor || selectedInvestmentEntity.hasLegacyInvestments) {
			this.links = [
				{
					text: 'Home',
					router: 'account-overview'
				},
				{
					text: 'Portfolio',
					router: 'account-portfolio'
				},
				{
					text: 'Invest',
					router: 'account-invest'
				}
			];

			this.links = [
				...this.links,
				{
					text: 'Transactions',
					router: 'account-transactions'
				},
				{
					dropdown: 'More',
					startOpened: true,
					links: this.constructMoreDropdownLinks(selectedInvestmentEntity)
				}
			];
		} else {
			const newsfeedLink: NavigationLink = {
				text: 'Newsfeed',
				router: 'newsfeed-public'
			};

			this.links = [...marketingLinks, newsfeedLink];
		}
	}

	private constructMoreDropdownLinks(selectedInvestmentEntity: InvestmentEntity): Array<DropdownSubmenuLink> {
		const appStore = useAppStore();
		const subMenuLinks: DropdownSubmenuLink[] = [
			{
				link: {
					text: 'Newsfeed',
					router: 'newsfeed'
				}
			},
			{
				link: {
					text: 'Help center',
					router: 'help-center-landing'
				}
			},
			{
				link: {
					text: 'Documents',
					router: 'account-documents'
				}
			}
		];

		if (selectedInvestmentEntity.showTaxCenter) {
			subMenuLinks.push({
				link: {
					text: 'Tax Center',
					router: 'tax-center'
				}
			});
		}

		if (
			selectedInvestmentEntity.entityType === 'INDIVIDUAL' ||
			selectedInvestmentEntity.entityType === 'JOINT_ACCOUNT'
		) {
			subMenuLinks.push({
				link: {
					text: 'Calculator',
					router: 'growth-calculator'
				}
			});
		}
		const investmentEntity = useInvestmentEntityStore();

		if (selectedInvestmentEntity.eligibleForInvestorGoal && investmentEntity.investorGoalExists) {
			subMenuLinks.push({
				link: {
					text: 'Goal',
					router: selectedInvestmentEntity.investorGoalExists ? 'investment-goal-edit' : 'investment-goal'
				}
			});
		}

		if (selectedInvestmentEntity.showBrowseMenuItem || appStore.isCorniceSubstituteUser) {
			subMenuLinks.push({
				link: {
					text: 'Browse Investments',
					router: 'offerings'
				}
			});
		}

		if (
			selectedInvestmentEntity.isReitInvestor &&
			selectedInvestmentEntity.additionalInvestmentEligibility === 'ENTITY_NO_PLAN_SELECTION'
		) {
			subMenuLinks.push({
				link: {
					text: 'Pick a plan',
					router: 'ria-upgrade'
				}
			});
		}

		return subMenuLinks;
	}
}
