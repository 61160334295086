<template>
	<div class="toast-container">
		<ToastMessage v-for="toast in toasts" :key="toast.message" :toast="toast" class="mt-100" @dismiss="dismiss" />
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Toast } from 'types/layout';
import ToastMessage from '@components/layout/toast-message.vue';
import { useAppStore } from '@stores/app';

const appStore = useAppStore();

const toasts = computed((): Array<Toast> => {
	return appStore.toasts;
});

function dismiss(toast: Toast): void {
	appStore.dismissToast(toast);
}
</script>
<style lang="scss">
.toast-container {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	margin-left: 1rem;
	margin-right: 1rem;
	z-index: 4500;

	&:first-child {
		margin-top: 0rem;
	}
}
</style>
