import {
	AccountValueChartModuleMetadata,
	ModularDashboardContainer,
	ModularDashboardMetadata,
	ModularDashboardModule
} from 'types/modular-dashboard';
import {
	AccountValueChartTimeHorizon,
	AccountValueChartTimeHorizonType,
	DividendsModuleData,
	GrowthChecklistModuleData,
	NetReturnsOverTimeByTimeHorizon,
	NewsFeedItem
} from 'types/account';
import { computed, ref } from 'vue';
import {
	dismissDashboardModule as dismissDashboardModuleUtil,
	getAccountChecklistModuleData,
	getDeltaModuleData,
	getFeaturedModuleData,
	getGrowthCalculatorByAgeModule,
	getGrowthChecklistModuleData,
	getInvitationProgramModule,
	getModularDashboardData,
	getModularDashboardMetadata,
	getPerformanceContextData,
	getPortfolioOverviewModuleData,
	getProgressModuleData,
	loadNextProgressModule
} from '@api/modular-dashboard';
import { getDashboardNewsFeed, getDividendsModuleData, getPublicDashboardNewsFeed } from '@api/account';
import {
	GrowthCalculatorByAgeModuleData,
	InvitationProgramModuleData,
	ModularDashboardAccountChecklist,
	ModularDashboardPortfolioOverview,
	ModularDashboardProgressModule
} from 'types/modular-dashboard/custom';
import { defineStore } from 'pinia';
import { FeaturedCard } from 'types/layout';
import { getAccountValueModuleChartData } from '@api/performance-portfolio';
import { ModularDashboardDeltaItem } from 'types/modular-dashboard/delta-module';
import { PerformanceContextCardData } from 'types/performance-context';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useInvestmentGoalsStore } from './investment-goals';

export const useModularDashboardStore = defineStore('modularDashboard', () => {
	const modularDashboardMetadata = ref<ModularDashboardMetadata | null>(null);
	const containers = ref<Array<ModularDashboardContainer> | null>(null);
	const accountChecklistModuleData = ref<ModularDashboardAccountChecklist | null>(null);
	const deltaModuleData = ref<Record<string, ModularDashboardDeltaItem>>({});
	const growthChecklistModuleData = ref<GrowthChecklistModuleData | null>(null);
	const progressModuleDetails = ref<ModularDashboardProgressModule | null>(null);
	const portfolioOverviewModule = ref<ModularDashboardPortfolioOverview | null>(null);
	const invitationProgramModule = ref<InvitationProgramModuleData | null>(null);
	const growthCalculatorByAgeModule = ref<GrowthCalculatorByAgeModuleData | null>(null);
	const newsfeedModule = ref<Array<NewsFeedItem> | null>(null);
	const accountValueChartDataByTimeHorizon = ref<
		Partial<Record<AccountValueChartTimeHorizonType, NetReturnsOverTimeByTimeHorizon>>
	>({});
	const accountValueChartAvailableTimeHorizons = ref<Array<AccountValueChartTimeHorizon>>([]);
	const accountValueChartActiveTimeHorizon = ref<AccountValueChartTimeHorizon | null>(null);
	const accountValuePerformanceContext = ref<{
		timeHorizon: string | null;
		activeEventIds: Array<string>;
		events: Record<string, Array<PerformanceContextCardData>>;
	}>({
		timeHorizon: null,
		activeEventIds: [],
		events: {}
	});
	const dividendsModuleData = ref<DividendsModuleData | null>(null);
	const featuredModuleData = ref<Record<string, FeaturedCard>>({});

	const isInPreSettlementState = computed((): boolean => {
		return modularDashboardMetadata.value?.settlementState === 'PRE_SETTLEMENT';
	});

	const accountStatusDescription = computed((): string => {
		return modularDashboardMetadata.value?.statusDescription ?? '';
	});

	function isValidModuleType(module: ModularDashboardModule): boolean {
		const validModuleType = [
			'ALERT',
			'DELTA',
			'CARD',
			'CUSTOM',
			'FEATURED_CONTENT',
			'STANDARD',
			'MULTI_A',
			'MULTI_B',
			'VIDEO'
		];

		const validCustomModules = [
			'ACCOUNT_CHECKLIST',
			'ACCOUNT_VALUE_CHART',
			'DIVIDENDS',
			'GROWTH_CHECKLIST',
			'NEWSFEED',
			'PORTFOLIO_OVERVIEW',
			'PROGRESS',
			'INVITATION_PROGRAM',
			'GROWTH_CALCULATOR_BY_AGE'
		];

		if (module.type === 'CUSTOM') {
			return validCustomModules.includes(module.name);
		} else {
			return validModuleType.includes(module.type);
		}
	}

	function updateContainers(dashboardContainers: Array<ModularDashboardContainer> | null): void {
		containers.value =
			dashboardContainers
				?.map((container) => ({
					...container,
					modules: container.modules.filter((module) => isValidModuleType(module))
				}))
				.filter((container) => container.modules.length > 0 || container.defaultState === 'PLACEHOLDER') ??
			null;
	}

	function updateAccountValueChartDataByTimeHorizons(chartData: NetReturnsOverTimeByTimeHorizon | null): void {
		if (chartData === null) {
			accountValueChartDataByTimeHorizon.value = {};
		} else {
			if (!accountValueChartDataByTimeHorizon.value) {
				accountValueChartDataByTimeHorizon.value = {};
			}

			const selectedRange = chartData.selectedRange;

			if (!accountValueChartDataByTimeHorizon.value[selectedRange]) {
				accountValueChartDataByTimeHorizon.value = {
					...accountValueChartDataByTimeHorizon.value,
					[selectedRange]: chartData
				};
			}
		}
	}

	async function storeModularDashboardData(force = false): Promise<void> {
		if (modularDashboardMetadata.value === null || force) {
			if (force) {
				$reset();
			}

			const modularDashboardMetadataData = await getModularDashboardMetadata();

			if (modularDashboardMetadataData) {
				modularDashboardMetadata.value = modularDashboardMetadataData;
			}

			const containerData = await getModularDashboardData();
			updateContainers(containerData.containers);
		}
	}

	async function dismissDashboardModule(payload: {
		container: ModularDashboardContainer;
		module: string;
	}): Promise<void> {
		try {
			await dismissDashboardModuleUtil(payload.module);
		} catch {}

		const indexOfContainer = containers.value?.findIndex(
			(item) =>
				item.location.columnIndex === payload.container.location.columnIndex &&
				item.location.sortOrder === payload.container.location.sortOrder
		);

		const indexOfModuleToDismiss = payload.container.modules.findIndex((item) => item.name === payload.module);

		const filteredArr = containers.value?.slice();
		filteredArr?.[indexOfContainer as number]?.modules.splice(indexOfModuleToDismiss, 1);

		if (filteredArr) {
			updateContainers(filteredArr);
		}
	}

	async function getGrowthChecklistModuleDetails(): Promise<void> {
		const data = await getGrowthChecklistModuleData();
		growthChecklistModuleData.value = data;
	}

	async function storeDeltaModuleData(moduleName: string, force = false): Promise<void> {
		if (!deltaModuleData.value[moduleName] || force) {
			const moduleData = await getDeltaModuleData(moduleName);

			deltaModuleData.value[moduleName] = moduleData;
		}
	}

	async function storeFeaturedModuleData(moduleName: string, force = false): Promise<void> {
		if (!featuredModuleData.value[moduleName] || force) {
			const moduleData = await getFeaturedModuleData(moduleName);

			featuredModuleData.value[moduleName] = moduleData;
		}
	}

	async function storeAccountChecklistModuleData(force = false): Promise<void> {
		if (!accountChecklistModuleData.value || force) {
			const moduleData = await getAccountChecklistModuleData();

			accountChecklistModuleData.value = moduleData;
		}
	}

	async function storeInvitationProgramModule(force = false): Promise<void> {
		if (!invitationProgramModule.value || force) {
			invitationProgramModule.value = await getInvitationProgramModule();
		}
	}

	async function storeGrowthCalculatorByAgeModule(force = false): Promise<void> {
		if (!growthCalculatorByAgeModule.value || force) {
			growthCalculatorByAgeModule.value = await getGrowthCalculatorByAgeModule();
		}
	}

	async function updateAccountValueChartByTimeHorizon(force = false): Promise<void> {
		if (!accountValueChartActiveTimeHorizon.value) {
			const accountValueChartmodule = containers.value
				?.find((container) => container.name === 'ACCOUNT_VALUE_CHART')
				?.modules.find((module) => module.name === 'ACCOUNT_VALUE_CHART');

			if (accountValueChartmodule?.metadata) {
				const moduleMetadata = accountValueChartmodule.metadata as AccountValueChartModuleMetadata;
				if (moduleMetadata.availableRanges) {
					accountValueChartAvailableTimeHorizons.value = moduleMetadata.availableRanges;
				}

				if (moduleMetadata.defaultRange) {
					accountValueChartActiveTimeHorizon.value = moduleMetadata.defaultRange;
				}
			}
		}

		const activeRange = accountValueChartActiveTimeHorizon.value?.name;
		if (
			activeRange &&
			(!accountValueChartDataByTimeHorizon.value ||
				!(activeRange in accountValueChartDataByTimeHorizon.value) ||
				force)
		) {
			const data = await getAccountValueModuleChartData(activeRange);

			updateAccountValueChartDataByTimeHorizons(data);
		}
	}

	function updateAccountValuePerformanceContextEvents(
		change: { timeHorizon: string; events: Array<PerformanceContextCardData> } | null
	): void {
		accountValuePerformanceContext.value.activeEventIds = [];
		if (!change) {
			accountValuePerformanceContext.value.events = {};
		} else {
			accountValuePerformanceContext.value.timeHorizon = change.timeHorizon;
			accountValuePerformanceContext.value.events[change.timeHorizon] = change.events;
		}
	}

	async function storePerformanceContextEvents(): Promise<void> {
		const timeHorizon = accountValueChartActiveTimeHorizon.value?.name;
		if (timeHorizon) {
			let events = accountValuePerformanceContext.value.events[timeHorizon];
			if (!events) {
				events = await getPerformanceContextData(timeHorizon);
			}

			updateAccountValuePerformanceContextEvents({
				timeHorizon,
				events
			});
		}
	}

	async function storeAccountValueAndPerformanceContextData(): Promise<void> {
		await Promise.all([updateAccountValueChartByTimeHorizon(), storePerformanceContextEvents()]);
	}

	async function updateAccountValueChartOnTimeHorizonSelection(
		timeHorizon: AccountValueChartTimeHorizon
	): Promise<void> {
		accountValueChartActiveTimeHorizon.value = timeHorizon;
		await storeAccountValueAndPerformanceContextData();
	}

	async function setAccountValueChartActivePerformanceContextEvents(eventIds: Array<string>): Promise<void> {
		if (eventIds.toString() !== accountValuePerformanceContext.value.activeEventIds.toString()) {
			accountValuePerformanceContext.value.activeEventIds = [...eventIds];
		}
	}

	async function storePortfolioOverviewModuleData(force = false): Promise<void> {
		if (!portfolioOverviewModule.value || force) {
			const data = await getPortfolioOverviewModuleData();

			portfolioOverviewModule.value = data;
		}
	}

	async function storeProgressModuleDetails(): Promise<void> {
		if (!progressModuleDetails.value) {
			const data = await getProgressModuleData();

			progressModuleDetails.value = data;
		}
	}

	async function storeNextProgressModuleDetails(): Promise<void> {
		const data = await loadNextProgressModule();
		progressModuleDetails.value = data;
	}

	async function storeDividendsModuleData(): Promise<void> {
		if (!dividendsModuleData.value) {
			const data = await getDividendsModuleData();

			dividendsModuleData.value = data;
		}
	}

	async function storeNewsfeedModuleData(): Promise<void> {
		const appStore = useAppStore();
		const investmentEntityStore = useInvestmentEntityStore();
		if (newsfeedModule.value || appStore.isThirdPartyLogin) return;

		let data = null;
		if (investmentEntityStore.hasNeverBeenAnInvestor) {
			data = await getPublicDashboardNewsFeed();
		} else {
			const dashboardNewsfeed = await getDashboardNewsFeed();
			data = dashboardNewsfeed.data;
		}

		newsfeedModule.value = data;
	}

	async function $reset(): Promise<void> {
		modularDashboardMetadata.value = null;
		accountValueChartDataByTimeHorizon.value = {};
		accountChecklistModuleData.value = null;
		deltaModuleData.value = {};
		featuredModuleData.value = {};
		growthChecklistModuleData.value = null;
		portfolioOverviewModule.value = null;
		progressModuleDetails.value = null;
		dividendsModuleData.value = null;
		newsfeedModule.value = null;
		accountValueChartAvailableTimeHorizons.value = [];
		accountValueChartActiveTimeHorizon.value = null;

		const investmentEntityStore = useInvestmentEntityStore();
		if (investmentEntityStore.investorGoalExists) {
			const investmentGoalsStore = useInvestmentGoalsStore();
			await investmentGoalsStore.fetchAndStoreCurrentGoal();
		}
	}

	return {
		accountChecklistModuleData,
		accountStatusDescription,
		accountValueChartActiveTimeHorizon,
		accountValueChartAvailableTimeHorizons,
		accountValueChartDataByTimeHorizon,
		accountValuePerformanceContext,
		containers,
		deltaModuleData,
		dividendsModuleData,
		featuredModuleData,
		growthCalculatorByAgeModule,
		growthChecklistModuleData,
		invitationProgramModule,
		isInPreSettlementState,
		modularDashboardMetadata,
		newsfeedModule,
		portfolioOverviewModule,
		progressModuleDetails,
		dismissDashboardModule,
		getGrowthChecklistModuleDetails,
		setAccountValueChartActivePerformanceContextEvents,
		storeAccountChecklistModuleData,
		storeAccountValueAndPerformanceContextData,
		storeDeltaModuleData,
		storeDividendsModuleData,
		storeFeaturedModuleData,
		storeGrowthCalculatorByAgeModule,
		storeInvitationProgramModule,
		storeModularDashboardData,
		storeNewsfeedModuleData,
		storeNextProgressModuleDetails,
		storePortfolioOverviewModuleData,
		storeProgressModuleDetails,
		updateAccountValueChartOnTimeHorizonSelection,
		$reset
	};
});
