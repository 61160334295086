import {
	ShareholderVoteDetail,
	ShareholderVoteEntityDetail,
	ShareholderVoteResponses,
	ShareholderVoteResponseType
} from 'types/shareholder-vote';
import { apiSecureRequest } from '@utils/request';
import axios from 'axios';
import { getShareholderVoteDescription } from './prismic';
import { useUserStore } from '@stores/user';

export const submitSameResponseForAllOpenShareholderItems = async (
	responseType: ShareholderVoteResponseType
): Promise<void> => {
	const userStore = useUserStore();
	const entities = userStore.shareholderVoteDetail?.entityIds ?? [];

	await axios.all(
		entities.map((entity) => {
			const payload: ShareholderVoteResponses = {
				voteResponses: [
					{
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						shareholderVoteId: userStore.shareholderVoteDetail!.vote!.shareholderVoteId,
						voteResponseType: responseType
					}
				]
			};
			return apiSecureRequest.post(`/investment-entity/${entity}/shareholder-votes`, payload);
		})
	);
};

export const getShareholderVoteDetails = async (): Promise<ShareholderVoteDetail | null> => {
	const userStore = useUserStore();
	const entities = userStore.user?.entityDetails?.investmentEntities ?? [];

	// fetch all votes available for all entities
	const entityDetails: Array<ShareholderVoteEntityDetail> = await Promise.all(
		entities
			.filter((entity) => entity.eligibleForShareholderVote)
			.map((entity) =>
				apiSecureRequest
					.get(`/investment-entity/${entity.investmentEntityId}/shareholder-vote`)
					.then((result) => ({
						...result.data,
						entityId: entity.investmentEntityId
					}))
			)
	);

	const vote = entityDetails.map((entity) => entity.voteDetails[0]).find(Boolean);

	if (!vote) {
		return null;
	}

	return {
		entityIds: entityDetails
			.filter((entity) =>
				entity.voteDetails.some((entityVote) => entityVote.shareholderVoteId === vote.shareholderVoteId)
			)
			.map((entity) => entity.entityId),
		vote,
		content: await getShareholderVoteDescription('primary')
	};
};
