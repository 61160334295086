import { FundriseRouteConfig } from 'vue-router';
import { useAdvisorStore } from '@stores/advisor';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { usePerformancePortfolioStore } from '@stores/performance-portfolio';
import { useUserStore } from '@stores/user';

const advisorRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/advisor`,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			const appStore = useAppStore();
			const enabled = await appStore.isFeatureEnabled('advisors-product');

			if (enabled) {
				if (appStore.isVerified && appStore.isAdvisor) {
					const advisorStore = useAdvisorStore();
					const userStore = useUserStore();
					await Promise.all([advisorStore.getAdvisorData(), userStore.storeTwoFactorType()]);
				}

				next();
			} else {
				next({ name: 'forbidden', replace: true });
			}
		},
		component: () => import('@views/advisor/advisor-subpage.vue'),
		children: [
			{
				path: '',
				name: 'advisor-landing',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/advisor/advisor-landing.vue')
			},
			{
				path: `get-started`,
				name: 'advisor-signup',
				meta: {
					minTokenScopeRequired: 'UNAUTHENTICATED'
				},
				component: () => import('@views/advisor/advisor-signup.vue'),
				beforeEnter: async (to, from, next) => {
					const appStore = useAppStore();
					if (appStore.isAuthenticated) {
						next({ name: 'home', replace: true });
					} else {
						next();
					}
				}
			},
			{
				path: 'clients/:investmentEntityId/detail',
				name: 'advisor-client-detail',
				meta: {
					minTokenScopeRequired: 'ADVISOR_ACCESS'
				},
				beforeEnter: async (to, from, next) => {
					try {
						const investmentEntityStore = useInvestmentEntityStore();
						const advisorStore = useAdvisorStore();
						const performancePortfolioStore = usePerformancePortfolioStore();
						advisorStore.selectedInvestmentEntityId = to.params.investmentEntityId as string;
						performancePortfolioStore.$reset();
						await investmentEntityStore.storePerformance();
						performancePortfolioStore.selectedAdvisorView = 'ALL';
						next();
					} catch {
						const appStore = useAppStore();
						appStore.addToast({
							type: 'error',
							message:
								'You are unable to access details for this client. If you believe this is an error, contact us at <a href="mailto:support@fundrise.com">support@fundrise.com</a>.'
						});
						next({ name: 'home' });
					}
				},
				component: () => import('@views/advisor/advisor-client-detail.vue')
			},
			{
				path: `clients`,
				name: 'advisor-dashboard',
				meta: { minTokenScopeRequired: 'ADVISOR_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const advisorStore = useAdvisorStore();
					await advisorStore.getClientAllocations();
					next();
				},
				component: () => import('@views/advisor/advisor-dashboard.vue')
			},
			{
				path: `invite-clients`,
				name: 'advisor-invite-clients',
				meta: { minTokenScopeRequired: 'ADVISOR_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const userStore = useUserStore();
					if (!userStore.twoFactorEnabled) {
						next({ name: 'advisor-dashboard' });
					} else {
						next();
					}
				},
				component: () => import('@views/advisor/advisor-invite-clients.vue')
			},
			{
				path: `invite-success`,
				name: 'advisor-invite-client-success',
				meta: { minTokenScopeRequired: 'ADVISOR_ACCESS' },
				component: () => import('@views/advisor/advisor-invite-client-success.vue')
			},
			{
				path: `unverified-email`,
				name: 'advisor-unverified-email',
				meta: {
					minTokenScopeRequired: 'UNVERIFIED'
				},
				beforeEnter: async (to, from, next) => {
					const appStore = useAppStore();
					if (appStore.isVerified) {
						next({ name: 'advisor-dashboard ' });
					} else {
						next();
					}
				},
				component: () => import('@views/advisor/advisor-verify-email.vue')
			},
			{
				path: `welcome`,
				name: 'advisor-welcome',
				meta: {
					minTokenScopeRequired: 'ADVISOR_ACCESS'
				},
				component: () => import('@views/advisor/advisor-welcome.vue')
			}
		]
	}
];

export default advisorRoutes;
