import {
	addNewEmailRecipient,
	deleteEmailRecipient as deleteEmailRecipientAPI,
	deleteNickname as deleteNicknameAPI,
	getBeneficiaries,
	getDocumentDeliveryPreference,
	getEmailRecipients,
	updateDocumentDeliveryPreference as updateDocumentDeliveryPreferenceAPI,
	updateEmailRecipient,
	updateNickname as updateNicknameAPI
} from '@api/settings';
import { BankAccount, NewManualBankAccount, PaymentMethod, PaymentMethods } from 'types/payment';
import { computed, ref } from 'vue';
import {
	deleteRecurringInvestmentSchedule as deleteRecurringInvestmentScheduleAPI,
	getRecurringInvestmentSchedule
} from '@api/auto-invest';
import { DocumentDeliveryPreferenceEnum, EmailRecipient } from 'types/settings';
import {
	DripStatus,
	FeatureEligibility,
	InvestmentEntity,
	InvestmentEntityType,
	IraEntityMetadata,
	UserReferralEligibilityStatus
} from 'types/investment-entity';
import { EntityAccountDetails, JointAccountDetails, TrustAccountDetails } from 'types/checkout';
import { getBankAccounts, getPaymentMethods, linkManualBankAccount } from '@api/bank-accounts';
import { getCurrentRiaPlan, getPerformanceData, getPositionsTable, updateRiaPlan } from '@api/investment-entity';
import { getEntityAccountDetails, getJointAccountDetails, getTrustAccountDetails } from '@api/checkout';
import { getNotifications, updateNotifications } from '@api/header';
import { InvestmentStrategy, RiaPlan } from 'types/plan';
import { setDatadogAutoInvestSchedule, setDatadogUserAUM, setDatadogUserEntityData } from '@utils/datadog';
import { AdditionalInvestmentEligibility } from 'types/invest';
import { AutoInvestSchedule } from 'types/auto-invest';
import { Beneficiary } from 'types/account';
import { defineStore } from 'pinia';
import { getDrip } from '@api/drip';
import { getIraAccountDetails } from '@api/ira';
import { getNAVReinvestmentPreference } from '@api/nav-reinvestment';
import { IraAccountDetails } from 'types/ira';
import { isBankPaymentMethod } from '@utils/payment-methods';
import { NAVReinvestmentPreference } from 'types/nav-reinvestment';
import { NotificationResponse } from 'types/header';
import { Performance } from 'types/performance';
import { Positions } from 'types/portfolio';
import { setItemInLocalStorage } from '@utils/web-storage';
import { useAppStore } from '@stores/app';
import { useModularDashboardStore } from '@stores/modular-dashboard';
import { usePerformancePortfolioStore } from '@stores/performance-portfolio';
import { useUserStore } from '@stores/user';

export const useInvestmentEntityStore = defineStore('investmentEntity', () => {
	const additionalInvestmentEligibility = ref<AdditionalInvestmentEligibility>('ENTITY_MISSING_INFORMATION');
	const beneficiaries = ref<Array<Beneficiary>>([]);
	const currentRiaPlanName = ref('');
	const currentRiaPlanInvestmentStrategy = ref<InvestmentStrategy | null>(null);
	const currentSelectedRiaPlanId = ref('');
	const documentDeliveryPreference = ref<DocumentDeliveryPreferenceEnum | null>(null);
	const eligibleForDrip = ref(false);
	const eligibleForInvestorGoal = ref(false);
	const eligibleForRecurringInvestment = ref(false);
	const eligibleForShareholderVote = ref(false);
	const entityType = ref<InvestmentEntityType>('INDIVIDUAL');
	const entityTypeLabel = ref('');
	const externalId = ref('');
	const dripEnabled = ref(false);
	const navReinvestmentPreference = ref<NAVReinvestmentPreference | null>(null);
	const dripPreference = ref<DripStatus | string>('');
	const featureEligibility = ref<FeatureEligibility>({
		advancedReporting: 'DISABLED',
		customAllocation: 'DISABLED',
		directInvest: 'DISABLED'
	});
	const freezeReason = ref('');
	const hasActiveRecurringInvestmentSchedule = ref(false);
	const hasAdvisoryServices = ref(false);
	const hasEverOwnedIpoShares = ref(false);
	const hasLegacyInvestments = ref(false);
	const investmentEntityId = ref('');
	const investmentEntityName = ref('');
	const investorGoalExists = ref(false);
	const iraEntityMetadata = ref<IraEntityMetadata>({
		isMtcIra: false,
		isIraOnboardingFinished: false,
		iraType: null,
		iraTypeLabel: ''
	});
	const isFrozen = ref(false);
	const isIraEntity = ref(false);
	const isReitInvestor = ref(false);
	const isVerifiedAccredited = ref(false);
	const nickname = ref('');
	const showBrowseMenuItem = ref(false);
	const showTaxCenter = ref(false);
	const userReferralEligibilityStatus = ref<UserReferralEligibilityStatus>('INELIGIBLE');
	const notifications = ref<NotificationResponse>({
		notifications: [],
		generatedTimestamp: '',
		lastNotificationView: ''
	});
	const riaPlan = ref<RiaPlan | null>(null);
	// Populated on request
	const additionalEmailRecipients = ref<Array<EmailRecipient>>([]);
	const autoInvestSchedule = ref<AutoInvestSchedule | null>(null);
	const bankAccounts = ref<Array<BankAccount>>([]);
	const entityAccountDetails = ref<EntityAccountDetails | null>(null);
	const iraAccountDetails = ref<IraAccountDetails | null>(null);
	const jointAccountDetails = ref<JointAccountDetails | null>(null);
	const trustAccountDetails = ref<TrustAccountDetails | null>(null);
	const paymentMethods = ref<PaymentMethods | null>(null);
	const positions = ref<Positions | null>(null);
	const performance = ref<Performance | null>(null);
	const investmentEntityObject = computed<InvestmentEntity>(() => ({
		additionalInvestmentEligibility: additionalInvestmentEligibility.value,
		currentRiaPlanName: currentRiaPlanName.value,
		currentRiaPlanInvestmentStrategy: currentRiaPlanInvestmentStrategy.value,
		currentSelectedRiaPlanId: currentSelectedRiaPlanId.value,
		dripEnabled: dripEnabled.value,
		eligibleForDrip: eligibleForDrip.value,
		eligibleForInvestorGoal: eligibleForInvestorGoal.value,
		eligibleForRecurringInvestment: eligibleForRecurringInvestment.value,
		eligibleForShareholderVote: eligibleForShareholderVote.value,
		entityType: entityType.value,
		entityTypeLabel: entityTypeLabel.value,
		externalId: externalId.value,
		featureEligibility: featureEligibility.value,
		freezeReason: freezeReason.value,
		hasActiveRecurringInvestmentSchedule: hasActiveRecurringInvestmentSchedule.value,
		hasAdvisoryServices: hasAdvisoryServices.value,
		hasEverOwnedIpoShares: hasEverOwnedIpoShares.value,
		hasLegacyInvestments: hasLegacyInvestments.value,
		investmentEntityId: investmentEntityId.value,
		investmentEntityName: investmentEntityName.value,
		investorGoalExists: investorGoalExists.value,
		iraEntityMetadata: iraEntityMetadata.value,
		isFrozen: isFrozen.value,
		isIraEntity: isIraEntity.value,
		isReitInvestor: isReitInvestor.value,
		isVerifiedAccredited: isVerifiedAccredited.value,
		nickname: nickname.value,
		showBrowseMenuItem: showBrowseMenuItem.value,
		showTaxCenter: showTaxCenter.value,
		userReferralEligibilityStatus: userReferralEligibilityStatus.value
	}));
	const isLegacyInvestorWithCashDistribution = computed<boolean>(
		() => !currentSelectedRiaPlanId.value && !dripEnabled.value
	);
	const isLegacyInvestorWithoutPlan = computed<boolean>(
		() => isReitInvestor.value && additionalInvestmentEligibility.value === 'ENTITY_NO_PLAN_SELECTION'
	);
	const hasNeverBeenAnInvestor = computed<boolean>(() => !isReitInvestor.value && !hasLegacyInvestments.value);
	const isDirectInvestEligible = computed<boolean>(() => featureEligibility.value.directInvest === 'ELIGIBLE');
	const isEligibleForReinvestment = computed<boolean>(
		() => eligibleForDrip.value || !isLegacyInvestorWithoutPlan.value
	);
	const isRedeemedNoDirectInvestNoPlanAccount = computed<boolean>(() => {
		const modularDashboardStore = useModularDashboardStore();
		const isAccountFullyRedeemed =
			modularDashboardStore.modularDashboardMetadata?.settlementState === 'FULLY_REDEEMED';
		return isAccountFullyRedeemed && !isDirectInvestEligible.value && !currentRiaPlanName.value;
	});

	const hasActiveAutoInvestSchedule = computed((): boolean => {
		return hasActiveRecurringInvestmentSchedule.value && !hasPausedAutoInvestSchedule.value;
	});

	const hasPausedAutoInvestSchedule = computed((): boolean => {
		return Boolean(autoInvestSchedule.value?.resumeDate);
	});

	const isEligibleForReferral = computed((): boolean => {
		return userReferralEligibilityStatus.value !== 'INELIGIBLE';
	});

	async function setInvestmentEntity(newEntity: InvestmentEntity): Promise<void> {
		$reset();
		setItemInLocalStorage('lastSelectedInvestmentEntity', newEntity.investmentEntityId);
		additionalInvestmentEligibility.value = newEntity.additionalInvestmentEligibility;
		currentRiaPlanName.value = newEntity?.currentRiaPlanName ?? '';
		currentRiaPlanInvestmentStrategy.value = newEntity?.currentRiaPlanInvestmentStrategy ?? null;
		currentSelectedRiaPlanId.value = newEntity?.currentSelectedRiaPlanId ?? '';
		dripEnabled.value = newEntity.dripEnabled;
		eligibleForDrip.value = newEntity.eligibleForDrip;
		eligibleForInvestorGoal.value = newEntity.eligibleForInvestorGoal;
		eligibleForRecurringInvestment.value = newEntity.eligibleForRecurringInvestment;
		eligibleForShareholderVote.value = newEntity.eligibleForShareholderVote;
		entityType.value = newEntity.entityType;
		entityTypeLabel.value = newEntity?.entityTypeLabel ?? '';
		externalId.value = newEntity.externalId;
		featureEligibility.value = newEntity.featureEligibility;
		freezeReason.value = newEntity?.freezeReason ?? '';
		hasActiveRecurringInvestmentSchedule.value = newEntity.hasActiveRecurringInvestmentSchedule;
		hasAdvisoryServices.value = newEntity.hasAdvisoryServices;
		hasEverOwnedIpoShares.value = newEntity.hasEverOwnedIpoShares;
		hasLegacyInvestments.value = newEntity.hasLegacyInvestments;
		investmentEntityId.value = newEntity.investmentEntityId;
		investmentEntityName.value = newEntity.investmentEntityName;
		investorGoalExists.value = newEntity.investorGoalExists;
		iraEntityMetadata.value = newEntity.iraEntityMetadata;
		isFrozen.value = newEntity.isFrozen;
		isIraEntity.value = newEntity.isIraEntity;
		isReitInvestor.value = newEntity.isReitInvestor;
		isVerifiedAccredited.value = newEntity.isVerifiedAccredited;
		nickname.value = newEntity.nickname ?? '';
		showBrowseMenuItem.value = newEntity.showBrowseMenuItem;
		showTaxCenter.value = newEntity.showTaxCenter;
		userReferralEligibilityStatus.value = newEntity.userReferralEligibilityStatus;
		await storeNotifications();
		if (newEntity.currentRiaPlanName) {
			await storeRiaPlanForEntity();
		}
		const appStore = useAppStore();
		appStore.initializeLaunchDarklyInstance();
		setDatadogUserEntityData(newEntity);
	}
	async function storeRiaPlanForEntity(): Promise<void> {
		try {
			const riaPlanData = await getCurrentRiaPlan();
			riaPlan.value = riaPlanData;
		} catch {}
	}
	async function updateRiaPlanForEntity(plan: RiaPlan): Promise<void> {
		await updateRiaPlan(plan.id);
		riaPlan.value = plan;
		currentSelectedRiaPlanId.value = plan.id;
		currentRiaPlanName.value = plan.name;
		currentRiaPlanInvestmentStrategy.value = plan.investmentStrategy;
	}
	async function storeAutoInvestSchedule(force = false): Promise<void> {
		if (force || autoInvestSchedule.value === null) {
			const autoInvestScheduleData = await getRecurringInvestmentSchedule();
			autoInvestSchedule.value = autoInvestScheduleData;
			setDatadogAutoInvestSchedule(!!autoInvestScheduleData);
		}
	}
	async function storeBankAccounts(force = false): Promise<void> {
		if (force || bankAccounts.value.length === 0) {
			const bankAccountsData = await getBankAccounts();
			bankAccounts.value = bankAccountsData;
		}
	}
	async function storePaymentMethods(force = false): Promise<void> {
		if (paymentMethods.value === null || force) {
			const paymentMethodsData = await getPaymentMethods();
			paymentMethods.value = paymentMethodsData;
		}
	}
	async function storeBeneficiaries(force = false): Promise<void> {
		if (beneficiaries.value.length === 0 || force) {
			const beneficiariesData = await getBeneficiaries();
			beneficiaries.value = beneficiariesData;
		}
	}
	async function storeEmailRecipients(force = false): Promise<void> {
		if (additionalEmailRecipients.value.length === 0 || force) {
			const recipients = await getEmailRecipients();
			additionalEmailRecipients.value = recipients;
		}
	}
	async function storeEntityAccountDetails(force = false): Promise<void> {
		if (!entityAccountDetails.value || force) {
			const entityAccountDetailsData = await getEntityAccountDetails(investmentEntityId.value);
			entityAccountDetails.value = entityAccountDetailsData;
		}
	}
	async function storeIraAccountDetails(): Promise<void> {
		if (entityType.value === 'IRA' && iraAccountDetails.value === null) {
			const iraAccountDetailsData = await getIraAccountDetails();
			iraAccountDetails.value = iraAccountDetailsData;
		}
	}
	async function storeJointAccountDetails(force = false): Promise<void> {
		if (!jointAccountDetails.value || force) {
			const jointAccountDetailsData = await getJointAccountDetails();
			jointAccountDetails.value = jointAccountDetailsData;
		}
	}
	async function storeNotifications(): Promise<void> {
		const appStore = useAppStore();
		if (notifications.value.notifications.length === 0 && !appStore.isThirdPartyLogin) {
			const notificationsData = await getNotifications();
			notifications.value = notificationsData;
		}
	}
	async function updateNotificationsView(): Promise<void> {
		const timestamp = notifications.value.generatedTimestamp;
		await updateNotifications({ timestamp });
		notifications.value.lastNotificationView = timestamp;
	}
	async function storePositions(): Promise<void> {
		if (positions.value === null) {
			const positionsData = await getPositionsTable();
			positions.value = positionsData;
			setDatadogUserAUM(positionsData.totalAccountValue);
		}
	}
	async function refreshPositionsAndPerformance(): Promise<void> {
		const performancePortfolioStore = usePerformancePortfolioStore();
		const modularDashboardStore = useModularDashboardStore();
		const positionsData = await getPositionsTable();
		positions.value = positionsData;
		const performanceData = await getPerformanceData();
		performance.value = performanceData;
		await modularDashboardStore.$reset();
		performancePortfolioStore.$reset();
	}
	async function storePerformance(): Promise<void> {
		if (performance.value === null) {
			const performanceData = await getPerformanceData();
			performance.value = performanceData;
		}
	}
	async function storeTrustAccountDetails(force = false): Promise<void> {
		if (!trustAccountDetails.value || force) {
			const trustAccountDetailsData = await getTrustAccountDetails();
			trustAccountDetails.value = trustAccountDetailsData;
		}
	}
	async function storeNAVReinvestmentPreference(): Promise<void> {
		if (navReinvestmentPreference.value === null) {
			const navReinvestmentPreferenceData = await getNAVReinvestmentPreference();
			navReinvestmentPreference.value = navReinvestmentPreferenceData;
		}
	}
	async function storeDripPreference(): Promise<void> {
		if (dripPreference.value === '') {
			const dripPreferenceData = await getDrip();
			dripPreference.value = dripPreferenceData;
		}
	}
	async function storeDocumentDeliveryPreference(): Promise<void> {
		if (documentDeliveryPreference.value === null) {
			const preference = await getDocumentDeliveryPreference();
			documentDeliveryPreference.value = preference;
		}
	}
	async function updateDocumentDeliveryPreference(preference: DocumentDeliveryPreferenceEnum): Promise<void> {
		const userStore = useUserStore();
		await updateDocumentDeliveryPreferenceAPI(preference);
		await userStore.getUser();
		documentDeliveryPreference.value = preference;
	}
	async function submitManualBankAccount(payload: NewManualBankAccount): Promise<BankAccount> {
		const linkedAccount = await linkManualBankAccount(payload);
		bankAccounts.value.push(linkedAccount);
		return linkedAccount;
	}
	async function updateUserAndEmailRecipients(): Promise<void> {
		const userStore = useUserStore();
		await userStore.getUser();
		await storeEmailRecipients(true);
	}
	async function addPaymentMethod(paymentMethod: PaymentMethod): Promise<void> {
		if (isBankPaymentMethod(paymentMethod)) {
			paymentMethods.value?.bankAccounts.push(paymentMethod);
		}
	}
	async function addOrUpdateEmailRecipient(payload: { recipientId: string; details: EmailRecipient }): Promise<void> {
		if (payload.recipientId) {
			await updateEmailRecipient(payload.recipientId, payload.details);
		} else {
			await addNewEmailRecipient(payload.details);
		}
		await updateUserAndEmailRecipients();
	}
	async function deleteEmailRecipient(recipientId: string): Promise<void> {
		await deleteEmailRecipientAPI(recipientId);
		await updateUserAndEmailRecipients();
	}
	async function updateNickname(newNickname: string): Promise<void> {
		nickname.value = newNickname;
		await updateNicknameAPI(newNickname);
	}
	async function deleteNickname(): Promise<void> {
		nickname.value = '';
		await deleteNicknameAPI();
	}
	async function deleteRecurringInvestmentSchedule(): Promise<void> {
		await deleteRecurringInvestmentScheduleAPI();
		autoInvestSchedule.value = null;
		hasActiveRecurringInvestmentSchedule.value = false;
	}
	function $reset(): void {
		additionalInvestmentEligibility.value = 'ENTITY_MISSING_INFORMATION';
		currentRiaPlanName.value = '';
		currentRiaPlanInvestmentStrategy.value = null;
		currentSelectedRiaPlanId.value = '';
		dripEnabled.value = false;
		eligibleForDrip.value = false;
		eligibleForInvestorGoal.value = false;
		eligibleForRecurringInvestment.value = false;
		eligibleForShareholderVote.value = false;
		entityType.value = 'INDIVIDUAL';
		entityTypeLabel.value = '';
		externalId.value = '';
		featureEligibility.value = {
			advancedReporting: 'DISABLED',
			customAllocation: 'DISABLED',
			directInvest: 'DISABLED'
		};
		freezeReason.value = '';
		hasActiveRecurringInvestmentSchedule.value = false;
		hasAdvisoryServices.value = false;
		hasEverOwnedIpoShares.value = false;
		hasLegacyInvestments.value = false;
		investmentEntityId.value = '';
		investmentEntityName.value = '';
		investorGoalExists.value = false;
		iraEntityMetadata.value = {
			isMtcIra: false,
			isIraOnboardingFinished: false,
			iraType: null,
			iraTypeLabel: ''
		};
		isFrozen.value = false;
		isIraEntity.value = false;
		isReitInvestor.value = false;
		isVerifiedAccredited.value = false;
		nickname.value = '';
		riaPlan.value = null;
		showBrowseMenuItem.value = false;
		showTaxCenter.value = false;
		userReferralEligibilityStatus.value = 'INELIGIBLE';
		additionalEmailRecipients.value = [];
		autoInvestSchedule.value = null;
		bankAccounts.value = [];
		paymentMethods.value = null;
		positions.value = null;
		performance.value = null;
		jointAccountDetails.value = null;
		trustAccountDetails.value = null;
		iraAccountDetails.value = null;
		entityAccountDetails.value = null;
		beneficiaries.value = [];
	}
	return {
		additionalInvestmentEligibility,
		beneficiaries,
		currentRiaPlanName,
		currentRiaPlanInvestmentStrategy,
		currentSelectedRiaPlanId,
		documentDeliveryPreference,
		eligibleForDrip,
		eligibleForInvestorGoal,
		eligibleForRecurringInvestment,
		eligibleForShareholderVote,
		entityType,
		entityTypeLabel,
		externalId,
		dripEnabled,
		navReinvestmentPreference,
		dripPreference,
		featureEligibility,
		freezeReason,
		hasActiveAutoInvestSchedule,
		hasActiveRecurringInvestmentSchedule,
		hasAdvisoryServices,
		hasEverOwnedIpoShares,
		hasLegacyInvestments,
		hasPausedAutoInvestSchedule,
		investmentEntityId,
		investmentEntityName,
		investorGoalExists,
		iraEntityMetadata,
		isEligibleForReferral,
		isFrozen,
		isIraEntity,
		isReitInvestor,
		isVerifiedAccredited,
		nickname,
		showBrowseMenuItem,
		showTaxCenter,
		userReferralEligibilityStatus,
		notifications,
		riaPlan,
		additionalEmailRecipients,
		addPaymentMethod,
		autoInvestSchedule,
		bankAccounts,
		entityAccountDetails,
		iraAccountDetails,
		jointAccountDetails,
		trustAccountDetails,
		paymentMethods,
		positions,
		performance,
		investmentEntityObject,
		isLegacyInvestorWithCashDistribution,
		isLegacyInvestorWithoutPlan,
		hasNeverBeenAnInvestor,
		isDirectInvestEligible,
		isEligibleForReinvestment,
		isRedeemedNoDirectInvestNoPlanAccount,
		setInvestmentEntity,
		storeRiaPlanForEntity,
		updateRiaPlanForEntity,
		storeAutoInvestSchedule,
		storeBankAccounts,
		storePaymentMethods,
		storeBeneficiaries,
		storeEmailRecipients,
		storeEntityAccountDetails,
		storeIraAccountDetails,
		storeJointAccountDetails,
		storeNotifications,
		updateNotificationsView,
		storePositions,
		refreshPositionsAndPerformance,
		storePerformance,
		storeTrustAccountDetails,
		storeNAVReinvestmentPreference,
		storeDripPreference,
		storeDocumentDeliveryPreference,
		updateDocumentDeliveryPreference,
		submitManualBankAccount,
		updateUserAndEmailRecipients,
		addOrUpdateEmailRecipient,
		deleteEmailRecipient,
		updateNickname,
		deleteNickname,
		deleteRecurringInvestmentSchedule,
		$reset
	};
});
