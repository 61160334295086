export const ytdReturnsByReitId2024: Record<string, string> = {
	'24': '7.47%',
	'25': '7.92%',
	'26': '12.81%',
	'2': '3.38%',
	'12': '(2.58%)',
	'16': '(5.48%)',
	'8': '1.65%',
	'14': '7.62%',
	'22': '8.71%',
	'3': '(6.56%)',
	'4': '4.72%',
	'5': '2.40%',
	'23': '8.70%'
};
