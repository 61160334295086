import { AnnualReturnsRow, AssetClassAnnualReturns } from 'types/annual-returns';
import { BlogCategory, BlogLanding, BlogPost, GlossaryTerm } from 'types/prismic/blog';
import { buildPremiumLandingPage, buildStrategiesShowcase } from '@utils/prismic/premium-landing-page';
import {
	EreitsLandingPage,
	IntercomPopupContentData,
	LogoListSliceData,
	MaintenancePageData,
	MediaPartners,
	PortfolioStats,
	PrismicDocument,
	PrismicObject,
	PrismicRef,
	PrismicSlice,
	ReviewsData,
	ReviewsPanelData,
	SocialStats
} from 'types/prismic';
import {
	InvestorUpdateSection,
	PrivateCreditPagePrismicData,
	RealEstatePageData,
	StrategiesSection
} from 'types/prismic/real-estate-page';
import { apiRequest } from '@utils/request';
import axios from 'axios';
import { buildRealEstatePage } from '@utils/prismic/real-estate-page';
import { LandingPageData } from 'types/prismic/landing-page';
import { NewsletterLandingPageData } from 'types/prismic/newsletter';
import { PremiumLandingPage } from 'types/prismic/premium-landing-page';
import { PrismicRiaPlanStrategyPage } from 'types/ria-plan-strategy';
import { serializePrismicContent } from '@utils/prismic/html-serializer';
import { UsersAndTestimonials } from 'types/marketing-site';
import { VentureCapitalPageData } from 'types/prismic/venture';
import { VoteDetailsContent } from 'types/shareholder-vote';
import { WhyFundriseSliceData } from 'types/prismic/why-fundrise';

async function getBaseUrl(customRef?: string): Promise<string> {
	const prismicHost = 'https://fundrise.cdn.prismic.io/api/v2';
	const prismicBaseUrl = `${prismicHost}/documents/search?ref=`;

	if (customRef) {
		return prismicBaseUrl + customRef;
	} else {
		const response = await axios.get(prismicHost);
		const refs: Array<PrismicRef> = response.data.refs;
		return prismicBaseUrl + refs[0].ref;
	}
}

export async function getAnnualReturnsTableData(): Promise<AssetClassAnnualReturns> {
	const baseUrl = await getBaseUrl();
	const requestUrl = `${baseUrl}&q=[[at(document.type, "annual_returns")]]`;
	const response = await axios.get(requestUrl);

	return response?.data?.results[0]?.data?.body?.reduce(
		(accum: AssetClassAnnualReturns, next: PrismicSlice) => {
			if (next.slice_type === 'annual_returns_data') {
				accum.annualReturnsData = next.items as AnnualReturnsRow[];
			} else if (next.slice_type === 'annual_returns_summaries') {
				accum.annualReturnsSummaries = next.items as AnnualReturnsRow[];
			}
			return accum;
		},
		{ annualReturnsData: [], annualReturnsSummaries: [] }
	);
}

function getBlogPostUrl(baseUrl: string, urlSlug: string): string {
	return `${baseUrl}&q=[[at(my.blog.uid, "${urlSlug}")]]&fetchLinks=category.name,author.name,blog.title,blog.abstract,blog.display_date`;
}

function getBlogsByCategoriesUrl(baseUrl: string, categoryId: string, limit: number): string {
	return `${baseUrl}&q=[[at(document.type, "blog")][at(my.blog.category, "${categoryId}")]]&orderings=[my.blog.display_date desc]&pageSize=${limit}&fetchLinks=category.name,category.description,author.name,blog.title,blog.abstract,blog.display_date`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getBlogPostFromResponse(response: any): BlogPost | null {
	return response.data.results && response.data.results.length > 0 ? response.data.results[0] : null;
}

export async function getBlogPost(urlSlug: string, customRef?: string): Promise<BlogPost | null> {
	const baseUrl = await getBaseUrl(customRef);

	const response = await axios.get(getBlogPostUrl(baseUrl, urlSlug));

	return getBlogPostFromResponse(response);
}

export async function getBlogPosts(urlSlugs: Array<string>, customRef?: string): Promise<Array<BlogPost>> {
	const baseUrl = await getBaseUrl(customRef);
	const requestUrls = urlSlugs.map((slug) => getBlogPostUrl(baseUrl, slug));

	const responses = await axios.all(requestUrls.map((url) => axios.get(url)));

	const result: Array<BlogPost> = [];

	responses.forEach((response) => {
		const blog = getBlogPostFromResponse(response);
		if (blog !== null) {
			result.push(blog);
		}
	});
	return result;
}

export async function getBlogLanding(customRef?: string): Promise<BlogLanding | null> {
	const baseUrl = await getBaseUrl(customRef);
	const response = await axios.get(
		`${baseUrl}&q=[[at(document.type, "blog_landing")]]&fetchLinks=categories.category_name,blog.title,blog.abstract,blog.cover_image`
	);

	return response.data.results && response.data.results.length > 0 ? response.data.results[0] : null;
}

export async function getBlogCategories(customRef?: string): Promise<Array<BlogCategory>> {
	const baseUrl = await getBaseUrl(customRef);
	const response = await axios.get(`${baseUrl}&q=[[at(document.type, "category")]]`);

	return response.data.results;
}

export async function getBlogCategory(categorySlug: string, customRef?: string): Promise<BlogCategory | null> {
	const baseUrl = await getBaseUrl(customRef);
	const requestUrl = `${baseUrl}&q=[[at(my.category.uid, "${categorySlug}")]]`;
	const response = await axios.get(requestUrl);

	return response.data.results && response.data.results.length > 0 ? response.data.results[0] : null;
}

export async function getBlogsByCategory(categoryId: string, limit = 5, customRef?: string): Promise<Array<BlogPost>> {
	const baseUrl = await getBaseUrl(customRef);
	const requestUrl = getBlogsByCategoriesUrl(baseUrl, categoryId, limit);

	const response = await axios.get(requestUrl);

	return response.data.results || [];
}

export async function getBlogsByCategories(
	categoryIds: Array<string>,
	limit = 5,
	customRef?: string
): Promise<Array<Array<BlogPost>>> {
	const baseUrl = await getBaseUrl(customRef);
	const requestUrls = categoryIds.map((categoryId) => getBlogsByCategoriesUrl(baseUrl, categoryId, limit));

	const responses = await axios.all(requestUrls.map((url) => axios.get(url)));

	return responses.map((response) => response.data.results || []);
}

export async function getFlagshipFundStrategies(): Promise<StrategiesSection> {
	const baseUrl = await getBaseUrl();
	const response = await axios.get(`${baseUrl}&q=[[at(document.type, "flagship_fund_strategies_section")]]`);
	const prismicData = response.data.results[0].data;

	const processedData: StrategiesSection = { sectionDisclaimer: [], strategies: [] };
	const flattenedDisclaimers = prismicData.strategies_section_disclaimers.map(
		(ssd: { disclaimer: string }) => ssd.disclaimer
	);
	processedData.sectionDisclaimer = flattenedDisclaimers;
	processedData.strategies = buildStrategiesShowcase(prismicData.strategies_showcase);

	return processedData;
}

export async function getPreview(previewToken: string, documentId: string): Promise<PrismicDocument> {
	const baseUrl = await getBaseUrl(previewToken);
	const requestUrl = `${baseUrl}&q=[[at(document.id, "${documentId}")]]`;
	const response = await axios.get(requestUrl);

	return response.data.results && response.data.results.length > 0 ? response.data.results[0] : {};
}

export async function getSocialStats(): Promise<SocialStats> {
	const baseUrl = await getBaseUrl();
	const requestUrl = `${baseUrl}&q=[[at(document.type, "social_stats")]]`;
	const response = await axios.get(requestUrl);

	return response.data.results[0].data;
}

export async function getReviewsData(): Promise<ReviewsData> {
	const baseUrl = await getBaseUrl();
	const requestUrl = `${baseUrl}&q=[[at(document.type, "reviews")]]`;
	const response = await axios.get(requestUrl);

	return response.data.results[0].data;
}

export async function getUsersAndTestimonialsData(): Promise<UsersAndTestimonials> {
	const baseUrl = await getBaseUrl();
	const requestUrl = `${baseUrl}&q=[[at(document.type, "users_and_testimonials")]]`;
	const response = await axios.get(requestUrl);

	return response.data.results[0].data;
}

export async function getGlossaryTerm(termSlug: string, customRef?: string): Promise<GlossaryTerm | null> {
	const baseUrl = await getBaseUrl(customRef);
	const requestUrl = `${baseUrl}&q=[[at(my.glossary.uid, "${termSlug}")]]`;
	const response = await axios.get(requestUrl);

	return response.data.results && response.data.results.length > 0 ? response.data.results[0] : null;
}

export async function getOrderedGlossaryTerms(limit: number, customRef?: string): Promise<Array<GlossaryTerm>> {
	const baseUrl = await getBaseUrl(customRef);
	const response = await axios.get(
		`${baseUrl}&q=[[at(document.type, "glossary")]]&orderings=[my.glossary.title]&pageSize=${limit}`
	);

	return response.data.results;
}

export async function getMediaPartners(): Promise<MediaPartners | null> {
	const baseUrl = await getBaseUrl();
	const response = await axios.get(`${baseUrl}&q=[[at(document.type, "media_partners")]]`);

	return response.data.results && response.data.results.length > 0 ? response.data.results[0].data : null;
}

export async function getPortfolioStats(): Promise<PortfolioStats> {
	const baseUrl = await getBaseUrl();
	const prismicRequestUrl = axios.get(`${baseUrl}&q=[[at(document.type, "social_stats")]]`);
	const reitElementStrategyTotalsUrl = apiRequest.get(`/reit-elements/strategy-totals`);

	const responses = await axios.all([prismicRequestUrl, reitElementStrategyTotalsUrl]);

	return {
		prismicStats: responses[0].data.results[0].data,
		reitsTotals: responses[1].data
	};
}

export async function getAdvisorsTrackRecord(): Promise<PrismicDocument | null> {
	const baseUrl = await getBaseUrl();
	const response = await axios.get(
		`${baseUrl}&q=[[at(document.type, "advisors_track_record")]]&fetchLinks=blog.title,blog.abstract,blog.display_date, blog.show_new_badge`
	);
	return response.data.results && response.data.results.length > 0 ? response.data.results[0] : null;
}

export async function getEreitsLanding(): Promise<EreitsLandingPage | null> {
	const baseUrl = await getBaseUrl();
	const response = await axios.get(`${baseUrl}&q=[[at(document.type, "ereits_landing")]]`);

	return response.data.results && response.data.results.length > 0 ? response.data.results[0].data : null;
}

export async function getReviewsPanelData(): Promise<ReviewsPanelData | null> {
	const baseUrl = await getBaseUrl();
	const response = await axios.get(`${baseUrl}&q=[[at(my.global_content.uid, "reviews-panel")]]`);

	return response.data.results && response.data.results.length > 0 ? response.data.results[0].data.body[0] : null;
}

export async function getLandingPage(pageSlug: string, customRef?: string): Promise<LandingPageData | null> {
	const baseUrl = await getBaseUrl(customRef);
	const response = await axios.get(
		`${baseUrl}&q=[[at(my.landing_page.uid, "${pageSlug}")]]&fetchLinks=blog.title,blog.cover_image,global_content.body`
	);

	const results = response.data.results?.filter((r: { uid: string }) => r.uid === pageSlug) ?? [];

	return results.length ? results[0].data : null;
}

export async function getNewsletter(): Promise<NewsletterLandingPageData | null> {
	const baseUrl = await getBaseUrl();
	const response = await axios.get(`${baseUrl}&q=[[at(document.type, "newsletter")]]`);

	return response.data.results && response.data.results.length > 0 ? response.data.results[0].data : null;
}

export async function getPremiumLandingPage(customRef?: string): Promise<PremiumLandingPage | null> {
	const baseUrl = await getBaseUrl(customRef);
	const response = await axios.get(`${baseUrl}&q=[[at(document.type, "premium_landing_page")]]`);
	return response.data.results && response.data.results.length > 0
		? buildPremiumLandingPage(response.data.results[0].data)
		: null;
}

function getShareholderVoteContentUrl(baseUrl: string, id: string): string {
	return `${baseUrl}&q=[[at(my.shareholder_vote_detail.uid, "shareholder-vote-${id}")]]`;
}

export async function getShareholderVoteDescription(shareholderVoteId: string): Promise<VoteDetailsContent | null> {
	const baseUrl = await getBaseUrl();
	const requestUrl = getShareholderVoteContentUrl(baseUrl, shareholderVoteId);
	try {
		const response = await axios.get(requestUrl);
		return response.data.results.length > 0 ? response.data.results[0].data : null;
	} catch (e) {
		return Promise.resolve(null);
	}
}

export async function getAdvisorPartnersInfo(): Promise<LogoListSliceData | null> {
	const baseUrl = await getBaseUrl();
	const response = await axios.get(`${baseUrl}&q=[[at(my.global_content.uid, "advisor-partner-logos")]]`);

	return response.data.results && response.data.results.length > 0 ? response.data.results[0].data.body[0] : null;
}

export async function getRealEstatePage(customRef?: string): Promise<RealEstatePageData | null> {
	const baseUrl = await getBaseUrl(customRef);
	const response = await axios.get(
		`${baseUrl}&q=[[at(document.type, "real_estate_page")]]&fetchLinks=blog.title,blog.abstract,blog.cover_image,blog.display_date`
	);
	return buildRealEstatePage(response.data.results[0]?.data);
}

export async function getPrivateCreditPage(customRef?: string): Promise<InvestorUpdateSection | null> {
	const baseUrl = await getBaseUrl(customRef);
	const response = await axios.get(
		`${baseUrl}&q=[[at(document.type, "private_credit_page")]]&fetchLinks=blog.title,blog.abstract,blog.cover_image,blog.display_date`
	);
	const prismicData: PrivateCreditPagePrismicData | undefined = response.data.results[0]?.data;
	if (prismicData) {
		return {
			heading: prismicData.investor_updates_heading,
			investorUpdates: prismicData.investor_updates.map((wrapper) => wrapper.investor_update)
		};
	} else {
		return null;
	}
}

export async function getVentureCapitalPage(): Promise<VentureCapitalPageData> {
	const baseUrl = await getBaseUrl();
	const requestUrl = `${baseUrl}&q=[[at(document.type, "venture_landing_page")]]&fetchLinks=blog.title,blog.abstract,blog.cover_image,blog.display_date`;
	const response = await axios.get(requestUrl);
	const responseData = response.data.results[0]?.data;

	const simplifiedInvestorUpdates = responseData.additional_reading.map(
		(wrapper: { investor_update: PrismicObject }) => wrapper.investor_update
	);

	return {
		additional_reading: simplifiedInvestorUpdates
	};
}

export async function getWhyFundrisePage(): Promise<Array<WhyFundriseSliceData>> {
	const baseUrl = await getBaseUrl();
	const response = await axios.get(`${baseUrl}&q=[[at(document.type, "why_fundrise_page")]]`);
	return response.data.results[0]?.data?.body ?? [];
}

export async function getMaintenanceWindowContent(): Promise<MaintenancePageData | null> {
	const baseUrl = await getBaseUrl();
	const response = await axios.get(`${baseUrl}&q=[[at(my.global_content.uid, "maintenance-window")]]`);
	const data = response.data.results[0]?.data?.body[0].primary;

	return data
		? {
				headline: data.headline,
				description: serializePrismicContent(data.text).join(''),
				imageUrl: data.image.url,
				imageAlt: data.image.alt
			}
		: null;
}

export async function getIntercomPopupContent(): Promise<IntercomPopupContentData> {
	const baseUrl = await getBaseUrl();
	const response = await axios.get(`${baseUrl}&q=[[at(document.type, "intercom_launcher")]]`);
	const data = response.data.results[0]?.data;

	return {
		primary: {
			title: data.title,
			body: serializePrismicContent(data.body),
			ctas: data.ctas.map((cta: { label: string; prepopulated_message: string }) => ({
				label: cta.label,
				message: cta.prepopulated_message
			}))
		},
		slices: data.slices.map((slice: PrismicSlice) => ({
			route: slice.primary?.route ?? '',
			title: slice.primary?.slice_title ?? '',
			body: serializePrismicContent(slice.primary?.slice_body ?? []),
			ctas:
				slice.items?.map((cta) => ({
					label: cta.label,
					message: cta.prepopulated_message
				})) ?? []
		}))
	};
}

export async function getRiaPlanStrategyPageData(
	planId: string,
	customRef?: string
): Promise<PrismicRiaPlanStrategyPage | null> {
	const baseUrl = await getBaseUrl(customRef);
	const response = await axios.get(`${baseUrl}&q=[[at(my.plan_detail_page.uid, "${planId}")]]`);

	return response.data.results[0]?.data ?? null;
}
