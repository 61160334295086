import { apiRequest, apiSecureRequest } from '@utils/request';
import { MixpanelIdDto } from 'types/analytics';
import { useAppStore } from '@stores/app';

export async function updateMixpanelId(dto: MixpanelIdDto): Promise<void> {
	const appStore = useAppStore();
	if (appStore.isAuthenticated) {
		await apiSecureRequest.post(`/mixpanel/update-id`, dto);
	} else {
		await apiRequest.post(`/mixpanel/update-id`, dto);
	}
}
