import {
	EmailCaptureFields,
	LeadParameters,
	NonShoppingLead,
	NonShoppingLeadCaptureResponse,
	OcfLeadParams
} from 'types/layout/lead';
import { getDistinctId, identifyMixpanel } from '@utils/analytics';
import { getItemFromSessionStorage, removeItemFromSessionStorage, setItemInLocalStorage } from '@utils/web-storage';
import { ThirdPartyAuthType, ThirdPartySignupParameters } from 'types/third-party-auth';
import { apiRequest } from '@utils/request';
import { AxiosResponse } from 'axios';
import { CreateThirdPartyUserResponse } from 'types/user';
import { setUserLoggedInCookie } from '@utils/user';
import { useSignupStore } from '@stores/signup';

function trackThirdPartyLeadEvents(): void {
	try {
		window.uetq = window.uetq || [];
		window.uetq.push('event', 'Email Capture', {
			event_category: 'Lead',
			event_label: 'Landing Page',
			event_value: '1'
		});
	} catch {}

	try {
		window.fbq('track', 'Lead');
	} catch {}

	try {
		window.pdst('lead', {
			value: 1.0,
			currency: 'USD'
		});
	} catch {}

	try {
		if (window.gtag) {
			window.gtag({
				event: 'lead'
			});
		}
	} catch {}
}

export async function captureLead(leadParameters: LeadParameters): Promise<EmailCaptureFields> {
	const signupStore = useSignupStore();
	const email = leadParameters.email;
	setItemInLocalStorage('emailcapture', leadParameters.email);
	const sessionParametersString = getItemFromSessionStorage('lead-capture-params');
	if (sessionParametersString) {
		try {
			leadParameters = Object.assign(leadParameters, JSON.parse(sessionParametersString));
			leadParameters.email = email;
			leadParameters.mixpanelId = getDistinctId() ?? '';
		} catch {}
	}

	const response = await apiRequest.post('/lead', leadParameters);

	signupStore.email = email;

	removeItemFromSessionStorage('lead-capture-params');

	const leadResponseData: EmailCaptureFields = response.data;

	if (leadResponseData.newLead) {
		trackThirdPartyLeadEvents();
	}

	identifyMixpanel(leadResponseData.mixpanelId);

	return leadResponseData;
}

export async function captureThirdPartyUser(
	endpoint: ThirdPartyAuthType,
	signupParameters: ThirdPartySignupParameters
): Promise<CreateThirdPartyUserResponse> {
	signupParameters.mixpanelId = getDistinctId() ?? '';

	const response = await apiRequest.post(`/user/${endpoint}`, signupParameters, {
		suppressToast: (resp: AxiosResponse) => resp.status === 400
	});
	const userResponseData: CreateThirdPartyUserResponse = response.data;
	setUserLoggedInCookie(userResponseData.accessToken);

	if (userResponseData.isNewUser) {
		trackThirdPartyLeadEvents();
	}

	identifyMixpanel(userResponseData.mixpanelId);

	return userResponseData;
}

export async function captureNonShoppingLead(
	parameters: NonShoppingLead | OcfLeadParams
): Promise<NonShoppingLeadCaptureResponse> {
	const response = await apiRequest.post('/non-shopping-lead', parameters);
	return response.data;
}
