<template>
	<span>
		<BaseButton :classes="baseLinkButtonClasses">
			<span class="slide-underline">{{ link.dropdown }}</span>
			<BaseSvgIcon name="chevron" width="20" height="20" dir="down" class="ml-25" />
		</BaseButton>
		<ul class="main-nav__sub-menu">
			<li
				v-for="(dropdownLink, dropdownLinkId) in link.links"
				:key="dropdownLinkId"
				class="main-nav__sub-menu-item"
			>
				<BaseLink :link="dropdownLink.link" classes="main-nav__sub-menu-item-link">
					<p class="slide-underline">{{ dropdownLink.link.text }}</p>
				</BaseLink>
			</li>
		</ul>
	</span>
</template>

<script setup lang="ts">
import { DropdownLinks, DropdownSubmenuLink } from 'types/header';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
	link: DropdownLinks;
}
const props = defineProps<Props>();
const route = useRoute();

const hasActiveSubMenuItem = computed((): boolean => {
	return !!props.link.links.find((link: DropdownSubmenuLink) => link.link.router === route?.name);
});

const baseLinkButtonClasses = computed(() => {
	const base = 'link main-nav__menu-item-link main-nav__menu-item-link--has-dropdown-arrow';

	return hasActiveSubMenuItem.value ? base + ' router-link-active' : base;
});
</script>
