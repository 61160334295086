import {
	getTwoFactorMetadata,
	sendEmail2faCode,
	sendPhoneCall2faCode,
	sendSms2faCode,
	submitTwoFactorAuthCode
} from '@api/login';
import { TwoFactorAuthCodeForm, TwoFactorCodeDeliveryMethod, TwoFactorMetadata } from 'types/authentication';
import { defineStore } from 'pinia';
import { OauthTokenResponse } from 'types/security';
import { ref } from 'vue';
import { sendMobileVerificationCode } from '@api/user';
import { useAppStore } from '@stores/app';

export const useLoginStore = defineStore('login', () => {
	const hasCompletedLoginMobileVerifySelect = ref(false);
	const selectedTwoFactorCodeDeliveryMethod = ref<TwoFactorCodeDeliveryMethod>('NONE');
	const twoFactorMetadata = ref<TwoFactorMetadata | null>(null);

	async function storeTwoFactorMetadata(): Promise<void> {
		if (selectedTwoFactorCodeDeliveryMethod.value === 'NONE') {
			const twoFactorMetadataResponse = await getTwoFactorMetadata();

			twoFactorMetadata.value = twoFactorMetadataResponse;
			selectedTwoFactorCodeDeliveryMethod.value =
				twoFactorMetadataResponse.twoFactorType === 'TOTP' ? 'TOTP' : 'NONE';
		}
	}

	function resetTwoFactorSelections(): void {
		selectedTwoFactorCodeDeliveryMethod.value = 'NONE';
	}

	async function submitLoginMobileVerifySelect(phoneNumberString: string): Promise<string> {
		hasCompletedLoginMobileVerifySelect.value = true;
		await sendMobileVerificationCode(phoneNumberString);
		return 'login-mobile-verify-confirm';
	}

	async function submitTwoFactorCode(twoFactorAuthForm: TwoFactorAuthCodeForm): Promise<OauthTokenResponse> {
		const successResponse: OauthTokenResponse = await submitTwoFactorAuthCode(twoFactorAuthForm);
		const appStore = useAppStore();
		await appStore.setUserAuthData(successResponse);
		selectedTwoFactorCodeDeliveryMethod.value = 'NONE';
		twoFactorMetadata.value = null;

		return successResponse;
	}

	async function send2faCode(method: TwoFactorCodeDeliveryMethod): Promise<string> {
		const nextRoute = 'login-two-factor-confirm';
		switch (method) {
			case 'EMAIL':
				selectedTwoFactorCodeDeliveryMethod.value = 'EMAIL';
				await sendEmail2faCode();
				return nextRoute;
			case 'SMS':
				selectedTwoFactorCodeDeliveryMethod.value = 'SMS';
				await sendSms2faCode();
				return nextRoute;
			case 'PHONE_CALL':
				selectedTwoFactorCodeDeliveryMethod.value = 'PHONE_CALL';
				await sendPhoneCall2faCode();
				return nextRoute;
			default:
				return '/';
		}
	}

	function $reset(): void {
		hasCompletedLoginMobileVerifySelect.value = false;
		selectedTwoFactorCodeDeliveryMethod.value = 'NONE';
		twoFactorMetadata.value = null;
	}

	return {
		hasCompletedLoginMobileVerifySelect,
		selectedTwoFactorCodeDeliveryMethod,
		twoFactorMetadata,
		storeTwoFactorMetadata,
		resetTwoFactorSelections,
		submitLoginMobileVerifySelect,
		submitTwoFactorCode,
		send2faCode,
		$reset
	};
});
