import { CheckoutRouteName, SignupStepName } from 'types/checkout';

export const signupStepToRouteMap: Record<SignupStepName, CheckoutRouteName> = {
	AUTO_INVEST_STEP: 'checkout-auto-invest',
	CONTACT_INFORMATION_STEP: 'checkout-individual-account-details',
	COUNTRY_STEP: 'checkout-select-country',
	DRIP_PREFERENCE_STEP: 'checkout-acknowledgments',
	ENTITY_INFORMATION_STEP: 'checkout-entity-account-details',
	ENTITY_TYPE_STEP: 'checkout-account-type',
	INVESTMENT_AMOUNT_STEP: 'checkout-payment-amount',
	IRA_CHECKOUT_STEP: 'ira-signup-start',
	JOINT_INFORMATION_STEP: 'checkout-joint-account-details',
	NAME_STEP: 'checkout-signup',
	ORDER_ACKNOWLEDGEMENTS_STEP: 'checkout-acknowledgments',
	PAYMENT_METHOD_STEP: 'checkout-payment-method',
	PHONE_NUMBER_PLAID_LAYER_STEP: 'checkout-phone-number-plaid-layer',
	PHONE_NUMBER_STEP: 'checkout-phone-number',
	PLAN_RECOMMENDATION_STEP: 'investment-recommendation',
	PLAN_SELECTION_STEP: 'plan-menu',
	PRESET_OFFERING_CLOSED_STEP: 'account-overview',
	PRESET_OFFERING_CONFIRMATION_STEP: 'checkout-preset-investment-confirmation',
	PRESET_PLAN_CONFIRMATION_STEP: 'checkout-preset-plan-confirmation',
	TAX_INFORMATION_STEP: 'checkout-tax-reporting-details',
	TRUST_INFORMATION_STEP: 'checkout-trust-account-details'
};
