<template>
	<SvgIcon
		:data="iconData"
		:width="width"
		:height="height"
		:scale="scale"
		:dir="dir"
		:fill="fill"
		:name="name"
		:title="title"
		:class="{ 'svg-no-stroke': !stroke }"
	/>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import type { VueSvgIcon } from '@yzfe/vue-svgicon';

type FundriseIconImport = { default: typeof VueSvgIcon };
const icons = import.meta.glob('@icons/svg/*.svg');

interface Props {
	name: string;
	width?: string | number;
	height?: string | number;
	scale?: string | number;
	dir?: string;
	title?: string;
	fill?: boolean;
	stroke?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	width: undefined,
	height: undefined,
	scale: undefined,
	dir: undefined,
	title: undefined,
	fill: true,
	stroke: false
});

const iconData = ref();

watchEffect(async () => {
	const svgPath = `/src/icons/svg/${props.name}.svg`;
	if (props.name && icons[svgPath]) {
		const icon: FundriseIconImport = (await icons[svgPath]()) as FundriseIconImport;
		if (icon?.default) {
			iconData.value = icon.default;
		}
	}
});
</script>
