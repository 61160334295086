import { FundriseRouteConfig, NavigationGuard } from 'vue-router';
import { determineLoginRoute } from '@utils/login-redirects';
import { logError } from '@utils/error-tracking';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useUserStore } from '@stores/user';
import { verifyEmail } from '@api/login';

const verifyEmailAddress = async (key: string, visitDashOnSuccess = true): Promise<ReturnType<NavigationGuard>> => {
	try {
		const appStore = useAppStore();
		const userStore = useUserStore();
		const investmentEntityStore = useInvestmentEntityStore();
		const verifyEmailResponse = await verifyEmail(key);
		await appStore.setUserAuthData(verifyEmailResponse);
		await userStore.storeLoginMethods();

		if (verifyEmailResponse.scope === 'ADVISOR_ACCESS') {
			appStore.addToast({
				type: 'success',
				message: 'Your email has been successfully verified.'
			});
			return { name: 'advisor-welcome' };
		} else if (visitDashOnSuccess || investmentEntityStore.isIraEntity) {
			appStore.addToast({
				type: 'success',
				message: 'Your email has been successfully verified.'
			});
			return determineLoginRoute();
		} else {
			return { name: 'reits-checkout-success' };
		}
	} catch {
		logError('Unable to verify user.');
		return { name: 'verify-email' };
	}
};

const signupRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/signup/verify-signup-checkout`,
		name: 'verify-signup-checkout',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		component: () => import('@views/checkout/checkout-success.vue'),
		beforeEnter: async (to) => {
			const appStore = useAppStore();
			if (appStore.isVerified) {
				return true;
			} else if (to.query.key && appStore.isAuthenticated) {
				return verifyEmailAddress(`${to.query.key}`, false);
			} else {
				return { name: 'unverified-email' };
			}
		}
	},
	{
		path: `/signup/unverified-email`,
		name: 'unverified-email',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		beforeEnter: async () => {
			const appStore = useAppStore();
			if (appStore.isVerified) {
				return { name: 'account-overview' };
			} else {
				return true;
			}
		},
		component: () => import('@views/email-verification/unverified-email.vue')
	},
	{
		path: `/signup/verify-email`,
		name: 'verify-email',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		component: () => import('@views/email-verification/verification-failed.vue'),
		beforeEnter: async (to) => {
			const appStore = useAppStore();
			if (appStore.isVerified) {
				return { name: 'home' };
			} else if (to.query.key && appStore.isAuthenticated) {
				const userStore = useUserStore();
				const shouldRedirectToDash = !userStore.hasFirstInvestment;
				return verifyEmailAddress(`${to.query.key}`, shouldRedirectToDash);
			} else {
				return true;
			}
		}
	},
	{
		path: `/signup/unverified-signup-checkout-email`,
		name: 'checkout-verify-email',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		beforeEnter: async () => {
			const appStore = useAppStore();
			if (appStore.isVerified) {
				return { name: 'home', replace: true };
			} else {
				return true;
			}
		},
		component: () => import('@views/checkout/checkout-verify-email.vue')
	}
];
export default signupRoutes;
