import { createIsbot, isbot } from 'isbot';
import { useAppStore } from '@stores/app';

export const userAgentIsBot = (userAgent = window.navigator.userAgent): boolean => {
	const isIeBot = createIsbot(/MSIE (\d+\.\d+);.*Trident\/(\d+\.\d+)/);
	return checkThirdParty(userAgent) || isbot(userAgent) || isIeBot(userAgent) || checkAzureBot(userAgent);
};

const userAgentIsThirdParty = createIsbot(/Chrome\/(?:1(?:0[0-8]|[0-9])|[1-9][0-9]?)\./);
const userAgentIsThirdPartyRecentVersion = createIsbot(/(Windows|Linux).*Chrome\/(129|131)/);

export const checkAzureBot = (userAgent = window.navigator.userAgent): boolean => {
	const azureViewportHeight = 960;
	const azureViewportWidth = 1280;
	const isAzureViewport = window.innerWidth === azureViewportWidth && window.innerHeight === azureViewportHeight;
	const azureBot = createIsbot(/(Windows).*Chrome\/(125)/);

	return azureBot(userAgent) && isAzureViewport;
};

export const checkThirdParty = (userAgent = window.navigator.userAgent): boolean => {
	const appStore = useAppStore();
	const thirdPartyViewportHeight = [671, 600];
	const thirdPartyViewportWidth = [800, 1004];
	const thirdPartyRoutes = ['/forbidden', '/login', '/account/overview', '/account/transactions', '/logout-success'];
	const route: boolean = thirdPartyRoutes.some((tpRoute) => tpRoute === window.location.pathname);
	const isThirdPartyViewport =
		thirdPartyViewportWidth.includes(window.innerWidth) && thirdPartyViewportHeight.includes(window.innerHeight);
	const isThirdPartyUserAgentLegacyBrowsers = userAgentIsThirdParty(userAgent);
	const isThirdPartyUserAgentRecentVersion =
		userAgentIsThirdPartyRecentVersion(userAgent) && route && isThirdPartyViewport;
	const isThirdPartyUserAgent = isThirdPartyUserAgentLegacyBrowsers || isThirdPartyUserAgentRecentVersion;

	return isThirdPartyUserAgent || appStore.isThirdPartyLogin;
};
