import { FundriseRouteConfig, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import {
	getAmountRoute,
	getPaymentMethodRoute,
	getStateDeterminerRoute,
	handleRequiredValues,
	verifyRequiredIraStates
} from '@router/ira/ira-signup-state-guards';
import { iraAddFundsDisabled, iraAddFundsDisabledForUser, iraOwnerNotUsResident } from '@constants/error-codes';
import { IraRequiredValue, IraSignupRequiredState, IraSignupRoute } from 'types/ira';
import { createIra } from '@api/ira';
import { InvestmentEntity } from 'types/investment-entity';
import { redirect } from '@utils/client';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useIraStore } from '@stores/ira';
import { useSignupStore } from '@stores/signup';
import { useUserStore } from '@stores/user';

const contributionRouteOrder: Array<IraSignupRoute> = [
	'ira-personal-information',
	'ira-mobile',
	'ira-funding',
	'ira-account-type',
	'ira-amount',
	'ira-max-contribution',
	'ira-plan',
	'ira-funding-source',
	'ira-bank-account-new',
	'ira-fee-schedule',
	'ira-drip',
	'ira-confirm',
	'ira-agreements',
	'ira-otp-type',
	'ira-mobile-verify',
	'ira-documents',
	'ira-success',
	'ira-disabled'
];

const transferRouteOrder: Array<IraSignupRoute> = [
	'ira-personal-information',
	'ira-mobile',
	'ira-funding',
	'ira-important-information',
	'ira-transfer-type',
	'ira-account-age',
	'ira-account-not-eligible',
	'ira-target-account-type',
	'ira-transfer-account-info',
	'ira-transfer-amount',
	'ira-plan',
	'ira-transfer-fees',
	'ira-bank-account-new',
	'ira-drip',
	'ira-confirm',
	'ira-agreements',
	'ira-otp-type',
	'ira-mobile-verify',
	'ira-documents',
	'ira-success',
	'ira-disabled'
];

const rolloverRouteOrder: Array<IraSignupRoute> = [
	'ira-personal-information',
	'ira-mobile',
	'ira-funding',
	'ira-important-information',
	'ira-rollover-type',
	'ira-rollover-source-account-type',
	'ira-rollover-mixed-error',
	'ira-target-account-type',
	'ira-rollover-method',
	'ira-rollover-check-date',
	'ira-rollover-check-not-eligible',
	'ira-transfer-amount',
	'ira-plan',
	'ira-transfer-fees',
	'ira-bank-account-new',
	'ira-drip',
	'ira-confirm',
	'ira-agreements',
	'ira-otp-type',
	'ira-mobile-verify',
	'ira-documents',
	'ira-success',
	'ira-disabled'
];

type SignupFundingTypes = 'CONTRIBUTION' | 'TRANSFER' | 'ROLLOVER';

const signupRouteOrder: Record<SignupFundingTypes, Array<IraSignupRoute>> = {
	CONTRIBUTION: contributionRouteOrder,
	TRANSFER: transferRouteOrder,
	ROLLOVER: rolloverRouteOrder
};

const getFlowStepIndex = (name: IraSignupRoute): number => {
	const ira = useIraStore();
	return signupRouteOrder[ira.fundingType as SignupFundingTypes].indexOf(name) + 1;
};

const stepConstructor = (route: RouteLocationNormalized): number => {
	return getFlowStepIndex(route.name as IraSignupRoute);
};

const hasLinkedIra = (): boolean => {
	const userStore = useUserStore();
	return (
		userStore.user?.entityDetails?.investmentEntities.some(
			(entity) => entity.entityType === 'IRA' && entity.iraEntityMetadata?.isIraOnboardingFinished
		) ?? false
	);
};

const getUnlinkedIraEntities = (): Array<InvestmentEntity> | [] => {
	const userStore = useUserStore();
	const unlinkedIraEnities = userStore.user?.entityDetails?.investmentEntities.filter(
		(entity) => entity.entityType === 'IRA' && entity.iraEntityMetadata.isIraOnboardingFinished === false
	);

	return unlinkedIraEnities ?? [];
};

async function validateIraSignupStep(
	next: NavigationGuardNext,
	requiredStates: IraSignupRequiredState[]
): Promise<void> {
	const appStore = useAppStore();
	const signupStore = useSignupStore();
	const userStore = useUserStore();
	if (appStore.isAuthenticated) {
		await signupStore.getUserCheckoutDetailsData();
		await userStore.getUser();
	}

	await verifyRequiredIraStates(next, requiredStates);
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const iraSignupRoutes: FundriseRouteConfig[] = [
	{
		path: `/ira/start`,
		name: 'ira-start',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		beforeEnter: async (to, from, next) => {
			const ira = useIraStore();
			const userStore = useUserStore();
			ira.flow = 'SIGN-UP';
			const investmentEntityStore = useInvestmentEntityStore();
			if (userStore.ssnNeedsUpdating || !userStore.hasDateOfBirth || !userStore.hasEmailAddress) {
				const appStore = useAppStore();
				next({
					name: 'profile-personal-information-view',
					replace: true
				});

				appStore.addToast({
					type: 'error',
					message:
						'Please confirm you have your date of birth, email, and SSN properly set on your account before creating an IRA.'
				});
			} else if (hasLinkedIra()) {
				const appStore = useAppStore();
				next({ name: 'account-overview', replace: true });
				appStore.addToast({
					type: 'error',
					message: 'You can only have a single active IRA account.'
				});
			} else if (!ira.dataRetrieved) {
				try {
					let iraEntity: string;
					const unlinkedIraEntities = getUnlinkedIraEntities();
					if (
						investmentEntityStore.iraEntityMetadata &&
						!investmentEntityStore.iraEntityMetadata.isIraOnboardingFinished
					) {
						iraEntity = investmentEntityStore.investmentEntityId;
					} else if (unlinkedIraEntities.length > 0) {
						iraEntity = unlinkedIraEntities[0].investmentEntityId;
					} else {
						const iraInvestmentEntity = await createIra({
							isSignup: false
						});

						await userStore.getUser();
						iraEntity = iraInvestmentEntity.investmentEntityId ?? '';
					}

					if (iraEntity !== investmentEntityStore.investmentEntityId) {
						await userStore.updateSelectedEntityWithoutReload({
							investmentEntityId: iraEntity,
							nextRoute: {
								route: {
									name: 'ira-start'
								},
								next
							}
						});
					} else {
						await ira.getEntryData();

						const isPlaidRedirect = to.query['oauth_state_id'];
						if (isPlaidRedirect) {
							const plaidOauthId = to.query['oauth_state_id'];
							const bankRoute =
								ira.fundingType === 'CONTRIBUTION' ? 'ira-funding-source' : 'ira-transfer-fees';
							await next({ name: bankRoute, replace: true, query: { oauth_state_id: plaidOauthId } });
						} else {
							const matchedRoute = getStateDeterminerRoute(ira.stateDeterminerStep);
							await next({ name: matchedRoute, replace: true });
						}
					}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					if (error.response?.data && error.response.status === 403) {
						const errorCode = error.response.data.errorCode;
						if (errorCode === iraAddFundsDisabled || errorCode === iraAddFundsDisabledForUser) {
							ira.dataRetrieved = true;
							await next({ name: 'ira-disabled', replace: true });
						} else if (errorCode === iraOwnerNotUsResident) {
							ira.dataRetrieved = true;
							await next({ name: 'ira-ineligible', replace: true });
						} else {
							redirect('/account/overview');
						}
					} else {
						redirect('/account/overview');
					}
				}
			} else {
				await next();
			}
		},
		component: () => import('@views/ira/signup/ira-signup.vue'),
		children: [
			{
				path: `ineligible`,
				name: 'ira-ineligible',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				component: () => import('@views/ira/signup/ira-ineligible.vue')
			},
			{
				path: `disabled`,
				name: 'ira-disabled',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				component: () => import('@views/ira/signup/ira-disabled.vue')
			},
			{
				path: `personal-information`,
				name: 'ira-personal-information',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = ['editingAllowed'];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-personal-info.vue')
			},
			{
				path: `information`,
				name: 'ira-important-information',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = ['personalInfo', 'rolloverOrTransferFlow'];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-important-info.vue')
			},
			{
				path: `account-type`,
				name: 'ira-transfer-type',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = ['personalInfo', 'transferFlow'];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-transfer-type.vue')
			},
			{
				path: `rollover-type`,
				name: 'ira-rollover-type',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = ['personalInfo', 'rolloverFlow'];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-rollover-type.vue')
			},
			{
				path: `rollover-account-type`,
				name: 'ira-rollover-source-account-type',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = ['personalInfo', 'rolloverFlow', 'rolloverType'];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-rollover-source-account-type.vue')
			},
			{
				path: `rollover-not-allowed`,
				name: 'ira-rollover-mixed-error',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = ['personalInfo', 'rolloverFlow'];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-rollover-mixed-error.vue')
			},
			{
				path: `rollover-method`,
				name: 'ira-rollover-method',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = [
						'personalInfo',
						'rolloverFlow',
						'rolloverType',
						'sourceAccountType'
					];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-rollover-method.vue')
			},
			{
				path: `rollover-date`,
				name: 'ira-rollover-check-date',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = [
						'personalInfo',
						'rolloverFlow',
						'rolloverType',
						'sourceAccountType',
						'rolloverMethodIndirect'
					];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-rollover-check-date.vue')
			},
			{
				path: `rollover-ineligible`,
				name: 'ira-rollover-check-not-eligible',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = [
						'personalInfo',
						'rolloverFlow',
						'rolloverType',
						'sourceAccountType',
						'rolloverMethodIndirect',
						'rolloverCheckDate'
					];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-rollover-check-not-eligible.vue')
			},
			{
				path: `account-age`,
				name: 'ira-account-age',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = [
						'personalInfo',
						'transferFlow',
						'sourceAccountType',
						'simpleSourceAccountType'
					];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-account-age.vue')
			},
			{
				path: `account-not-eligible`,
				name: 'ira-account-not-eligible',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = [
						'personalInfo',
						'transferFlow',
						'sourceAccountType',
						'simpleSourceAccountType',
						'simpleIraFirstContributionDate',
						'simpleIraFirstContributionDateValidFalse'
					];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-account-not-eligible.vue')
			},
			{
				path: `target`,
				name: 'ira-target-account-type',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];

					if (ira.fundingType === 'ROLLOVER') {
						requiredVals = ['personalInfo', 'rolloverFlow', 'rolloverType'];
					} else if (ira.fundingType === 'TRANSFER') {
						requiredVals = ['personalInfo', 'transferFlow', 'sourceAccountType'];
					} else {
						requiredVals = ['rolloverOrTransferFlow'];
					}

					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-target-account-type.vue')
			},
			{
				path: `previous-account`,
				name: 'ira-transfer-account-info',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = [
						'personalInfo',
						'transferFlow',
						'sourceAccountType',
						'accountType'
					];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-transfer-account-info.vue')
			},
			{
				path: `transfer-amount`,
				name: 'ira-transfer-amount',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];

					if (ira.fundingType === 'ROLLOVER') {
						requiredVals = [
							'personalInfo',
							'rolloverFlow',
							'sourceAccountType',
							'accountType',
							'rolloverMethod'
						];
					} else if (ira.fundingType === 'TRANSFER') {
						requiredVals = [
							'personalInfo',
							'transferFlow',
							'sourceAccountType',
							'accountType',
							'sourceCustodianDtcInfo',
							'sourceAccountNumber'
						];
					}
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-transfer-amount.vue')
			},
			{
				path: `bank`,
				name: 'ira-transfer-fees',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];

					if (ira.fundingType === 'ROLLOVER') {
						requiredVals = [
							'personalInfo',
							'rolloverFlow',
							'sourceAccountType',
							'accountType',
							'rolloverMethod',
							'selectedAmount'
						];
					} else if (ira.fundingType === 'TRANSFER') {
						requiredVals = [
							'personalInfo',
							'transferFlow',
							'sourceAccountType',
							'accountType',
							'sourceCustodianDtcInfo',
							'sourceAccountNumber',
							'selectedAmount',
							'plan'
						];
					}

					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-transfer-fees.vue')
			},
			{
				path: `mobile`,
				name: 'ira-mobile',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = ['personalInfo', 'editingAllowed'];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-mobile.vue')
			},
			{
				path: `delivery-method`,
				name: 'ira-otp-type',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];

					if (ira.fundingType === 'ROLLOVER') {
						requiredVals = ['contactSet', 'verificationNotStarted', 'rolloverAgreementsSubmitted'];
					} else {
						requiredVals = [
							'reitInvestmentOrderGroupId',
							'orderGroupAcknowledged',
							'verificationNotStarted'
						];
					}
					await handleRequiredValues(next, requiredVals, 'ira-otp-type');
				},
				component: () => import('@views/ira/signup/ira-otp-type.vue')
			},
			{
				path: `verification-error`,
				name: 'ira-verification-error',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];

					if (ira.fundingType === 'ROLLOVER') {
						requiredVals = ['contactSet', 'rolloverAgreementsSubmitted'];
					} else {
						requiredVals = ['reitInvestmentOrderGroupId', 'orderGroupAcknowledged'];
					}
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-verification-error.vue')
			},
			{
				path: `mobile-verify`,
				name: 'ira-mobile-verify',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];

					if (ira.fundingType === 'ROLLOVER') {
						requiredVals = [
							'contactSet',
							'userOtpSelected',
							'phoneVerificationflow',
							'rolloverAgreementsSubmitted'
						];
					} else {
						requiredVals = [
							'reitInvestmentOrderGroupId',
							'orderGroupAcknowledged',
							'userOtpSelected',
							'phoneVerificationflow'
						];
					}
					await handleRequiredValues(next, requiredVals, 'ira-mobile-verify');
				},
				component: () => import('@views/ira/signup/ira-mobile-verify.vue')
			},
			{
				path: `documents`,
				name: 'ira-documents',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];

					if (ira.fundingType === 'ROLLOVER') {
						requiredVals = [
							'contactSet',
							'userOtpSelected',
							'documentVerificationFlow',
							'rolloverAgreementsSubmitted'
						];
					} else {
						requiredVals = [
							'reitInvestmentOrderGroupId',
							'orderGroupAcknowledged',
							'userOtpSelected',
							'documentVerificationFlow'
						];
					}
					await handleRequiredValues(next, requiredVals, 'ira-documents');
				},
				component: () => import('@views/ira/signup/ira-documents.vue')
			},
			{
				path: `funding`,
				name: 'ira-funding',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = ['personalInfo'];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-funding.vue')
			},
			{
				path: `type`,
				name: 'ira-account-type',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = ['personalInfo', 'contributionFlow'];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-account-type.vue')
			},
			{
				path: `amount`,
				name: 'ira-amount',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					const requiredVals: Array<IraRequiredValue> = ['personalInfo', 'contributionFlow', 'accountType'];
					await ira.getIraFeesAndContributionLimits();
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-amount.vue')
			},
			{
				path: `max-contribution`,
				name: 'ira-max-contribution',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					if (from && (from.name === 'ira-amount' || from.name === 'ira-transfer-amount')) {
						next();
					} else {
						const amountRoute = getAmountRoute();
						next({ name: amountRoute });
					}
				},
				component: () => import('@views/ira/signup/ira-max-contribution.vue')
			},
			{
				path: `plan`,
				name: 'ira-plan',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];

					if (ira.fundingType === 'CONTRIBUTION') {
						requiredVals = ['personalInfo', 'accountType', 'selectedAmount', 'selectedTaxYear'];
					} else if (ira.fundingType === 'TRANSFER') {
						requiredVals = [
							'personalInfo',
							'sourceAccountType',
							'accountType',
							'sourceCustodianDtcInfo',
							'sourceAccountNumber',
							'selectedAmount'
						];
					}

					if (ira.fundingType === 'ROLLOVER') {
						next({ name: 'ira-transfer-fees' });
					} else {
						await handleRequiredValues(next, requiredVals);
					}
				},
				component: () => import('@views/ira/signup/ira-plan.vue')
			},
			{
				path: `funding-source`,
				name: 'ira-funding-source',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = [
						'personalInfo',
						'contributionFlow',
						'accountType',
						'selectedAmount',
						'selectedTaxYear',
						'plan'
					];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-funding-source.vue')
			},
			{
				path: `bank-account-new`,
				name: 'ira-bank-account-new',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					if (from && (from.name === 'ira-funding-source' || from.name === 'ira-transfer-fees')) {
						next();
					} else {
						const paymentMethodRoute = getPaymentMethodRoute();
						next({ name: paymentMethodRoute });
					}
				},
				component: () => import('@views/ira/signup/ira-bank-account-new.vue')
			},
			{
				path: `fee-schedule`,
				name: 'ira-fee-schedule',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredVals: Array<IraRequiredValue> = [
						'personalInfo',
						'contributionFlow',
						'accountType',
						'selectedAmount',
						'selectedTaxYear',
						'plan',
						'selectedPaymentMethod'
					];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-fee-schedule.vue')
			},
			{
				path: `drip`,
				name: 'ira-drip',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];
					if (ira.fundingType === 'CONTRIBUTION') {
						requiredVals = [
							'personalInfo',
							'accountType',
							'selectedAmount',
							'selectedTaxYear',
							'plan',
							'selectedPaymentMethod',
							'acknowledgedFees'
						];
					}
					if (ira.fundingType === 'TRANSFER') {
						requiredVals = [
							'personalInfo',
							'sourceAccountType',
							'accountType',
							'sourceCustodianDtcInfo',
							'sourceAccountNumber',
							'selectedAmount',
							'plan',
							'selectedPaymentMethod',
							'acknowledgedFees'
						];
					}

					if (ira.fundingType === 'ROLLOVER') {
						next({ name: 'ira-confirm' });
					} else {
						await handleRequiredValues(next, requiredVals);
					}
				},
				component: () => import('@views/ira/signup/ira-drip.vue')
			},
			{
				path: `confirm`,
				name: 'ira-confirm',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];
					if (ira.fundingType === 'CONTRIBUTION') {
						requiredVals = [
							'personalInfo',
							'accountType',
							'selectedAmount',
							'selectedTaxYear',
							'plan',
							'selectedPaymentMethod',
							'acknowledgedFees',
							'dripPreference',
							'needsConfirmation'
						];
					}
					if (ira.fundingType === 'TRANSFER') {
						requiredVals = [
							'personalInfo',
							'sourceAccountType',
							'accountType',
							'sourceCustodianDtcInfo',
							'sourceAccountNumber',
							'selectedAmount',
							'plan',
							'selectedPaymentMethod',
							'acknowledgedFees',
							'dripPreference',
							'needsConfirmation'
						];
					}
					if (ira.fundingType === 'ROLLOVER') {
						requiredVals = [
							'personalInfo',
							'rolloverFlow',
							'rolloverType',
							'accountType',
							'rolloverMethod',
							'selectedAmount',
							'selectedPaymentMethod',
							'acknowledgedFees',
							'needsConfirmation'
						];
					}
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-confirm.vue')
			},
			{
				path: `agreements`,
				name: 'ira-agreements',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					const requiredVals: Array<IraRequiredValue> =
						ira.fundingType === 'ROLLOVER' ? ['contactSet'] : ['reitInvestmentOrderGroupId'];
					await handleRequiredValues(next, requiredVals);
				},
				component: () => import('@views/ira/signup/ira-agreements.vue')
			},
			{
				path: `success`,
				name: 'ira-success',
				meta: { step: stepConstructor, minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const ira = useIraStore();
					let requiredVals: Array<IraRequiredValue> = [];

					if (ira.fundingType === 'ROLLOVER') {
						requiredVals = ['userVerificationCompleted', 'rolloverAgreementsSubmitted'];
					} else {
						requiredVals = [
							'reitInvestmentOrderGroupId',
							'orderGroupAcknowledged',
							'userVerificationCompleted'
						];
					}
					await handleRequiredValues(next, requiredVals, 'ira-success');
				},
				component: () => import('@views/ira/signup/ira-success.vue')
			}
		]
	},
	{
		path: `/ira/checkout`,
		name: 'ira-signup-start',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			const appStore = useAppStore();
			const signupStore = useSignupStore();
			const ira = useIraStore();

			if (appStore.isAuthenticated) {
				await signupStore.getUserCheckoutDetailsData();
			}

			if (signupStore.isIraCheckout) {
				ira.flow = 'SIGN-UP';
				next();
			} else {
				next({ name: 'checkout-resume' });
			}
		},
		redirect: { name: 'ira-account-creation' },
		component: () => import('@views/checkout/checkout-view.vue'),
		children: [
			{
				path: 'account-creation',
				name: 'ira-account-creation',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				beforeEnter: async (to, from, next) => {
					const requiredStates: Array<IraSignupRequiredState> = ['userNotCreated'];

					await validateIraSignupStep(next, requiredStates);
				},
				component: () => import('@views/ira/signup/ira-account-creation.vue')
			},
			{
				path: 'select-country',
				name: 'ira-select-country',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					const requiredStates: Array<IraSignupRequiredState> = [
						'userCreated',
						'residenceInvalidOrNotSubmitted'
					];

					await validateIraSignupStep(next, requiredStates);
				},
				component: () => import('@views/ira/signup/ira-select-country.vue')
			},
			{
				path: 'country-not-supported',
				name: 'ira-country-not-supported',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				component: () => import('@views/ira/signup/ira-country-not-supported.vue')
			}
		]
	}
];

export default iraSignupRoutes;
