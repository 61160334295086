import { FundCategory, PaginatedFunds } from 'types/funds';
import {
	RedeemableFundsByCategory,
	RedeemableReitItem,
	RedemptionRequestAcknowledgements,
	RedemptionRequestMetadata,
	RedemptionRequestPreviewData,
	RedemptionSubmission,
	ReitRedemptionRequest
} from 'types/redemption-request';
import { getFunds } from '@sanity/proxy/funds';
import { investmentEntityRequest } from '@utils/request';
import { ytdReturnsByReitId2024 } from '@constants/ytd-returns-by-fund-2024';

export const getReitRedemptionRequestMetadata = async (): Promise<RedemptionRequestMetadata> => {
	const response = await investmentEntityRequest.get(`/redemption-request/metadata`);
	return response.data;
};

export const getIpoRedemptionRequestMetadata = async (): Promise<RedemptionRequestMetadata> => {
	const response = await investmentEntityRequest.get(`/redemption-request/ipo/metadata`);
	return response.data;
};

export const getRedemptionPreview = async (
	requests: Array<ReitRedemptionRequest>
): Promise<RedemptionRequestPreviewData> => {
	const response = await investmentEntityRequest.post(`/redemption-request/redemption-request-preview`, { requests });
	return response.data;
};

export const getRedemptionRequestAcknowledgements = async (
	requests: Array<ReitRedemptionRequest>
): Promise<RedemptionRequestAcknowledgements> => {
	const response = await investmentEntityRequest.post(`/redemption-request/acknowledgements`, { requests });
	return response.data;
};

export const submitRequest = async (redemptionSubmission: RedemptionSubmission): Promise<string> => {
	const response = await investmentEntityRequest.post(`/redemption-request/submit-request`, {
		...redemptionSubmission
	});
	return response.data;
};

export const getRedeemableFundsByCategory = async (
	redemptionRequests: ReitRedemptionRequest[],
	redeemableReits: RedeemableReitItem[]
): Promise<RedeemableFundsByCategory> => {
	const fundsByCategory: RedeemableFundsByCategory = new Map();

	const allFundsInfo: PaginatedFunds = await getFunds();

	const existingRequestsMap = new Map(redemptionRequests.map((request) => [request.reitId, request.sharesRequested]));

	const reitsMap = new Map(
		redeemableReits.map((reit) => [
			reit.reitId,
			{
				amountSelected: existingRequestsMap.get(reit.reitId) ?? '0',
				validity: true,
				...reit
			}
		])
	);

	allFundsInfo.results
		.filter((fund) => fund.category?.name && reitsMap.has(fund.fundriseId))
		.sort((a, b) => {
			const categoryA = a.category?.priority;
			const categoryB = b.category?.priority;
			return categoryA && categoryB ? parseInt(categoryA) - parseInt(categoryB) : 0;
		})
		.forEach((fund) => {
			const categoryName = fund.category!.name;
			const reitDetails = reitsMap.get(fund.fundriseId)!;
			const lastYearReturn = ytdReturnsByReitId2024[fund.fundriseId] ?? '-';

			const relevantFundsDetails = {
				lastYearReturn,
				...reitDetails
			};

			const categoryFunds = fundsByCategory.get(categoryName) ?? {
				categoryDetails: fund.category as FundCategory,
				funds: []
			};

			categoryFunds.funds.push(relevantFundsDetails);
			fundsByCategory.set(categoryName, categoryFunds);
		});

	return fundsByCategory;
};
