<template>
	<div class="tooltip" :style="{ ...visibilityStyle }">
		<span ref="triggerRef" :class="['popover__trigger', { 'dashed-underline': hasTrigger }]" v-on="triggers">
			<slot name="trigger"
				><span
					class="popover__trigger-icon"
					:class="{ 'popover__trigger-icon--reversed': props.darkMode }"
				></span
			></slot>
		</span>
		<span
			ref="tooltipRef"
			:class="['tooltip-text', { 'body-text-color': props.darkMode }, displayedPosition]"
			:style="{ ...visibilityStyle }"
			@click.stop
			@mouseenter.stop
			@mouseleave.stop
		>
			<slot>OVERRIDE Tooltip Text</slot>
			<span id="popover-arrow" class="tooltip-arrow"></span>
		</span>
	</div>
</template>

<script lang="ts">
export default {
	name: 'BaseTooltip'
};
</script>

<script setup lang="ts">
import { computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, useSlots, watch } from 'vue';
import { trackMixpanelClick, trackMixpanelView } from '@utils/analytics';
import { MixpanelContext } from 'types/analytics';
import { useAnalyticsProperties } from '@utils/composables/use-analytics-properties';
import { useEventBus } from '@vueuse/core';

interface Props {
	darkMode?: boolean;
	position?: 'above' | 'below';
	trigger?: 'click' | 'hover';
	mixpanelClickCustomProperties?: MixpanelContext;
	mixpanelViewCustomProperties?: MixpanelContext;
	mixpanelTarget?: string;
}

const props = withDefaults(defineProps<Props>(), {
	darkMode: false,
	position: 'above',
	trigger: 'click',
	mixpanelClickCustomProperties: undefined,
	mixpanelViewCustomProperties: undefined,
	mixpanelTarget: undefined
});

const slots = useSlots();
const currentInstance = getCurrentInstance();
const { getActionProperties, getViewProperties } = useAnalyticsProperties();

const eventBus = useEventBus('tooltip-toggled');
const toggleListener = eventBus.on((tooltipId) => {
	if (tooltipId !== currentInstance?.uid) {
		hideTooltip();
	}
});

const tooltipRef = ref<HTMLElement | null>(null);
const triggerRef = ref<HTMLElement | null>(null);

const visible = ref(false);

const displayedPosition = ref(props.position);

const hasTrigger = computed((): boolean => !!slots.trigger);

const triggers = computed((): object => {
	if (props.trigger === 'hover') {
		return { mouseenter: toggleTooltip, mouseleave: toggleTooltip };
	} else {
		return { click: toggleTooltip };
	}
});

const visibilityStyle = computed((): object => {
	const styles: Record<string, string | number> = { visibility: 'visible', opacity: 1 };
	if (props.trigger === 'hover') {
		styles['user-select'] = 'none';
	}
	return visible.value ? styles : {};
});

watch(
	() => visible.value,
	(visibility: boolean) => {
		if (props.trigger === 'click' && triggerRef.value) {
			trackMixpanelClick(
				getActionProperties({
					'Action Target': props.mixpanelTarget,
					element: triggerRef.value,
					customProperties: props.mixpanelClickCustomProperties
				})
			);
			if (visibility) {
				document.addEventListener('click', clickOutsideHandler);
				if (props.mixpanelViewCustomProperties) {
					trackMixpanelView('Element', { ...getViewProperties(), ...props.mixpanelViewCustomProperties });
				}
			} else {
				document.removeEventListener('click', clickOutsideHandler);
			}
		}
	}
);

onMounted(() => {
	// move el
	const body = document.body;
	body.append(tooltipRef.value as HTMLElement);
});

onBeforeUnmount(() => {
	document.removeEventListener('click', clickOutsideHandler);
	toggleListener();
	tooltipRef.value?.remove(); // remove from body
});

function toggleTooltip(event?: PointerEvent): void {
	event?.stopPropagation();

	visible.value = !visible.value;

	if (visible.value) {
		const trigger = (triggerRef.value as HTMLElement).getBoundingClientRect();
		const popover = (tooltipRef.value as HTMLElement).getBoundingClientRect();

		const scroll = document.querySelector('html')?.scrollTop ?? 0;
		const top = trigger.top - popover.height + scroll;
		const bottom = trigger.bottom + scroll;

		// vertical positioning
		if (props.position === 'above') {
			if (trigger.top - popover.height >= 0) {
				displayedPosition.value = 'above';
				(tooltipRef.value as HTMLElement).style.top = `${top}px`;
			} else {
				displayedPosition.value = 'below';
				(tooltipRef.value as HTMLElement).style.top = `${bottom}px`;
			}
		} else if (trigger.bottom + popover.height <= window.innerHeight) {
			displayedPosition.value = 'below';
			(tooltipRef.value as HTMLElement).style.top = `${bottom}px`;
		} else {
			displayedPosition.value = 'above';
			(tooltipRef.value as HTMLElement).style.top = `${top}px`;
		}

		const left = trigger.left + trigger.width / 2 - popover.width / 2;
		(tooltipRef.value as HTMLElement).style.left = `${left}px`;

		// prevent overflow left
		if (left < 16) {
			(tooltipRef.value as HTMLElement).style.left = `16px`;
			(tooltipRef.value as HTMLElement).style.right = 'unset';
		}

		// prevent overflow right
		if (left + popover.width > window.innerWidth) {
			(tooltipRef.value as HTMLElement).style.right = `16px`;
			(tooltipRef.value as HTMLElement).style.left = 'unset';
		}

		eventBus.emit(currentInstance?.uid);
	}
}

function clickOutsideHandler(event: MouseEvent): void {
	if (!currentInstance?.proxy?.$el.contains(event.target as Node)) {
		hideTooltip();
	}
}

function hideTooltip(event?: MouseEvent): void {
	event?.stopPropagation();

	visible.value = false;
}

defineExpose({ hideTooltip });
</script>

<style lang="scss" scoped>
@use '../styles/utilities/respond-to.scss' as *;
$width: 288px;
$arrow-width: 7px;

.tooltip {
	position: relative;
	display: inline-block;

	&-text {
		position: absolute;
		visibility: hidden;
		top: 0;
		width: $width;
		background-color: #ffffff;
		border-radius: 3px;
		box-shadow:
			0 0 0 1px rgba(0, 0, 0, 0.02),
			0 2px 24px 0 rgba(0, 0, 0, 0.16);
		font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
		text-align: center;
		font-size: 14px;
		line-height: 1.5;
		padding: 0.75rem 1rem;
		z-index: 10000;
		opacity: 0;
		transition:
			opacity 0.3s,
			visibility 0.3s;

		&.above {
			transform: translateY(calc(-1 * #{$arrow-width}));
			.tooltip-arrow {
				top: 100%;
				border-color: #ffffff transparent transparent transparent;
			}
		}
		&.below {
			transform: translateY(calc(1 * #{$arrow-width}));
			.tooltip-arrow {
				bottom: 100%;
				border-color: transparent transparent #ffffff transparent;
			}
		}
	}

	&-arrow {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		margin-left: -$arrow-width;
		border-width: $arrow-width;
		border-style: solid;
	}
}
</style>
