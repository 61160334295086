import {
	AdvisorClientDetail,
	AdvisorInvestorRelationshipRow,
	AdvisorInviteRelationshipRequest,
	ClientAllocationsChartData,
	ClientAllocationsChartDrilldownData,
	ClientAllocationsTableData,
	InvestmentAdvisorDetails
} from 'types/advisor';
import { computed, ref } from 'vue';
import { createAdvisorRelationshipRequest, getAllocationOverview, getInvestmentAdvisorDetails } from '@api/advisor';
import { currency, percentage } from '@filters/shared-filters';
import {
	formattedPerformanceCurrencyValue,
	formattedPerformancePercentageValue,
	getReturnsColor
} from '@utils/performance-portfolio';
import big from 'big.js';
import { defineStore } from 'pinia';
import { ReturnsByAssetClass } from 'types/performance-portfolio';

export const useAdvisorStore = defineStore('advisor', () => {
	const investmentAdvisorDetails = ref<InvestmentAdvisorDetails | null>(null);
	const selectedInvestmentEntityId = ref('');
	const clientAllocations = ref<ReturnsByAssetClass | null>(null);

	const clientAccountsCount = computed(
		(): string | null => investmentAdvisorDetails.value?.managedEntityCount ?? null
	);

	const totalAum = computed((): string | null =>
		investmentAdvisorDetails.value?.totalManagedAum
			? formattedPerformanceCurrencyValue(investmentAdvisorDetails.value.totalManagedAum)
			: null
	);

	const hasRelationships = computed((): boolean => !!investmentAdvisorDetails.value?.relationships.length);

	const hasRelationshipRequests = computed(
		(): boolean => !!investmentAdvisorDetails.value?.relationshipRequests.length
	);

	const clientEntities = computed((): Array<AdvisorClientDetail> => {
		const userEntityList = investmentAdvisorDetails.value?.relationships.flatMap((relationship) => {
			const managedEntities = relationship.managedUser.managedEntities ?? [];
			return managedEntities.map((entity) => {
				return {
					firstName: relationship.managedUser.firstName,
					lastName: relationship.managedUser.lastName,
					investmentEntityId: entity.investmentEntityId,
					entity
				};
			});
		});

		return userEntityList ?? [];
	});

	const formattedRelationships = computed((): Array<AdvisorInvestorRelationshipRow> => {
		return (
			clientEntities.value.map((client) => {
				const balance = client.entity.totalAccountValue
					? formattedPerformanceCurrencyValue(client.entity.totalAccountValue)
					: '-';
				const ytdPerformance = client.entity.yearToDateReturns
					? formattedPerformancePercentageValue(client.entity.yearToDateReturns, true, true)
					: '-';
				const ytdPerformanceClass = client.entity.yearToDateReturns
					? getReturnsColor(client.entity.yearToDateReturns)
					: '';
				const allTimePerformance = client.entity.allTimeReturns
					? formattedPerformancePercentageValue(client.entity.allTimeReturns, true, true)
					: '-';
				const allTimePerformanceClass = client.entity.allTimeReturns
					? getReturnsColor(client.entity.allTimeReturns)
					: '';

				return {
					firstName: client.firstName,
					lastName: client.lastName,
					investmentEntityId: client.investmentEntityId,
					accountType: client.entity.entityTypeLabel ?? '-',
					balance,
					ytdPerformance,
					ytdPerformanceClass,
					allTimePerformance,
					allTimePerformanceClass
				};
			}) ?? []
		);
	});

	const allocationBreakdown = computed((): Array<ClientAllocationsChartData> => {
		if (!clientAllocations.value?.assetClasses.length) {
			return [];
		}

		return clientAllocations.value.assetClasses
			.filter((assetClass) => big(assetClass.overview.allocation.allocationPercentage).gt(0))
			.map((assetClass): ClientAllocationsChartData => {
				const weight = percentage(assetClass.overview.allocation.allocationPercentage, false, 2, '0', true);
				return {
					name: assetClass.overview.assetClassLabel,
					custom: {
						amount: currency(assetClass.overview.allocation.allocationAmount)
					},
					y: Number(weight),
					drilldown: assetClass.overview.assetClass
				};
			});
	});

	const assetClassBreakdown = computed((): Array<ClientAllocationsChartDrilldownData> => {
		if (!clientAllocations.value?.assetClasses.length) {
			return [];
		}

		return clientAllocations.value.assetClasses.map((assetClass) => {
			return {
				id: assetClass.overview.assetClass,
				name: assetClass.overview.assetClassLabel,
				data: assetClass.offerings
					.filter((offering) => big(offering.overview.allocation.allocationPercentage).gt(0))
					.map((offering) => {
						const offeringWeight = percentage(
							offering.overview.allocation.allocationPercentage,
							false,
							2,
							'0',
							true
						);
						return {
							name: offering.overview.name,
							custom: { amount: currency(offering.overview.allocation.allocationAmount) },
							y: Number(offeringWeight)
						};
					})
			};
		});
	});

	const allocationBreakdownTableData = computed((): Array<ClientAllocationsTableData> => {
		if (!clientAllocations.value?.assetClasses.length) {
			return [];
		}

		return clientAllocations.value.assetClasses
			.filter((assetClass) => big(assetClass.overview.allocation.allocationPercentage).gt(0))
			.map((assetClass) => {
				return {
					name: assetClass.overview.assetClassLabel,
					amount: currency(assetClass.overview.allocation.allocationAmount),
					percentage: percentage(assetClass.overview.allocation.allocationPercentage, true),
					funds: assetClass.offerings
						.filter((offering) => big(offering.overview.allocation.allocationPercentage).gt(0))
						.map((offering) => {
							return {
								name: offering.overview.name,
								amount: currency(offering.overview.allocation.allocationAmount),
								percentage: percentage(offering.overview.allocation.allocationPercentage, true)
							};
						})
				};
			});
	});

	async function getAdvisorData(forceRefresh = false): Promise<void> {
		if (!investmentAdvisorDetails.value || forceRefresh) {
			investmentAdvisorDetails.value = await getInvestmentAdvisorDetails();
		}
	}

	async function inviteClient(advisorInviteClientForm: AdvisorInviteRelationshipRequest): Promise<void> {
		await createAdvisorRelationshipRequest(advisorInviteClientForm);
		await getAdvisorData(true);
	}

	async function getClientAllocations(): Promise<void> {
		clientAllocations.value = await getAllocationOverview();
	}

	function $reset(): void {
		selectedInvestmentEntityId.value = '';
		investmentAdvisorDetails.value = null;
		clientAllocations.value = null;
	}

	return {
		investmentAdvisorDetails,
		selectedInvestmentEntityId,
		clientAllocations,
		clientAccountsCount,
		totalAum,
		hasRelationships,
		hasRelationshipRequests,
		clientEntities,
		formattedRelationships,
		allocationBreakdown,
		assetClassBreakdown,
		allocationBreakdownTableData,
		getAdvisorData,
		inviteClient,
		getClientAllocations,
		$reset
	};
});
