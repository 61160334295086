import { addFundsEntryRedirectHandling, addFundsOfferingEntryRedirectHandling } from '@router/utils/invest';
import { FundriseRouteConfig, NavigationGuard, RouteParamValue } from 'vue-router';
import { AccountInvestFlowType } from 'types/account/invest';
import { flattenQueryParam } from '@utils/request';
import { redirect } from '@utils/client';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useInvestStore } from '@stores/invest';
import { windowReservationCapacityFull } from '@constants/error-codes';

const continueToNextRoute: NavigationGuard = (to, from, next) => {
	if (to.name === 'account-invest-flow-amount' && !to.query.backRoute && !!from.name && !from.params) {
		const offeringId = to.query.offeringId as RouteParamValue;
		next({
			...to,
			query: { backRoute: from.name as RouteParamValue, offeringId }
		});
	} else {
		next();
	}
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const investRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/account/invest/:flowType`,
		name: 'account-invest-flow',
		meta: { minTokenScopeRequired: 'FULL_ACCESS', suppressDefaultMxpViewEvent: true },
		beforeEnter: async (to, from, next) => {
			const investStore = useInvestStore();

			await investStore.storeIsInTestGroup();

			const investmentEntityStore = useInvestmentEntityStore();
			const offeringId = to.query.offeringId as RouteParamValue;
			const flowType = to.params.flowType as AccountInvestFlowType;

			investStore.accountInvestFlowType = flowType;

			switch (flowType) {
				case 'plan':
					investStore.resetReservationWindowData();

					if (investmentEntityStore.isRedeemedNoDirectInvestNoPlanAccount) {
						next({ name: 'ria-upgrade', replace: true });
					} else if (
						investmentEntityStore.additionalInvestmentEligibility !== 'VALID' ||
						investmentEntityStore.entityType === 'IRA'
					) {
						const addFundsSpecialRedirect = addFundsEntryRedirectHandling();
						if (addFundsSpecialRedirect) {
							redirect(addFundsSpecialRedirect);
						} else {
							const appStore = useAppStore();
							next({ name: 'account-overview', replace: true });
							appStore.addToast({
								type: 'error',
								message:
									'The current account is not eligible for an additional investment at this time.'
							});
						}
					} else {
						if (investStore.orderComplete) {
							investStore.$reset();
						}

						try {
							await investStore.getInvestOrderMetadata();
						} catch {
							next({ name: 'account-overview', replace: true });
						}

						continueToNextRoute(to, from, next);
					}
					break;
				case 'ipo':
				case 'fund':
				case 'window-reservation':
					if (offeringId) {
						if (investStore.orderComplete) {
							investStore.$reset();
							investStore.resetReservationWindowData();
						}

						try {
							await investStore.getInvestOrderMetadata(offeringId);
						} catch {
							next({ name: 'account-overview', replace: true });
						}

						if (
							(investStore.investMetaData?.investmentEligibility !== 'ELIGIBLE' &&
								investStore.investMetaData?.investmentEligibility !== 'RESERVATION_ELIGIBLE') ||
							investmentEntityStore.isIraEntity
						) {
							const addFundsSpecialRedirect = addFundsOfferingEntryRedirectHandling();
							if (addFundsSpecialRedirect) {
								next(addFundsSpecialRedirect);
							} else if (flowType === 'ipo') {
								const appStore = useAppStore();
								next({ name: 'account-overview', replace: true });
								appStore.addToast({
									type: 'error',
									message: `Thank you for your investment in the Fundrise iPO. You've already invested the maximum amount available to you.`
								});
							} else {
								const appStore = useAppStore();
								next({ name: 'account-overview', replace: true });
								appStore.addToast({
									type: 'error',
									message:
										'You are not eligible for direct investment in the selected offering at this time.'
								});
							}
						} else {
							if (flowType === 'window-reservation' && !investStore.selectedWindow) {
								next({ name: 'account-invest-window-reservation-start', query: { offeringId } });
							} else {
								continueToNextRoute(to, from, next);
							}
						}
					} else {
						next({
							name: 'account-invest-flow',
							params: { flowType: 'plan' },
							replace: true
						});
					}
					break;
				case 'new-investor-offering':
					await investStore.checkCrossSellEligibility();
					if (investStore.crossSellResponse?.eligible) {
						continueToNextRoute(to, from, next);
					} else {
						next({
							name: 'account-invest-flow',
							params: { flowType: 'plan' },
							replace: true
						});
					}
					break;
				default:
					next({ name: 'account-overview' });
					break;
			}
		},
		component: () =>
			import(/* webpackChunkName: "account-invest-flow" */ '@views/account/invest/account-invest-flow.vue'),
		redirect: { name: 'account-invest-flow-amount' },
		children: [
			{
				path: 'amount',
				name: 'account-invest-flow-amount',
				meta: {
					minTokenScopeRequired: 'FULL_ACCESS',
					suppressDefaultMxpViewEvent: true,
					customAnalyticsViewName: 'Investment Amount'
				},
				beforeEnter: async (to, from, next) => {
					const investStore = useInvestStore();

					const flowType = to.params.flowType as AccountInvestFlowType;
					if (flowType === 'ipo' && !investStore.amount) {
						next({ name: 'offerings-ipo-landing' });
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "account-invest-flow-amount" */ '@views/account/invest/account-invest-flow-amount.vue'
					)
			},
			{
				path: 'allocation',
				name: 'account-invest-flow-allocation',
				meta: {
					minTokenScopeRequired: 'FULL_ACCESS',
					suppressDefaultMxpViewEvent: true,
					customAnalyticsViewName: 'Investment Allocation'
				},
				beforeEnter: async (to, from, next) => {
					const investStore = useInvestStore();

					const flowType = to.params.flowType as AccountInvestFlowType;
					if (flowType === 'plan') {
						if (investStore.amount) {
							next();
						} else {
							next({ name: 'account-invest', replace: true });
						}
					} else {
						next({ name: 'account-invest-flow-review', replace: true });
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "account-invest-flow-allocation" */ '@views/account/invest/account-invest-flow-allocation.vue'
					)
			},
			{
				path: 'review',
				name: 'account-invest-flow-review',
				meta: {
					minTokenScopeRequired: 'FULL_ACCESS',
					suppressDefaultMxpViewEvent: true,
					customAnalyticsViewName: 'Investment Review Order'
				},
				beforeEnter: async (to, from, next) => {
					const investStore = useInvestStore();

					if (investStore.orderComplete) {
						investStore.$reset();
					}

					const flowType = to.params.flowType as AccountInvestFlowType;
					const offeringId = to.query.offeringId as RouteParamValue;
					if (['fund', 'ipo', 'window-reservation', 'plan'].includes(flowType)) {
						const isOfferingAmountStepIncomplete = !investStore.amount || !investStore.orderGroupId;
						if (isOfferingAmountStepIncomplete) {
							next({
								name: 'account-invest-flow-amount',
								params: { flowType: flowType },
								query: { offeringId: offeringId },
								replace: true
							});
						} else {
							next();
						}
					} else if (flowType === 'new-investor-offering') {
						if (from.name === 'account-invest-flow-success') {
							next({ name: 'account-overview', replace: true });
						} else if (!investStore.amount || !investStore.orderGroupId) {
							next({
								name: 'account-invest-new-investor-offering',
								replace: true
							});
						} else {
							next();
						}
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "account-invest-flow-review" */ '@views/account/invest/account-invest-flow-review.vue'
					)
			},
			{
				path: 'success',
				name: 'account-invest-flow-success',
				meta: {
					minTokenScopeRequired: 'FULL_ACCESS',
					suppressDefaultMxpViewEvent: true,
					customAnalyticsViewName: 'Investment Order Success'
				},
				beforeEnter: async (to, from, next) => {
					const investStore = useInvestStore();

					const flowType = to.params.flowType as AccountInvestFlowType;
					const offeringId = to.query.offeringId as RouteParamValue;

					if (investStore.orderGroupId) {
						next();
					} else {
						if (offeringId) {
							next({
								name: 'account-invest-flow-amount',
								params: { flowType },
								query: { offeringId },
								replace: true
							});
						} else {
							next({
								name: 'account-invest-flow-amount',
								params: { flowType },
								replace: true
							});
						}
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "account-invest-flow-success" */ '@views/account/invest/account-invest-flow-success.vue'
					)
			},
			{
				path: 'error',
				name: 'account-invest-flow-error',
				meta: {
					minTokenScopeRequired: 'FULL_ACCESS',
					suppressDefaultMxpViewEvent: true,
					customAnalyticsViewName: 'Investment Order Error'
				},
				beforeEnter: async (to, from, next) => {
					const investStore = useInvestStore();

					const flowType = to.params.flowType as AccountInvestFlowType;
					const offeringId = to.query.offeringId as RouteParamValue;
					if (
						from.name !== 'account-invest-flow-review' ||
						investStore.offeringSubmissionErrorCode !== windowReservationCapacityFull
					) {
						next({
							name: 'account-invest-flow-review',
							params: { flowType },
							query: { offeringId },
							replace: true
						});
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "account-invest-flow-error" */ '@views/account/invest/account-invest-flow-error.vue'
					)
			}
		]
	},
	{
		path: `/account/invest/new-investor-offering`,
		name: 'account-invest-new-investor-offering',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			suppressDefaultMxpViewEvent: true,
			customAnalyticsViewName: 'Investment Post Signup X-sell Prompt'
		},
		beforeEnter: async (to, from, next) => {
			const investStore = useInvestStore();

			await investStore.checkCrossSellEligibility();

			if (investStore.crossSellResponse?.eligible) {
				next();
			} else {
				next({ name: 'account-invest-flow', params: { flowType: 'plan' } });
			}
		},
		component: () =>
			import(
				/* webpackChunkName: "account-invest-new-investor-offering" */ '@views/account/invest/account-invest-new-investor-offering.vue'
			)
	},
	{
		path: '/account/invest/window-reservation/start',
		name: 'account-invest-window-reservation-start',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			suppressDefaultMxpViewEvent: true,
			customAnalyticsViewName: 'Investment Window Reservation Start'
		},
		beforeEnter: async (to, from, next) => {
			const investStore = useInvestStore();
			const investmentEntityStore = useInvestmentEntityStore();

			await investStore.storeIsInTestGroup();

			if (investStore.orderComplete) {
				investStore.$reset();
			}
			investStore.resetReservationWindowData();

			const offeringId = to.query.offeringId as RouteParamValue;

			try {
				await investStore.getInvestOrderMetadata(offeringId);
			} catch {
				next({ name: 'account-overview', replace: true });
			}

			if (
				(investStore.investMetaData?.investmentEligibility === 'RESERVATION_ELIGIBLE' ||
					investStore.investMetaData?.investmentEligibility === 'ELIGIBLE') &&
				!investmentEntityStore.isIraEntity
			) {
				const preselectedCohortId = flattenQueryParam(to.query.cohortId);
				await investStore.fetchAndUpdateReservationWindow({ offeringId, cohortId: preselectedCohortId });
				if (investStore.reservationWindows.length > 0) {
					next();
				} else {
					next({ name: 'account-overview', replace: true });
				}
			} else {
				const addFundsSpecialRedirect = addFundsOfferingEntryRedirectHandling();
				if (addFundsSpecialRedirect) {
					next(addFundsSpecialRedirect);
				} else {
					const appStore = useAppStore();
					next({ name: 'account-overview', replace: true });
					appStore.addToast({
						type: 'error',
						message: 'You are not eligible for direct investment in the selected offering at this time.'
					});
				}
			}
		},
		component: () =>
			import(
				/* webpackChunkName: "account-invest-window-reservation-start" */ '@views/account/invest/account-invest-window-reservation-start.vue'
			)
	},
	{
		path: '/account/repeat-investment/offering/plaid-redirect',
		name: 'account-invest-plaid-redirect',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const investStore = useInvestStore();
			const offeringId = to.query.offeringId as RouteParamValue;

			try {
				await investStore.getInvestOrderMetadata(offeringId);
			} catch {
				next({ name: 'account-overview', replace: true });
			}

			next({
				path: `/account/invest/fund`,
				query: { oauth_state_id: to.query.oauth_state_id, offeringId }
			});
		},
		component: () =>
			import(/* webpackChunkName: "account-invest-flow" */ '@views/account/invest/account-invest-flow.vue')
	}
];

export default investRoutes;
