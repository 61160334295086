// API Methods - https://developers.intercom.com/installing-intercom/docs/intercom-javascript
// API Attributes & Objects - https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
import { IntercomContext } from 'types/intercom';
import { trackMixpanelEventWithBeacon } from '@utils/analytics';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { userIntercomStore } from '@stores/intercom';
import { useUserStore } from '@stores/user';

export const intercomEnabled = async (): Promise<boolean> => {
	const store = userIntercomStore();
	const appStore = useAppStore();
	const userStore = useUserStore();

	await store.storeFeatureEnabled();
	const isWebview = appStore.isMobileWebview;
	const isNonInvestor = appStore.isAuthenticated && !userStore.hasFirstInvestment;

	const enabled = !!store.enabled && !isWebview && !appStore.isAdvisor;

	if (isNonInvestor || store.context === 'SIGN_UP') {
		const isInTestGroup = await appStore.isUserInLDTestGroup({
			featureFlag: '2024-11-05-intercom-in-signup-a-b-test',
			testGroup: 'test-enabled-in-signup'
		});
		return enabled && isInTestGroup;
	} else {
		return enabled;
	}
};

export const registerIntercom = async (context: IntercomContext): Promise<void> => {
	const store = userIntercomStore();
	const appStore = useAppStore();
	const investmentEntityStore = useInvestmentEntityStore();
	const userStore = useUserStore();

	if (store.context === context) {
		return; // don't reinitialize if configuration hasn't changed
	}

	store.context = context;
	if (await intercomEnabled()) {
		window.Intercom('boot', {
			app_id: 'sj0c506e',
			hide_default_launcher: true,
			custom_launcher_selector: '#intercom-custom-launcher',
			alignment: 'right',
			vertical_padding: 64,
			horizontal_padding: 24,
			environment: import.meta.env.VITE_INTERCOM_ENV,
			isAuthenticated: appStore.isAuthenticated,
			user_id: userStore.user?.mixpanelId,
			fundrise_user_id: userStore.user?.id,
			zendeskId: userStore.user?.zendeskId,
			user_hash: userStore.user?.intercomIdentityVerification,
			// name: [user.user?.firstName, user.user?.lastName].join(' '),
			// email: user.user?.email,
			// phone: user.phoneData.currentPhoneNumber,
			context,
			investor: userStore.hasFirstInvestment,
			advisor: userStore.user?.isInvestmentAdvisor ?? false,
			accredited: investmentEntityStore.isVerifiedAccredited,
			pro: userStore.isSubscriptionActive,
			premium: userStore.isPremiumUser,
			subscriptionStatus: userStore.subscriptionStatus,
			created_at: userStore.user?.entityDetails?.firstInvestmentDate
		});

		window.Intercom('onShow', () => trackMixpanelEventWithBeacon('Intercom', { event: 'Show' }));
		window.Intercom('onHide', () => trackMixpanelEventWithBeacon('Intercom', { event: 'Hide' }));

		appStore.toggleIntercom(true);
	} else {
		await shutdownIntercom();
	}
};

export const shutdownIntercom = async (): Promise<void> => {
	const appStore = useAppStore();
	window.Intercom('shutdown');
	appStore.toggleIntercom(false);
};

export const intercomShowNewMessage = (prepopulateMessage: string): void => {
	window.Intercom('showNewMessage', prepopulateMessage);
};
