import { CheckoutAccountFormStatus, SignupStep } from 'types/checkout';
import { enumToKebab } from '@filters/shared-filters';
import { InvestmentEntityType } from 'types/investment-entity';
import { RouteLocationRaw } from 'vue-router';
import { signupStepToRouteMap } from '@constants/checkout';
import { useSignupStore } from '@stores/signup';

export async function getNextRoute(currentStep?: SignupStep): Promise<RouteLocationRaw> {
	const signupStore = useSignupStore();
	switch (currentStep?.signupStepType) {
		case 'INFO':
			if (currentStep.infoName) {
				const infoSlug = enumToKebab(currentStep.infoName);
				return { name: 'info-screen', params: { infoSlug } };
			} else {
				return { name: 'info-framework' };
			}
		case 'QUESTION':
			if (currentStep.questionName) {
				const questionId = enumToKebab(currentStep.questionName);
				return { name: 'question', params: { questionId } };
			} else {
				return { name: 'question-framework' };
			}
		case 'CUSTOM':
		default: {
			let route: RouteLocationRaw;

			if (currentStep?.signupStepName) {
				route = { name: signupStepToRouteMap[currentStep.signupStepName] };
			} else {
				route = await signupStore.goToNextStep();
			}

			return route;
		}
	}
}

export const accountDetailsRoute = async (
	entityType: InvestmentEntityType,
	userRoute: CheckoutAccountFormStatus
): Promise<RouteLocationRaw> => {
	const signupStore = useSignupStore();
	switch (entityType) {
		case 'INDIVIDUAL':
			await signupStore.getUserCheckoutDetailsData('CONTACT_INFORMATION_STEP');
			return { name: 'checkout-individual-account-details' };
		case 'JOINT_ACCOUNT':
			await signupStore.getUserCheckoutDetailsData('JOINT_INFORMATION_STEP');
			return { name: 'checkout-joint-account-details', query: { account: userRoute } };
		case 'LLC':
			await signupStore.getUserCheckoutDetailsData('ENTITY_INFORMATION_STEP');
			return {
				name: 'checkout-entity-account-details',
				query: { account: userRoute }
			};
		case 'TRUST':
			await signupStore.getUserCheckoutDetailsData('TRUST_INFORMATION_STEP');
			return { name: 'checkout-trust-account-details', query: { account: userRoute } };
		default:
			return { name: 'checkout-resume' };
	}
};
