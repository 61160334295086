import { OauthTokenResponse, TokenScope } from 'types/security';
import { InvestmentEntity } from 'types/investment-entity';
import { setCookie } from '@utils/web-storage';
import { useUserStore } from '@stores/user';

export const getUserEntityById = (investmentEntityId: string): InvestmentEntity | null => {
	const userStore = useUserStore();
	if (!userStore.user?.entityDetails) {
		return null;
	}

	const selectedInvestmentEntity = userStore.user?.entityDetails?.investmentEntities.filter(
		(investmentEntity) => investmentEntity.investmentEntityId === investmentEntityId
	)[0];
	const defaultInvestmentEntity = userStore.user.entityDetails?.investmentEntities.filter(
		(investmentEntity) =>
			investmentEntity.investmentEntityId === userStore.user?.entityDetails?.defaultInvestmentEntityId
	)[0];

	return selectedInvestmentEntity ?? defaultInvestmentEntity;
};

export const setUserLoggedInCookie = (accessToken: OauthTokenResponse): void => {
	const unAuthenticatedScopes: Array<TokenScope> = [
		'GOOGLE_AUTH_SETUP',
		'APPLE_AUTH_SETUP',
		'REQUIRES_ADDITIONAL_AUTH'
	];

	const shouldSetCookie = accessToken.scope
		.split(' ')
		.every((scope) => !unAuthenticatedScopes.includes(scope as TokenScope));

	if (shouldSetCookie) {
		setCookie('userLoggedIn', 'true', accessToken.refresh_expires_in);
	}
};
