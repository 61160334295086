import { CheckoutOrderGroupDetails, CrossSellResponse } from 'types/checkout';
import { DirectInvestmentFeatureDetails, OrderGroupResponse } from 'types/invest';
import { InvestMetadata, InvestReviewDetails } from 'types/account/invest';
import { investmentEntityRequest } from '@utils/request';
import { ReservationWindow } from 'types/reit-element';

export async function getCrossSellEligibility(): Promise<CrossSellResponse> {
	const response = await investmentEntityRequest.get('/cross-sell');
	return response.data;
}

export async function getDirectInvestmentData(): Promise<DirectInvestmentFeatureDetails> {
	const response = await investmentEntityRequest('/invest/direct-investment');
	return response.data;
}

export const getOfferingReservationWindows = async (offeringVintageId: string): Promise<Array<ReservationWindow>> => {
	const response = await investmentEntityRequest.get(`/offering/${offeringVintageId}/reservation-windows`);
	return response.data;
};

export const getOrderAllocation = async (amount: string, riaPlanId: string): Promise<OrderGroupResponse> => {
	const response = await investmentEntityRequest.get(`/investment/allocation`, {
		params: {
			amount,
			riaPlanId
		}
	});
	return response.data;
};

export async function getOrderMetadata(reitOfferingVintageId?: string, cohortId?: string): Promise<InvestMetadata> {
	const response = await investmentEntityRequest.get(`/repeat-investment/metadata`, {
		params: { reitOfferingVintageId, cohortId }
	});

	return response.data;
}

export async function getReviewOrderDetails(orderGroupId: string): Promise<InvestReviewDetails> {
	const response = await investmentEntityRequest.get(`/repeat-investment/order-review/${orderGroupId}`);
	return response.data;
}

export async function updateOrderGroup(
	orderGroupId: string,
	payload: CheckoutOrderGroupDetails
): Promise<OrderGroupResponse> {
	const response = await investmentEntityRequest.put(`/investment/order-group/${orderGroupId}`, payload);
	return response.data;
}
