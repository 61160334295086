<template>
	<li
		class="position-relative main-nav__menu-item-no-hover position-relative"
		data-dd-action-name="user dropdown"
		data-test="user-dropdown-toggle"
		@click.stop="toggleDropdown"
	>
		<BaseLinkButton click-text="user dropdown">
			<span class="display-flex flex-direction-row align-items-center">
				<Avatar class="text-transform-none" avatar-size="nav" />
				<BaseSvgIcon
					name="chevron"
					width="20"
					height="20"
					:dir="dropdownExpanded ? 'up' : 'down'"
					class="ml-25 main-nav__simple-item-link dropdown-accordion-icon position-relative"
				/>
			</span>
		</BaseLinkButton>

		<ul
			v-click-away="onClickAway"
			class="main-nav__sub-menu main-nav__sub-menu--user-rollup mx-100"
			:class="{ 'main-nav__sub-menu--state-visible': dropdownExpanded }"
			@click.stop
		>
			<li
				class="display-flex flex-direction-column align-items-center pt-150 px-100"
				:class="{ 'bg-color-gold-20': isProUser }"
			>
				<Avatar avatar-size="medium" class="mb-50" />
				<div class="heading-5 text-align-center mb-100">{{ userName }}</div>
				<div v-if="isProUser" class="mb-150 display-flex justify-content-center align-items-center">
					<BaseLink
						v-if="!isAdvisor"
						:link="{ router: 'profile-pro-features', text: 'View Pro features' }"
						data-dd-action-name="pro cta link"
						data-test="join-pro-cta-link"
						class="button button-outline body-xs"
					/>
				</div>
			</li>
			<li class="account-section">
				<ul>
					<li class="main-nav__sub-menu-item" :class="!isProUser ? 'bt' : ''">
						<BaseLink
							:link="{ router: 'profile-personal-information-view' }"
							classes="main-nav__sub-menu-item-link  text-align-left display-flex align-items-center"
							data-dd-action-name="menu profile and preferences"
						>
							<BaseSvgIcon name="user-outline" width="18" height="18" class="mr-100 dark" />
							<span class="dark">Profile &amp; Preferences</span>
						</BaseLink>
					</li>
					<li v-if="isEligibleForReferral" class="main-nav__sub-menu-item bt">
						<BaseLink
							:link="{ router: 'referral-landing' }"
							classes="main-nav__sub-menu-item-link  text-align-left display-flex align-items-center"
							data-dd-action-name="menu referral"
						>
							<BaseSvgIcon name="gift" width="18" height="18" class="mr-100 text-color-rust" />
							<span class="dark">Earn a {{ currency(referralAmount, 0) }} voucher</span>
						</BaseLink>
					</li>
					<li v-if="hasMultipleEntities" class="pb-50 bt">
						<div class="mx-100">
							<p class="font-weight-normal text-align-left mt-100"> Accounts </p>
							<NavInvestmentEntityList />
						</div>
					</li>

					<li v-if="!isAdvisor" class="main-nav__sub-menu-item bt">
						<BaseLink
							:link="{ router: 'settings-account-information-redirect' }"
							classes="main-nav__sub-menu-item-link  text-align-left display-flex align-items-center"
							data-dd-action-name="menu settings"
						>
							<BaseSvgIcon name="gear-outline" width="20" height="20" class="mr-100" />
							<span class="dark">Settings</span>
						</BaseLink>
					</li>

					<li class="main-nav__sub-menu-item bt">
						<BaseLink
							classes="main-nav__sub-menu-item-link  text-align-left display-flex align-items-center"
							data-dd-action-name="menu log out"
							mixpanel-target="Log out"
							:link="{ router: 'logout-success' }"
						>
							<BaseSvgIcon name="logout" width="20" height="20" class="mr-100" />
							<span class="dark">Log out</span>
						</BaseLink>
					</li>
				</ul>
			</li>
		</ul>
	</li>
</template>

<script setup lang="ts">
import { isAdvisor, isProUser } from '@utils/composables';
import Avatar from '@components/avatar/avatar-component.vue';
import { computed } from 'vue';
import { currency } from '@filters/shared-filters';
import NavInvestmentEntityList from '@components/header/navigation/nav-investment-entity-list.vue';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useUserStore } from '@stores/user';

interface Props {
	hasMultipleEntities?: boolean;
	expanded?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	hasMultipleEntities: false,
	expanded: false
});

interface Emits {
	(e: 'expandUserDropdown', value: boolean): void;
}
const emit = defineEmits<Emits>();

const investmentEntityStore = useInvestmentEntityStore();
const userStore = useUserStore();

const referralAmount = computed((): string => {
	return userStore.invitationDetails?.invitationDetails.rewardPerInvite ?? '';
});

const userName = computed((): string => {
	return userStore.user?.firstName && userStore.user?.lastName
		? `${userStore.user.firstName} ${userStore.user.lastName}`
		: 'Your Account';
});

const dropdownExpanded = computed((): boolean => {
	return props.expanded;
});

const isEligibleForReferral = computed((): boolean => {
	return investmentEntityStore.isEligibleForReferral;
});

function toggleDropdown(): void {
	emit('expandUserDropdown', !props.expanded);
}

function onClickAway(): void {
	emit('expandUserDropdown', false);
}
</script>

<style lang="scss" scoped>
@use '../../../styles/constants/colors' as *;

li.bg-color-gold-20 {
	border-bottom: 1px solid gold(90);
}

.text-transform-none {
	text-transform: none;
}
</style>
