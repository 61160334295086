import { IraAddFundsRoute, IraRequiredValue } from 'types/ira';
import { useIraStore } from '@stores/ira';

export const isContribution = (): boolean => {
	const ira = useIraStore();
	return ira.fundingType === 'CONTRIBUTION';
};
export const isTransfer = (): boolean => {
	const ira = useIraStore();
	return ira.fundingType === 'TRANSFER';
};
export const isExisting = (): boolean => {
	const ira = useIraStore();
	return ira.fundingType === 'EXISTING';
};
export const isRollover = (): boolean => {
	const ira = useIraStore();
	return ira.fundingType === 'ROLLOVER';
};

const fundingTypeSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.fundingType) {
		return null;
	} else {
		return 'ira-add-funds-funding';
	}
};

const sourceAccountTypeSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.sourceAccountType && ira.sourceAccountTypeLabel) {
		return null;
	} else {
		return 'ira-add-funds-transfer-type';
	}
};

const selectedPaymentMethodSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.selectedPaymentMethod) {
		return null;
	} else {
		return 'ira-add-funds-funding-source';
	}
};

const orderGroupAcknowledgedSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.orderGroupAcknowledged) {
		return null;
	} else {
		return 'ira-add-funds-agreements';
	}
};

export const getAmountRoute = (): IraAddFundsRoute =>
	isContribution() ? 'ira-add-funds-amount' : 'ira-add-funds-transfer-amount';

const selectedAmountSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.selectedAmount) {
		return null;
	} else {
		return getAmountRoute();
	}
};

const selectedTaxYearSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.selectedTaxYear) {
		return null;
	} else {
		return 'ira-add-funds-amount';
	}
};

const reitInvestmentOrderGroupIdSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.reitInvestmentOrderGroupId) {
		return null;
	} else {
		return 'ira-add-funds-confirm';
	}
};

const contributionSet = (): IraAddFundsRoute | null => {
	if (isContribution()) {
		return null;
	} else {
		return 'ira-add-funds-funding';
	}
};

const rolloverSet = (): IraAddFundsRoute | null => {
	if (isRollover()) {
		return null;
	} else {
		return 'ira-add-funds-funding';
	}
};

const rolloverLegacySet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (isRollover() && ira.legacyIra) {
		return null;
	} else {
		return 'ira-add-funds-success';
	}
};

const transferSet = (): IraAddFundsRoute | null => {
	if (isTransfer()) {
		return null;
	} else {
		return 'ira-add-funds-funding';
	}
};

const transferOrExistingSet = (): IraAddFundsRoute | null => {
	if (isExisting() || isTransfer()) {
		return null;
	} else {
		return 'ira-add-funds-funding';
	}
};

const rolloverOrTransferSet = (): IraAddFundsRoute | null => {
	if (isTransfer() || isRollover()) {
		return null;
	} else {
		return 'ira-add-funds-funding';
	}
};

const simpleSourceAccountTypeSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.sourceAccountType === 'SIMPLE') {
		return null;
	} else {
		return 'ira-add-funds-transfer-type';
	}
};

const simpleIraFirstContributionDateSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.simpleIraFirstContributionDate) {
		return null;
	} else {
		return 'ira-add-funds-account-age';
	}
};

const simpleIraFirstContributionDateValidFalseSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.simpleIraFirstContributionDateValid !== null && !ira.simpleIraFirstContributionDateValid) {
		return null;
	} else if (ira.simpleIraFirstContributionDateValid) {
		return 'ira-add-funds-transfer-account-info';
	} else {
		return 'ira-add-funds-account-age';
	}
};

const sourceCustodianDtcInfoSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.sourceCustodianName && ira.sourceDtcParticipantNumber) {
		return null;
	} else if (ira.sourceDtcParticipant !== null) {
		return null;
	} else {
		return 'ira-add-funds-transfer-account-info';
	}
};

const sourceAccountNumberSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.sourceAccountNumber) {
		return null;
	} else {
		return 'ira-add-funds-transfer-account-info';
	}
};

const addFundsPlanSelectionRequiredSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.addFundsPlanSelectionRequired) {
		return null;
	} else {
		return 'ira-add-funds-confirm';
	}
};

const addFundsDripPreferenceRequiredSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.addFundsDripPreferenceRequired) {
		return null;
	} else {
		return 'ira-add-funds-confirm';
	}
};

const planRequiredAndSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.addFundsPlanSelectionRequired) {
		return ira.plan ? null : 'ira-add-funds-plan';
	} else {
		return null;
	}
};

const dripRequiredandSet = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.addFundsDripPreferenceRequired) {
		return ira.dripPreference !== null ? null : 'ira-add-funds-drip';
	} else {
		return null;
	}
};

const contactCreatedTrue = (): IraAddFundsRoute | null => {
	const ira = useIraStore();
	if (ira.mtcContactCreated) {
		return null;
	} else {
		return 'ira-add-funds-confirm';
	}
};

const getFunction = (requiredVal: IraRequiredValue): (() => IraAddFundsRoute | null) => {
	switch (requiredVal) {
		case 'fundingType':
			return fundingTypeSet;
		case 'sourceAccountType':
			return sourceAccountTypeSet;
		case 'selectedPaymentMethod':
			return selectedPaymentMethodSet;
		case 'orderGroupAcknowledged':
			return orderGroupAcknowledgedSet;
		case 'selectedAmount':
			return selectedAmountSet;
		case 'selectedTaxYear':
			return selectedTaxYearSet;
		case 'reitInvestmentOrderGroupId':
			return reitInvestmentOrderGroupIdSet;
		case 'contributionFlow':
			return contributionSet;
		case 'transferFlow':
			return transferSet;
		case 'rolloverFlow':
			return rolloverSet;
		case 'rolloverLegacy':
			return rolloverLegacySet;
		case 'transferOrExisting':
			return transferOrExistingSet;
		case 'rolloverOrTransferFlow':
			return rolloverOrTransferSet;
		case 'simpleSourceAccountType':
			return simpleSourceAccountTypeSet;
		case 'simpleIraFirstContributionDate':
			return simpleIraFirstContributionDateSet;
		case 'simpleIraFirstContributionDateValidFalse':
			return simpleIraFirstContributionDateValidFalseSet;
		case 'sourceCustodianDtcInfo':
			return sourceCustodianDtcInfoSet;
		case 'sourceAccountNumber':
			return sourceAccountNumberSet;
		case 'addFundsPlanSelectionRequired':
			return addFundsPlanSelectionRequiredSet;
		case 'addFundsDripPreferenceRequired':
			return addFundsDripPreferenceRequiredSet;
		case 'plan':
			return planRequiredAndSet;
		case 'dripPreference':
			return dripRequiredandSet;
		case 'contactSet':
			return contactCreatedTrue;
		default:
			return fundingTypeSet;
	}
};

const getFirstRequiredRoute = (requiredValsPriorityOrder: Array<IraRequiredValue>): IraAddFundsRoute | null => {
	const requiredRoutes = requiredValsPriorityOrder.reduce(
		(result, requiredVal) => {
			const redirect = getFunction(requiredVal)();

			if (redirect) {
				result.push(redirect);
			}

			return result;
		},
		[] as Array<IraAddFundsRoute | null>
	);

	return requiredRoutes[0];
};

export const handleRequiredValues = async (
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	next: any,
	requiredValsPriorityOrder: Array<IraRequiredValue>
): Promise<void> => {
	const requiredRoute = getFirstRequiredRoute(requiredValsPriorityOrder);

	if (requiredRoute) {
		try {
			await next({ name: requiredRoute });
		} catch {}
	} else {
		try {
			await next();
		} catch {}
	}
};
