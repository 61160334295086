import { FundriseRouteConfig } from 'vue-router';
import { kebabToEnum } from '@filters/shared-filters';
import { SignupStepName } from 'types/checkout';
import { useQuestionInfoFrameworkStore } from '@stores/question-info-framework';
import { useSignupStore } from '@stores/signup';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const questionInfoFrameworkRoutes: FundriseRouteConfig[] = [
	{
		path: '/question',
		name: 'question-framework',
		meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
		beforeEnter: async (to, from, next) => {
			const signupStore = useSignupStore();
			const questionName = signupStore.signupCheckoutSelections?.currentStep?.questionName;

			if (to.params?.questionId || questionName) {
				next();
			} else {
				next({ name: 'checkout-resume', replace: true });
			}
		},
		component: () => import('@views/question-framework/question-framework.vue'),
		children: [
			{
				path: `:questionId`,
				name: 'question',
				meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
				beforeEnter: async (to, from, next) => {
					const signupStore = useSignupStore();
					const questionInfoFrameworkStore = useQuestionInfoFrameworkStore();
					const questionName = kebabToEnum(to.params.questionId as string);

					if (questionName) {
						const stepName = questionName + '_QUESTION';
						await signupStore.getUserCheckoutDetailsData(stepName as SignupStepName);
						await questionInfoFrameworkStore.getQuestion(questionName);
						next();
					} else {
						next({ name: 'checkout-resume', replace: true });
					}
				},
				component: () => import('@views/question-framework/question-framework-prompt.vue')
			}
		]
	},
	{
		path: '/info',
		name: 'info-framework',
		meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
		beforeEnter: async (to, from, next) => {
			const signupStore = useSignupStore();
			const infoName = signupStore.signupCheckoutSelections?.currentStep?.infoName;

			if (to.params?.infoSlug || infoName) {
				next();
			} else {
				next({ name: 'checkout-resume', replace: true });
			}
		},
		component: () => import('@views/info-framework/info-framework.vue'),
		children: [
			{
				path: `:infoSlug`,
				name: 'info-screen',
				meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
				beforeEnter: async (to, from, next) => {
					const signupStore = useSignupStore();
					const questionInfoFrameworkStore = useQuestionInfoFrameworkStore();
					const infoName = kebabToEnum(to.params.infoSlug as string);

					if (infoName) {
						const stepName = infoName + '_INFO';
						await signupStore.getUserCheckoutDetailsData(stepName as SignupStepName);
						await questionInfoFrameworkStore.getInfo(infoName);
						next();
					} else {
						next({ name: 'checkout-resume', replace: true });
					}
				},
				component: () => import('@views/info-framework/info-screen.vue')
			}
		]
	}
];

export default questionInfoFrameworkRoutes;
