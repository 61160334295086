import {
	AssetClassV2,
	PerformanceEligibleQuarter,
	PerformanceReturnBreakdown,
	ReturnsFormat,
	TimeChipSelection,
	TimePeriodType
} from 'types/performance-portfolio';
import { computed, ref } from 'vue';
import {
	getNetReturnsOverTimeData,
	getNetReturnsOverTimeDataByAssetClass,
	getNetReturnsOverTimeDataForIpo,
	getPerformanceReturnBreakdown,
	getPerformanceReturnBreakdownByAssetClass
} from '@api/performance-portfolio';
import { CompanyElement } from 'types/company-element';
import { defineStore } from 'pinia';
import { NetReturnsOverTimeData } from 'types/account';
import { ReitAssetDetail } from 'types/asset-details';
import { useAppStore } from '@stores/app';
import { useUserStore } from './user';

const defaultAssetClassTabCache: Record<AssetClassV2, string | null> = {
	REAL_ESTATE: null,
	PRIVATE_CREDIT: null,
	VENTURE: null,
	OTHER: null
};

type RateOfReturnType = 'annualized' | 'cumulative';

export const usePerformancePortfolioStore = defineStore('performancePortfolio', () => {
	const endingAccountValue = ref('');
	const cachedNetReturnsOverTime = ref<Record<string, NetReturnsOverTimeData>>({});
	const cachedNetReturnsBreakdown = ref<Record<string, PerformanceReturnBreakdown>>({});
	const selectedAdvisorView = ref<AssetClassV2 | 'ALL'>('ALL');
	const selectedTimePeriod = ref<TimePeriodType>('All');
	const previousSelectedTimePeriod = ref<TimePeriodType | null>(null);
	const selectedCustomYearAndQuarter = ref<PerformanceEligibleQuarter | null>(null);
	const returnsTableFormat = ref<ReturnsFormat>('%');
	const performanceReturnsValueFormat = ref<ReturnsFormat>('%');

	const filteredPropertyElements = ref<Array<ReitAssetDetail>>([]);
	const filteredCompanyElements = ref<Array<CompanyElement>>([]);

	const assetClassTabCache = ref<Record<AssetClassV2, string | null>>(defaultAssetClassTabCache);

	const hideAnnualizedReturnFromNewInvestors = ref<boolean | null>(null);

	const isPortfolioTabDesignImprovementsEnabled = ref<boolean | null>(null);

	const rateOfReturnSelection = ref<RateOfReturnType | null>(null);

	const showAllReturns = computed(
		(): boolean =>
			selectedTimePeriod.value === 'All' ||
			(selectedTimePeriod.value === 'Custom' && previousSelectedTimePeriod.value === 'All')
	);

	const showYtdReturns = computed(
		(): boolean =>
			selectedTimePeriod.value === 'YTD' ||
			(selectedTimePeriod.value === 'Custom' && previousSelectedTimePeriod.value === 'YTD')
	);

	const showCustomYearReturns = computed(
		(): boolean =>
			selectedTimePeriod.value === 'Custom Applied' && selectedCustomYearAndQuarter.value?.quarter === 'All'
	);

	const showQtdReturns = computed(
		(): boolean =>
			selectedTimePeriod.value === 'QTD' ||
			(selectedTimePeriod.value === 'Custom' && previousSelectedTimePeriod.value === 'QTD')
	);

	const showCustomQuarterReturns = computed(
		(): boolean =>
			selectedTimePeriod.value === 'Custom Applied' && selectedCustomYearAndQuarter.value?.quarter !== 'All'
	);

	async function fetchNetReturnsOverTimeData({
		assetClass,
		year,
		quarter
	}: {
		assetClass?: AssetClassV2 | 'FUNDRISE_IPO';
		year?: number;
		quarter?: number;
	} = {}): Promise<NetReturnsOverTimeData> {
		const key = [assetClass, year, quarter].map((slug) => slug ?? '').join(':');

		if (!cachedNetReturnsOverTime.value[key]) {
			if (assetClass === 'FUNDRISE_IPO') {
				const data = await getNetReturnsOverTimeDataForIpo();
				cachedNetReturnsOverTime.value[key] = data;
			} else if (assetClass) {
				const data = await getNetReturnsOverTimeDataByAssetClass(assetClass, year, quarter);
				cachedNetReturnsOverTime.value[key] = data;
			} else {
				const data = await getNetReturnsOverTimeData(year, quarter);
				cachedNetReturnsOverTime.value[key] = data;
			}
		}

		return cachedNetReturnsOverTime.value[key];
	}

	async function fetchNetReturnsBreakdownData({
		assetClass,
		year,
		quarter
	}: {
		assetClass?: AssetClassV2;
		year?: number;
		quarter?: number;
	} = {}): Promise<PerformanceReturnBreakdown> {
		const key = [assetClass, year, quarter].map((slug) => slug ?? '').join(':');
		const yearAndQuarterArgs = isPortfolioTabDesignImprovementsEnabled.value ? [year, quarter] : [];

		if (!cachedNetReturnsBreakdown.value[key]) {
			if (assetClass) {
				const data = await getPerformanceReturnBreakdownByAssetClass(assetClass, ...yearAndQuarterArgs);
				cachedNetReturnsBreakdown.value[key] = data;
			} else {
				const data = await getPerformanceReturnBreakdown(...yearAndQuarterArgs);
				cachedNetReturnsBreakdown.value[key] = data;
			}
		}

		return cachedNetReturnsBreakdown.value[key];
	}

	function setTimeSelections(selection: TimeChipSelection): void {
		if (selection.timePeriod === 'Custom') {
			previousSelectedTimePeriod.value = selectedTimePeriod.value;
			selectedTimePeriod.value = selection.timePeriod;
		} else if (selection.timePeriod === 'Custom Applied' && selection.yearAndQtr) {
			selectedTimePeriod.value = selection.timePeriod;
			selectedCustomYearAndQuarter.value = selection.yearAndQtr;
			previousSelectedTimePeriod.value = null;
		} else {
			selectedTimePeriod.value = selection.timePeriod;
			previousSelectedTimePeriod.value = null;
			selectedCustomYearAndQuarter.value = null;
		}
	}

	async function storeHideAnnualizedReturnFromNewInvestors(): Promise<void> {
		if (hideAnnualizedReturnFromNewInvestors.value === null) {
			const appStore = useAppStore();
			const isFeatureEnabled = await appStore.isFeatureEnabled('prod-2011-cumulative-net-returns');
			hideAnnualizedReturnFromNewInvestors.value = isFeatureEnabled;
		}
	}

	async function storeDesignImprovementsToPortfolioTabEnabled(): Promise<void> {
		if (isPortfolioTabDesignImprovementsEnabled.value === null) {
			const appStore = useAppStore();
			isPortfolioTabDesignImprovementsEnabled.value = await appStore.isUserInLDTestGroup({
				featureFlag: '01-09-2025-portfolio-tab-design-improvements-ab-test',
				testGroup: 'test-with-updates'
			});
		}
	}

	function storeRateOfReturnSelection(): void {
		if (!rateOfReturnSelection.value) {
			const userStore = useUserStore();
			rateOfReturnSelection.value = userStore.isInvestedForLessThanAYear ? 'cumulative' : 'annualized';
		}
	}

	function saveTabSelection(selection: { assetClass: AssetClassV2; tabKey: string }): void {
		assetClassTabCache.value = { ...assetClassTabCache.value, [selection.assetClass]: selection.tabKey };
	}

	function clearTabSelections(): void {
		assetClassTabCache.value = defaultAssetClassTabCache;
	}

	function $reset(): void {
		endingAccountValue.value = '';
		cachedNetReturnsOverTime.value = {};
		selectedAdvisorView.value = 'ALL';
		selectedTimePeriod.value = 'All';
		previousSelectedTimePeriod.value = null;
		selectedCustomYearAndQuarter.value = null;
		returnsTableFormat.value = '%';
		performanceReturnsValueFormat.value = '%';
		filteredPropertyElements.value = [];
		filteredCompanyElements.value = [];
		assetClassTabCache.value = defaultAssetClassTabCache;
		hideAnnualizedReturnFromNewInvestors.value = null;
		isPortfolioTabDesignImprovementsEnabled.value = null;
		rateOfReturnSelection.value = null;
	}

	return {
		endingAccountValue,
		cachedNetReturnsOverTime,
		cachedNetReturnsBreakdown,
		selectedAdvisorView,
		selectedTimePeriod,
		previousSelectedTimePeriod,
		selectedCustomYearAndQuarter,
		returnsTableFormat,
		performanceReturnsValueFormat,
		filteredPropertyElements,
		filteredCompanyElements,
		assetClassTabCache,
		hideAnnualizedReturnFromNewInvestors,
		isPortfolioTabDesignImprovementsEnabled,
		rateOfReturnSelection,
		showAllReturns,
		showYtdReturns,
		showCustomYearReturns,
		showQtdReturns,
		showCustomQuarterReturns,
		fetchNetReturnsOverTimeData,
		fetchNetReturnsBreakdownData,
		setTimeSelections,
		storeHideAnnualizedReturnFromNewInvestors,
		storeDesignImprovementsToPortfolioTabEnabled,
		storeRateOfReturnSelection,
		saveTabSelection,
		clearTabSelections,
		$reset
	};
});
