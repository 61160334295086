import { AccountRequiredState } from 'types/account';
import { getLoginMobileVerifyMeta } from '@api/login';
import { useLoginStore } from '@stores/login';
import { useUserStore } from '@stores/user';

const userHasCurrentPhone = (): boolean => {
	const userStore = useUserStore();
	return !!userStore.phoneData.currentPhoneNumber;
};

const userPhoneNotVerified = (): boolean => {
	const userStore = useUserStore();
	return !userStore.phoneData.currentPhoneNumberValidated;
};

const userIsNotRestrictedFromVisitingMobileVerifyFlow = async (): Promise<boolean> => {
	try {
		const meta = await getLoginMobileVerifyMeta();
		return !(meta.hasVisitedInSession || meta.hasExceededMaxPrompts);
	} catch {
		return false;
	}
};

const userHasCompletedSelectInCurrentSession = (): boolean => {
	const loginStore = useLoginStore();
	return loginStore.hasCompletedLoginMobileVerifySelect;
};

const getFunction = (requiredState: AccountRequiredState): (() => boolean | Promise<boolean>) => {
	switch (requiredState) {
		case 'userHasCurrentPhone':
			return userHasCurrentPhone;
		case 'userPhoneNotVerified':
			return userPhoneNotVerified;
		case 'userIsNotRestrictedFromVisitingMobileVerifyFlow':
			return userIsNotRestrictedFromVisitingMobileVerifyFlow;
		case 'userHasCompletedSelectInCurrentSession':
			return userHasCompletedSelectInCurrentSession;
		default:
			return userHasCurrentPhone;
	}
};

const checkRequiredStates = async (requiredStates: Array<AccountRequiredState>): Promise<boolean> => {
	for (const requiredState of requiredStates) {
		if (!(await getFunction(requiredState)())) {
			return false;
		}
	}
	return true;
};

export const verifyRequiredStates = async (
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	next: any,
	requiredStates: Array<AccountRequiredState>
): Promise<void> => {
	try {
		const isValidStep = await checkRequiredStates(requiredStates);
		if (isValidStep) {
			await next();
		} else {
			await next({ name: 'profile-personal-information-view' });
		}
	} catch {
		await next({ name: 'profile-personal-information-view' });
	}
};
