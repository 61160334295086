import { logError } from '@utils/error-tracking';
import { useAppStore } from '@stores/app';

export async function waitForAwsWafIntegration() {
	const appStore = useAppStore();
	if (!appStore.wafIsLoaded) {
		return new Promise((resolve, reject) => {
			const maxAttempts = 40; // Maximum number of attempts
			const interval = 100; // Interval in milliseconds between attempts
			let attempts = 0;

			const checkAwsWafIntegration = () => {
				attempts++;
				if (window.AwsWafIntegration) {
					appStore.wafIsLoaded = true;
					resolve(true);
				} else if (attempts >= maxAttempts) {
					appStore.wafIsLoaded = false;
					reject(new Error('AWS WAF Integration is not available.'));
				} else {
					setTimeout(checkAwsWafIntegration, interval);
				}
			};

			checkAwsWafIntegration();
		});
	}
}

export async function getWafCaptchaToken() {
	if (import.meta.env.VITE_WAF_CHALLENGE_URL) {
		await waitForAwsWafIntegration();
		const token = await window.AwsWafIntegration.getToken().catch(logError);
		return token;
	}
}
