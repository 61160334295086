import { BaseAlertComponentType } from 'types/layout';
import { computed } from 'vue';
import { getOS } from '@utils/client';
import { InfoModule } from 'types/invest';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useUserStore } from '@stores/user';

export const isAdvisor = computed((): boolean => {
	const appStore = useAppStore();
	return appStore.isAdvisor;
});
export const isAndroidDevice = computed((): boolean => getOS() === 'Android');
export const isAuthenticated = computed((): boolean => {
	const appStore = useAppStore();
	return appStore.isAuthenticated;
});
export const isDesktop = computed((): boolean => {
	const appStore = useAppStore();
	return appStore.screenSize === 'DESKTOP';
});
export const isExistingInvestor = computed((): boolean => {
	const userStore = useUserStore();
	return userStore.isExistingInvestor;
});
export const isMobile = computed((): boolean => {
	const appStore = useAppStore();
	return appStore.screenSize === 'MOBILE';
});

export const isRepeatInvestor = computed((): boolean => {
	const appStore = useAppStore();
	const investmentEntityStore = useInvestmentEntityStore();
	return appStore.isAuthenticated && investmentEntityStore.additionalInvestmentEligibility === 'VALID';
});
export const isPremiumUser = computed((): boolean => {
	const userStore = useUserStore();
	return userStore.isPremiumUser;
});
export const isProUser = computed((): boolean => {
	const userStore = useUserStore();
	return userStore.isSubscriptionActive;
});
export const isProOrPremium = computed((): boolean => {
	const userStore = useUserStore();
	return userStore.isProOrPremium;
});
export const isTablet = computed((): boolean => {
	const appStore = useAppStore();
	return appStore.screenSize === 'TABLET';
});
export const isWebview = computed((): boolean => {
	const appStore = useAppStore();
	return appStore.isMobileWebview;
});

export function mapInfoModuleToBaseAlert(
	infoModule: InfoModule | null | undefined
): BaseAlertComponentType | undefined {
	return infoModule
		? {
				heading: infoModule.title,
				iconUrl: infoModule.icon,
				message: infoModule.body?.split('\n') ?? []
			}
		: undefined;
}
