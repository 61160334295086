<template>
	<ul v-if="investmentEntities.length > 1">
		<NavInvestmentEntityListItem
			v-for="investmentEntity in investmentEntities"
			:key="investmentEntity.investmentEntityId"
			:entity="investmentEntity"
		/>
	</ul>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { InvestmentEntity } from 'types/investment-entity';
import NavInvestmentEntityListItem from '@components/header/navigation/nav-investment-entity-list-item.vue';
import { useUserStore } from '@stores/user';

const userStore = useUserStore();

const investmentEntities = computed((): Array<InvestmentEntity> => {
	if (userStore.user?.entityDetails) {
		return userStore.user.entityDetails?.investmentEntities;
	}

	return [];
});
</script>
