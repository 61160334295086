import { RouteLocationRaw } from 'vue-router';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useInvestStore } from '@stores/invest';

function addFundsEntryRedirectMissingInfo(): string | null {
	const investmentEntityStore = useInvestmentEntityStore();

	switch (investmentEntityStore.entityType) {
		case 'INDIVIDUAL':
			return `/account/profile/personal-information`;
		case 'JOINT_ACCOUNT':
			return `/account/investment-accounts/joint-account/account-holder-information/edit`;
		case 'TRUST':
			return `/account/investment-accounts/trust/information/edit`;
		case 'LLC':
			return `/account/investment-accounts/entity-account/entity-information/edit`;
		case 'IRA':
			if (investmentEntityStore.iraEntityMetadata.isIraOnboardingFinished) {
				return '/ira/add-funds'
			} else {
				return '/ira/start'
			}
		default:
			return null;
	}
}

export const addFundsEntryRedirectHandling = (): string | null => {
	const investmentEntityStore = useInvestmentEntityStore();
	switch (investmentEntityStore.additionalInvestmentEligibility) {
		case 'ENTITY_NO_INVESTMENTS':
			return '/start-investing';
		case 'ENTITY_NO_PLAN_SELECTION':
			return '/account/ria-upgrade';
		case 'ENTITY_MISSING_INFORMATION':
			return addFundsEntryRedirectMissingInfo();
		default:
			return null;
	}
};

function addFundsOfferingEntryRedirectMissingInformation(): null | RouteLocationRaw {
	const investmentEntityStore = useInvestmentEntityStore();
	switch (investmentEntityStore.entityType) {
		case 'INDIVIDUAL':
			return {
				name: 'profile-address-edit',
				replace: true
			};
		case 'JOINT_ACCOUNT':
			return {
				name: 'settings-joint-account-information-edit',
				replace: true
			};
		case 'TRUST':
			return {
				name: 'settings-trust-information-edit',
				replace: true
			};
		case 'LLC':
			return {
				name: 'settings-entity-account-information-edit',
				replace: true
			};
		default:
			return null;
	}
}

export const addFundsOfferingEntryRedirectHandling = (): null | RouteLocationRaw => {
	const investStore = useInvestStore();
	const investmentEntityStore = useInvestmentEntityStore();
	if (investmentEntityStore.isIraEntity) {
		if (investmentEntityStore.iraEntityMetadata.isIraOnboardingFinished) {
			return {
				name: 'ira-add-funds-start',
				query: { reitOfferingVintageId: investStore.investMetaData?.offering?.vintageId },
				replace: true
			};
		} else {
			return { name: 'ira-start', replace: true };
		}
	}
	switch (investmentEntityStore.additionalInvestmentEligibility) {
		case 'ENTITY_NO_INVESTMENTS':
			return { name: 'start-investing', replace: true };
		case 'ENTITY_MISSING_INFORMATION':
			return addFundsOfferingEntryRedirectMissingInformation();
		default:
			return null;
	}
};
