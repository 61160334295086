import { computed, ref } from 'vue';
import {
	getIpoRedemptionRequestMetadata,
	getRedemptionPreview,
	getReitRedemptionRequestMetadata,
	submitRequest
} from '@api/redemption-request';
import {
	RedeemableReitItem,
	RedemptionPreference,
	RedemptionRequestMetadata,
	RedemptionRequestPreviewData,
	RedemptionRequestSurvey,
	RedemptionRequestType,
	RedemptionSubmission,
	ReitRedemptionRequest
} from 'types/redemption-request';
import { defineStore } from 'pinia';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useInvestmentGoalsStore } from './investment-goals';
import { useUserStore } from '@stores/user';

export const useRedemptionRequestStore = defineStore('redemptionRequest', () => {
	const autoInvestor = ref(false);
	const expectedQuarterlyProcessingDate = ref('');
	const fullRedemptionExcludesBlockedFunds = ref(false);
	const futureQuarterlyProcessingDates = ref<Array<string>>([]);
	const hasCurrentInvestmentGoal = ref(false);
	const hasPendingDrip = ref(false);
	const hasPendingRecurringInvestments = ref(false);
	const hasPendingRedemptions = ref(false);
	const hasPendingReitInvestments = ref(false);
	const hasReitsEligibleForRedemption = ref(false);
	const hasUnredeemedIpoShareholdings = ref(false);
	const nextRequestDeadline = ref('');
	const reInvestor = ref(false);
	const redeemableReits = ref<Array<RedeemableReitItem>>([]);
	const redemptionType = ref<RedemptionRequestType>('REIT');
	const redemptionRequestInterstitialTestFlag = ref<string | null>(null);
	const redemptionRequestInterstitialVariant = ref<string | null>('control');
	const redemptionRequests = ref<Array<ReitRedemptionRequest>>([]);
	const redemptionPreference = ref<RedemptionPreference | null>(null);
	const redemptionPreview = ref<RedemptionRequestPreviewData | null>(null);
	const redemptionRequestGroupId = ref('');
	const showProceeds = ref(false);
	const submitted = ref(false);
	const survey = ref<RedemptionRequestSurvey>({
		redemptionReason: null,
		marketReason: null,
		dissatisfactionSubtype: null,
		personalReason: null,
		customMarketReason: '',
		customDissatisfactionReason: '',
		customPersonalReason: '',
		additionalComments: ''
	});

	const hasRedeemableReits = computed((): boolean => redeemableReits.value.length > 0);

	const hasRequests = computed((): boolean => redemptionRequests.value.length > 0);

	const showRedemptionRequestInterstitial = computed((): boolean => !!redemptionRequestInterstitialVariant.value);

	const completedSurvey = computed((): boolean => survey.value.redemptionReason !== null);

	async function fetchRedemptionRequestInterstitialVariant(): Promise<void> {
		if (redemptionRequestInterstitialTestFlag.value && redemptionRequestInterstitialVariant.value === null) {
			const appStore = useAppStore();
			const variant = await appStore.getLdAbTestGroup(redemptionRequestInterstitialTestFlag.value);
			redemptionRequestInterstitialVariant.value = variant;
		}
	}

	async function fetchAndStoreMetadata(): Promise<void> {
		const redemptionRequestData =
			redemptionType.value === 'REIT'
				? await getReitRedemptionRequestMetadata()
				: await getIpoRedemptionRequestMetadata();
		storeMetadata(redemptionRequestData);
	}

	function storeMetadata(redemptionRequestData: RedemptionRequestMetadata): void {
		autoInvestor.value = redemptionRequestData.autoInvestor;
		expectedQuarterlyProcessingDate.value = redemptionRequestData.expectedQuarterlyProcessingDate;
		fullRedemptionExcludesBlockedFunds.value = redemptionRequestData.fullRedemptionExcludesBlockedFunds;
		futureQuarterlyProcessingDates.value = redemptionRequestData.futureQuarterlyProcessingDates;
		hasCurrentInvestmentGoal.value = redemptionRequestData.hasCurrentInvestmentGoal;
		hasPendingDrip.value = redemptionRequestData.hasPendingDrip;
		hasPendingRecurringInvestments.value = redemptionRequestData.hasPendingRecurringInvestments;
		hasPendingRedemptions.value = redemptionRequestData.hasPendingRedemptions;
		hasPendingReitInvestments.value = redemptionRequestData.hasPendingReitInvestments;
		hasReitsEligibleForRedemption.value = redemptionRequestData.hasReitsEligibleForRedemption;
		hasUnredeemedIpoShareholdings.value = redemptionRequestData.hasUnredeemedIpoShareholdings;
		nextRequestDeadline.value = redemptionRequestData.nextRequestDeadline;
		reInvestor.value = redemptionRequestData.reInvestor;
		redeemableReits.value = redemptionRequestData.redeemableReits;
		redemptionRequests.value = [];
		redemptionPreference.value = null;
		redemptionPreview.value = null;
		redemptionRequestGroupId.value = '';
		submitted.value = false;
		survey.value = {
			redemptionReason: null,
			marketReason: null,
			dissatisfactionSubtype: null,
			personalReason: null,
			customMarketReason: '',
			customDissatisfactionReason: '',
			customPersonalReason: '',
			additionalComments: ''
		};
	}

	async function storeRedemptionPreview(): Promise<void> {
		redemptionPreview.value = await getRedemptionPreview(redemptionRequests.value);
	}

	async function submitPreference(preference: RedemptionPreference): Promise<string> {
		redemptionPreference.value = preference;

		if (redemptionPreference.value === 'FULL') {
			const allReitsForRedemption = redeemableReits.value.map((reitRequest) => {
				return {
					reitId: reitRequest.reitId,
					sharesRequested: reitRequest.sharesOutstanding
				};
			});
			redemptionRequests.value = allReitsForRedemption;
			return `redemption-request-review-${redemptionType.value}`;
		} else {
			return `redemption-request-form-${redemptionType.value}`;
		}
	}

	async function submitRedemptionRequest(): Promise<string> {
		const investmentEntityStore = useInvestmentEntityStore();
		const userStore = useUserStore();

		const redemptionSubmission: RedemptionSubmission = {
			allAcknowledgementsAccepted: true,
			investmentEntityId: investmentEntityStore.investmentEntityId,
			requests: redemptionRequests.value,
			survey: survey.value
		};

		const groupId = await submitRequest(redemptionSubmission);

		await userStore.refreshUser();
		const investmentGoalsStore = useInvestmentGoalsStore();
		investmentGoalsStore.$reset();

		redemptionRequestGroupId.value = groupId;
		submitted.value = true;
		return `redemption-request-success-${redemptionType.value}`;
	}

	function storeSurveyResponse(newSurvey: RedemptionRequestSurvey): string {
		survey.value = newSurvey;
		return `redemption-request-agreements-${redemptionType.value}`;
	}

	function submitRequestForm(redemptionRequestForm: Array<ReitRedemptionRequest>): string {
		redemptionRequests.value = redemptionRequestForm;
		return `redemption-request-review-${redemptionType.value}`;
	}

	function $reset(): void {
		autoInvestor.value = false;
		expectedQuarterlyProcessingDate.value = '';
		fullRedemptionExcludesBlockedFunds.value = false;
		futureQuarterlyProcessingDates.value = [];
		hasCurrentInvestmentGoal.value = false;
		hasPendingDrip.value = false;
		hasPendingRecurringInvestments.value = false;
		hasPendingRedemptions.value = false;
		hasPendingReitInvestments.value = false;
		hasReitsEligibleForRedemption.value = false;
		hasUnredeemedIpoShareholdings.value = false;
		nextRequestDeadline.value = '';
		reInvestor.value = false;
		redeemableReits.value = [];
		redemptionRequests.value = [];
		redemptionPreference.value = null;
		redemptionPreview.value = null;
		redemptionRequestGroupId.value = '';
		submitted.value = false;
		survey.value = {
			redemptionReason: null,
			marketReason: null,
			dissatisfactionSubtype: null,
			personalReason: null,
			customMarketReason: '',
			customDissatisfactionReason: '',
			customPersonalReason: '',
			additionalComments: ''
		};
	}

	return {
		autoInvestor,
		expectedQuarterlyProcessingDate,
		fullRedemptionExcludesBlockedFunds,
		futureQuarterlyProcessingDates,
		hasCurrentInvestmentGoal,
		hasPendingDrip,
		hasPendingRecurringInvestments,
		hasPendingRedemptions,
		hasPendingReitInvestments,
		hasReitsEligibleForRedemption,
		hasUnredeemedIpoShareholdings,
		nextRequestDeadline,
		reInvestor,
		redeemableReits,
		redemptionType,
		redemptionRequestInterstitialTestFlag,
		redemptionRequestInterstitialVariant,
		redemptionRequests,
		redemptionPreference,
		redemptionPreview,
		redemptionRequestGroupId,
		showProceeds,
		submitted,
		survey,
		hasRedeemableReits,
		hasRequests,
		showRedemptionRequestInterstitial,
		completedSurvey,
		fetchRedemptionRequestInterstitialVariant,
		fetchAndStoreMetadata,
		storeMetadata,
		storeRedemptionPreview,
		submitPreference,
		submitRedemptionRequest,
		storeSurveyResponse,
		submitRequestForm,
		$reset
	};
});
