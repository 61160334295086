import {
	BankAccount,
	NewManualBankAccount,
	PaymentMethodOptions,
	PaymentMethodQualifications,
	PaymentMethods
} from 'types/payment';
import { AxiosResponse } from 'axios';
import { invalidCheckoutState } from '@constants/error-codes';
import { investmentEntityRequest } from '@utils/request';
import { PlaidLinkAccountRequest } from 'types/plaid';
import { TransactionType } from 'types/invest';

export function formatBankAccounts(unformattedAccounts: Array<BankAccount>): Array<BankAccount> {
	return unformattedAccounts.map((bankAccount: BankAccount) => {
		const properCaseAccountType =
			bankAccount.accountType.charAt(0).toUpperCase() + bankAccount.accountType.slice(1).toLowerCase();
		bankAccount.label = bankAccount.label + ' (' + properCaseAccountType + ')';
		return bankAccount;
	});
}

export async function getBankAccounts(): Promise<Array<BankAccount>> {
	const response = await investmentEntityRequest.get(`/bank-accounts`);
	return formatBankAccounts(response.data);
}

export async function getPaymentMethods(): Promise<PaymentMethods> {
	const response = await investmentEntityRequest.get(`/payment-methods`);
	return response.data as PaymentMethods;
}

export async function getPaymentMethod(qualifications: PaymentMethodQualifications): Promise<PaymentMethodOptions> {
	const response = await investmentEntityRequest.post(`/payment-options`, qualifications);

	return {
		bankAccounts: formatBankAccounts(response.data.bankAccounts)
	};
}

export async function linkManualBankAccount(payload: NewManualBankAccount): Promise<BankAccount> {
	const response = await investmentEntityRequest.post(`/link-manual-bank-account`, payload);
	return response.data as BankAccount;
}

export async function linkPlaidBankAccount(payload: PlaidLinkAccountRequest): Promise<BankAccount> {
	const response = await investmentEntityRequest.post(`/account/link-plaid-account`, payload);
	return response.data as BankAccount;
}

export async function updateInvestmentBankAccount(
	orderGroupId: string,
	bankAccountId: string,
	ignoreError = false
): Promise<void> {
	await investmentEntityRequest.post(
		`/order-group/${orderGroupId}/attach-bank-account`,
		{
			transactionType: 'ACH',
			bankAccountId
		},
		{ suppressToast: () => ignoreError }
	);
}

export async function attachPaymentMethod(
	paymentMethodId: string,
	transactionType: TransactionType = 'ACH',
	ignoreError = false
): Promise<void> {
	await investmentEntityRequest.put(
		`/signup-checkout/attach-payment-method`,
		{
			paymentMethodId,
			transactionType
		},
		{ suppressToast: (resp: AxiosResponse) => ignoreError || resp.data?.errorCode === invalidCheckoutState }
	);
}

export async function updatePrimaryBankAccount(bankAccountId: string): Promise<BankAccount> {
	const response = await investmentEntityRequest.post(`/bank-account/primary`, { id: bankAccountId });
	return response.data as BankAccount;
}

export async function deleteBankAccount(bankAccountId: string): Promise<void> {
	await investmentEntityRequest.delete(`/bank-account`, { data: { id: bankAccountId } });
}
