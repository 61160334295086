import { computed, getCurrentInstance, onBeforeUnmount, ref } from 'vue';
import { ThirdPartyAuthType, ThirdPartySignupParameters } from 'types/third-party-auth';
import { captureThirdPartyUser } from '@api/lead-capture';
import { defineStore } from 'pinia';
import { determineLoginRoute } from '@utils/login-redirects';
import { getItemFromLocalStorage } from '@utils/web-storage';
import { isWebview } from '@utils/composables';
import { RouteLocationNamedRaw } from 'vue-router';
import { useAppStore } from '@stores/app';
import { useUserStore } from '@stores/user';

export const useThirdPartyAuthStore = defineStore('thirdPartyAuth', () => {
	const _isLoginEnabled = ref<boolean | null>(null);
	const _isSignupEnabled = ref<boolean | null>(null);
	const _isPageDisabled = ref(false);
	const isAppleLibraryLoaded = ref(false);
	const isGoogleLibraryLoaded = ref(false);

	const leadCaptured = computed((): boolean => {
		return !!getItemFromLocalStorage('emailcapture');
	});

	const isLoginEnabled = computed((): boolean => {
		return !_isPageDisabled.value && !!_isLoginEnabled.value;
	});

	const isSignupEnabled = computed((): boolean => {
		return !_isPageDisabled.value && !!_isSignupEnabled.value && !leadCaptured.value && !isWebview.value;
	});

	async function storeLoginEnabled(): Promise<void> {
		if (_isLoginEnabled.value === null) {
			const appStore = useAppStore();
			_isLoginEnabled.value = await appStore.isFeatureEnabled('google-apple-signup');
		}
	}

	async function storeSignupEnabled(): Promise<void> {
		if (_isSignupEnabled.value === null) {
			const appStore = useAppStore();
			_isSignupEnabled.value = await appStore.isUserInLDTestGroup({
				featureFlag: '2025-01-14-google-apple-signup-v2',
				testGroup: 'test-show-3rd-party-auth'
			});
		}
	}

	async function signUp(type: ThirdPartyAuthType, payload: ThirdPartySignupParameters): Promise<string> {
		const userResponseData = await captureThirdPartyUser(type, payload);
		const appStore = useAppStore();
		const userStore = useUserStore();
		await appStore.setUserAuthData(userResponseData.accessToken);

		let routeName: string;
		if (userResponseData.isNewUser) {
			await userStore.setThirdPartyUser(userResponseData);
			routeName = 'checkout-signup-success-interstitial';
		} else {
			const redirect = (await determineLoginRoute()) as RouteLocationNamedRaw;
			routeName = redirect.name as string;
		}

		return routeName;
	}

	function disable(reset: typeof onBeforeUnmount): void {
		if (!getCurrentInstance()) return;

		_isPageDisabled.value = true;
		reset(() => (_isPageDisabled.value = false));
	}

	function $reset(): void {
		_isLoginEnabled.value = null;
		_isSignupEnabled.value = null;
		_isPageDisabled.value = false;
		isAppleLibraryLoaded.value = false;
		isGoogleLibraryLoaded.value = false;
	}

	return {
		isAppleLibraryLoaded,
		isGoogleLibraryLoaded,
		isLoginEnabled,
		isSignupEnabled,
		storeLoginEnabled,
		storeSignupEnabled,
		signUp,
		disable,
		$reset
	};
});
