import { FundriseRouteConfig } from 'vue-router';
import { genericErrorToast } from '@constants/toasts';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useIraStore } from '@stores/ira';
import { useUserStore } from '@stores/user';

const getFirstPendingVerificationIraInvestmentEntityId = (): string => {
	const userStore = useUserStore();
	const pendingVerificationIraEntities = userStore.user?.entityDetails?.investmentEntities.find(
		(entity) => entity.iraEntityMetadata?.verificationStatus === 'AWAITING_DOCUMENTATION'
	);
	return pendingVerificationIraEntities ? pendingVerificationIraEntities.investmentEntityId : '';
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const iraVerifyRoutes: FundriseRouteConfig = {
	path: `/ira/verify`,
	name: 'ira-verify',
	meta: { minTokenScopeRequired: 'FULL_ACCESS' },
	beforeEnter: async (to, from, next) => {
		const ira = useIraStore();
		const pendingVerificationInvestmentEntityId = getFirstPendingVerificationIraInvestmentEntityId();
		const investmentEntityStore = useInvestmentEntityStore();
		const userStore = useUserStore();
		if (pendingVerificationInvestmentEntityId === '') {
			const appStore = useAppStore();
			next({ name: 'account-overview', replace: true });
			appStore.addToast({
				type: 'error',
				message: 'No IRA for verification is available.'
			});
		} else {
			if (investmentEntityStore.investmentEntityId !== pendingVerificationInvestmentEntityId) {
				await userStore.updateSelectedEntity(pendingVerificationInvestmentEntityId);
			}

			ira.flow = 'VERIFY';
			if (!ira.dataRetrieved) {
				try {
					await ira.getVerifyFlowInfo();
				} catch (error) {
					const appStore = useAppStore();
					next({ name: 'account-overview', replace: true });
					appStore.addToast(genericErrorToast);
				}
			}
			await next();
		}
	},
	component: () => import('@views/ira/verify/ira-verify-start.vue'),
	children: [
		{
			path: `documents`,
			name: 'ira-verify-documents',
			meta: { minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const ira = useIraStore();
				if (ira.verificationStatus === 'AWAITING_DOCUMENTATION') {
					await next();
				} else {
					await next({ name: 'ira-verify-success', replace: true });
				}
			},
			component: () => import('@views/ira/signup/ira-documents.vue')
		},
		{
			path: `success`,
			name: 'ira-verify-success',
			meta: { minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import('@views/ira/verify/ira-verify-success.vue')
		}
	]
};

export default iraVerifyRoutes;
