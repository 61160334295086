import {
	AccountValueChartTimeHorizonType,
	NetReturnsOverTimeByTimeHorizon,
	NetReturnsOverTimeData
} from 'types/account';
import {
	AssetClassV2,
	PerformanceOverview,
	PerformanceOverviewByAssetType,
	PerformanceOverviewForDebtOffering,
	PerformanceReturnBreakdown,
	PortfolioAssetCharacteristicsResponse,
	ReturnsByAssetClass
} from 'types/performance-portfolio';
import { investmentEntityApsRequest, investmentEntityRequest } from '@utils/request';
import { AccountValueSummary } from 'types/performance';
import { CustomSpacetimeFormat } from 'types/spacetime-formats';
import spacetime from 'spacetime';

export const getPerformanceOverviewData = async (year?: number, quarter?: number): Promise<PerformanceOverview> => {
	const response = await investmentEntityApsRequest.get('/performance-overview-by-asset-class', {
		params: {
			year,
			quarter
		}
	});
	return response.data;
};

export const getPerformanceOverviewDataByAssetClass = async (
	assetClass: AssetClassV2,
	year?: number,
	quarter?: number
): Promise<PerformanceOverview> => {
	const response = await investmentEntityApsRequest.get(`/asset-class/${assetClass}/performance-overview`, {
		params: {
			year,
			quarter
		}
	});
	return response.data;
};

export const getPerformanceOverviewDataByAssetType = async (
	assetType: string,
	year?: number,
	quarter?: number
): Promise<PerformanceOverviewByAssetType> => {
	const response = await investmentEntityApsRequest.get(`/asset-type/${assetType}/performance-overview`, {
		params: {
			year,
			quarter
		}
	});
	return response.data;
};

export const getDebtOfferingOverview = async (): Promise<PerformanceOverviewForDebtOffering> => {
	const response = await investmentEntityApsRequest.get(`/debt-offering/performance-overview`);
	return response.data;
};

export const getPerformanceReturnBreakdown = async (
	year?: number,
	quarter?: number
): Promise<PerformanceReturnBreakdown> => {
	const response = await investmentEntityApsRequest.get('/returns-breakdown-by-asset-class', {
		params: {
			year,
			quarter
		}
	});
	return response.data;
};

export const getPerformanceReturnBreakdownByAssetClass = async (
	assetClass: AssetClassV2,
	year?: number,
	quarter?: number
): Promise<PerformanceReturnBreakdown> => {
	const response = await investmentEntityApsRequest.get(`/asset-class/${assetClass}/returns-breakdown`, {
		params: {
			year,
			quarter
		}
	});
	return response.data;
};

export const getDebtOfferingPosition = async (debtOfferingId: string) => {
	const response = await investmentEntityApsRequest.get(`/debt-offering/${debtOfferingId}/position`);

	response.data.issueDate = spacetime(response.data.issueDate).format(CustomSpacetimeFormat.MONTH_SHORT_DATE_YEAR);
	response.data.maturityDate = spacetime(response.data.maturityDate).format(
		CustomSpacetimeFormat.MONTH_SHORT_DATE_YEAR
	);
	return response.data;
};

export const getReturnsByAssetClass = async (): Promise<ReturnsByAssetClass> => {
	const response = await investmentEntityApsRequest.get('/returns-by-asset-class');
	return response.data;
};

export const getAssetCharacteristicsByAssetClass = async (
	assetClass: AssetClassV2,
	year?: number,
	quarter?: number
): Promise<PortfolioAssetCharacteristicsResponse> => {
	const response = await investmentEntityRequest.get(`/portfolio/assets/class/${assetClass}/characteristics`, {
		params: {
			year,
			quarter
		}
	});
	return response.data;
};

export const getNetReturnsOverTimeData = async (year?: number, quarter?: number): Promise<NetReturnsOverTimeData> => {
	const response = await investmentEntityApsRequest.get('net-returns-over-time', {
		params: {
			year,
			quarter
		}
	});
	return response.data;
};

export const getNetReturnsOverTimeDataByAssetClass = async (
	assetClass: AssetClassV2,
	year?: number,
	quarter?: number
): Promise<NetReturnsOverTimeData> => {
	const response = await investmentEntityApsRequest.get(`/asset-class/${assetClass}/net-returns-over-time`, {
		params: {
			year,
			quarter
		}
	});
	return response.data;
};

export const getNetReturnsOverTimeDataForIpo = async (): Promise<NetReturnsOverTimeData> => {
	const response = await investmentEntityApsRequest.get('/asset-class/fundrise-ipo/net-returns-over-time');
	return response.data;
};

export const getAccountValueSummary = async (): Promise<AccountValueSummary> => {
	const response = await investmentEntityApsRequest.get('/account-value-summary');
	return response.data;
};

export const getAccountValueModuleChartData = async (
	timeRange: AccountValueChartTimeHorizonType
): Promise<NetReturnsOverTimeByTimeHorizon> => {
	const response = await investmentEntityApsRequest.get('/account-performance-in-time-range', {
		params: {
			timeRange
		}
	});

	return response.data;
};
