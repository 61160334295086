import {
	checkoutAuthGuard,
	navigateToNextStepGuard,
	planMenuGuard,
	resumeCheckoutGuard,
	signupGuard
} from '@router/utils/signup-route-guards';
import { FundriseRouteConfig } from 'vue-router';
import { getInvestFlowRouteLocation } from '@utils/invest';
import { isRepeatInvestor } from '@utils/composables';
import { useAppStore } from '@stores/app';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const checkoutRoutes: FundriseRouteConfig[] = [
	{
		path: '/checkout-resume',
		name: 'checkout-resume',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		beforeEnter: resumeCheckoutGuard,
		component: { render: () => null }
	},
	{
		path: `/reits/checkout`,
		name: 'checkout',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		beforeEnter: signupGuard,
		redirect: { name: 'checkout-resume' },
		component: () => import('@views/checkout/checkout-view.vue'),
		children: [
			{
				path: `/reits/signup-checkout/signup`,
				name: 'checkout-signup',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-account-creation.vue')
			},
			{
				path: `select-country`,
				name: 'checkout-select-country',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-select-country.vue')
			},
			{
				path: `phone-number`,
				name: 'checkout-phone-number',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () =>
					import(/* webpackChunkName: "checkout-phone-number" */ '@views/checkout/checkout-phone-number.vue')
			},
			{
				path: `phone-number-plaid`,
				name: 'checkout-phone-number-plaid-layer',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () =>
					import(
						/* webpackChunkName: "checkout-phone-number-plaid-layer" */ '@views/checkout/checkout-phone-number-plaid-layer.vue'
					)
			},
			{
				path: `select-account-form`,
				name: 'checkout-account-type',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-account-type.vue')
			},
			{
				path: `individual-profile-form`,
				name: 'checkout-individual-account-details',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-individual-account-details.vue')
			},
			{
				path: 'joint-account-profile-form',
				name: 'checkout-joint-account-details',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-joint-account-details.vue')
			},
			{
				path: `entity-profile-form`,
				name: 'checkout-entity-account-details',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-entity-account-details.vue')
			},
			{
				path: 'trust-profile-form',
				name: 'checkout-trust-account-details',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-trust-account-details.vue')
			},
			{
				path: `tax-reporting-form`,
				name: 'checkout-tax-reporting-details',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-tax-reporting-details.vue')
			},
			{
				path: `payment-amount`,
				name: 'checkout-payment-amount',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-payment-amount.vue')
			},
			{
				path: `auto-invest`,
				name: 'checkout-auto-invest',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-auto-invest.vue')
			},
			{
				path: `select-payment-method`,
				name: 'checkout-payment-method',
				meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-payment-method.vue')
			},
			{
				path: 'new-ach-account',
				name: 'checkout-new-bank-account',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				component: () => import('@views/checkout/checkout-new-bank-account.vue')
			},
			{
				path: 'acknowledgments',
				name: 'checkout-acknowledgments',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: navigateToNextStepGuard,
				component: () => import('@views/checkout/checkout-acknowledgments.vue')
			},
			{
				path: 'review-order',
				name: 'checkout-review-order',
				meta: { minTokenScopeRequired: 'UNVERIFIED' },
				beforeEnter: async (to, from, next) => {
					if (from.name === 'checkout-acknowledgments') {
						next();
					} else {
						next({ name: 'checkout-acknowledgments', replace: true });
					}
				},
				component: () => import('@views/checkout/checkout-review-order.vue')
			}
		]
	},
	{
		path: '/reits/checkout/select-investment-list',
		name: 'plan-menu',
		meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
		beforeEnter: planMenuGuard,
		component: () => import('@views/checkout/checkout-plan-menu.vue')
	},
	{
		path: '/reits/checkout/select-investment-detail/:planId',
		name: 'investment-details',
		meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
		beforeEnter: planMenuGuard,
		component: () => import('@views/checkout/checkout-investment-details.vue')
	},
	{
		path: `/reits/checkout/country-not-supported`,
		name: 'checkout-country-not-supported',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		component: () => import('@views/checkout/checkout-country-not-supported.vue')
	},
	{
		path: `/reits/checkout/preset-investment-confirmation`,
		name: 'checkout-preset-investment-confirmation',
		meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
		beforeEnter: signupGuard,
		component: () => import('@views/checkout/checkout-preset-investment-confirmation.vue')
	},
	{
		path: '/reits/checkout/preset-investment-success',
		name: 'checkout-preset-investment-success',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/checkout/checkout-preset-investment-success.vue')
	},
	{
		path: '/reits/checkout/checkout-preset-plan-confirmation',
		name: 'checkout-preset-plan-confirmation',
		meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
		beforeEnter: signupGuard,
		component: () => import('@views/checkout/checkout-preset-plan-confirmation.vue')
	},
	{
		path: `/reits/checkout/success`,
		name: 'reits-checkout-success',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/checkout/checkout-success.vue')
	},
	{
		path: `/reits/checkout/investment-recommendation`,
		name: 'investment-recommendation',
		meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
		beforeEnter: signupGuard,
		component: () => import('@views/checkout/checkout-investment-recommendation.vue')
	},
	{
		path: '/checkout/signup-success',
		name: 'checkout-signup-success-interstitial',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		component: () => import('@views/checkout/checkout-signup-success-interstitial.vue')
	},
	{
		path: `/questionnaire`,
		name: 'checkout-personal-info-intro',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/checkout/checkout-view.vue'),
		redirect: { name: 'checkout-personal-info' },
		beforeEnter: async (to, from, next) => {
			const appStore = useAppStore();
			const isMaintenanceWindow = await appStore.isFeatureEnabled('app-down-maintenance-window');
			if (isMaintenanceWindow) {
				next({ name: 'maintenance' });
			} else {
				if (isRepeatInvestor.value) {
					next(getInvestFlowRouteLocation('plan'));
				}

				if (to.query.new_lead) {
					next({ name: 'checkout-password' });
				} else {
					next();
				}
			}
		},
		children: [
			{
				path: `personal-info`,
				name: 'checkout-personal-info',
				meta: {
					minTokenScopeRequired: 'UNAUTHENTICATED'
				},
				beforeEnter: checkoutAuthGuard,
				component: () => import('@views/checkout/checkout-personal-info.vue')
			},
			{
				path: `password`,
				name: 'checkout-password',
				meta: {
					minTokenScopeRequired: 'UNAUTHENTICATED'
				},
				beforeEnter: checkoutAuthGuard,
				component: () => import('@views/checkout/checkout-password.vue')
			}
		]
	}
];

export default checkoutRoutes;
