<template>
	<div :class="props.backgroundColorClass" class="page" :data-dd-privacy="datadogPrivacyLevel">
		<div :class="{ 'page__main--full-height': props.verticallyCentered }" class="page__main">
			<transition name="transition--fade" mode="out-in">
				<slot v-if="!appStore.isMobileWebview && !hideHeader" name="header">
					<AppHeader
						class="page__header"
						:transparent-header="props.transparentHeader"
						:transparent-header-dark-text="props.transparentHeaderDarkText"
						data-context="header"
					/>
				</slot>
			</transition>

			<div
				id="main-content"
				:class="{ 'page__content--vertically-centered': props.verticallyCentered }"
				class="page__content"
				data-context="main"
			>
				<div
					:class="{
						'page__content-inner--padded': props.includePadding,
						'page__content--full-height': props.contentFullHeight
					}"
					class="page__content-inner"
				>
					<slot />
				</div>
			</div>
		</div>
		<div v-if="!appStore.isMobileWebview" id="footer">
			<slot name="footer">
				<component :is="footerComponent.component" v-bind="footerComponent.props" data-context="footer" />
			</slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import AppHeader from '@components/header/app-header.vue';
import { computed } from 'vue';
import { DynamicComponent } from 'types/vue';
import FooterBasic from '@components/footer/footer-basic.vue';
import FooterFull from '@components/footer/footer-full.vue';
import { useAppStore } from '@stores/app';

interface Props {
	footerFull?: boolean;
	transparentHeader?: boolean;
	transparentHeaderDarkText?: boolean;
	verticallyCentered?: boolean;
	includePadding?: boolean;
	backgroundColorClass?: string;
	contentFullHeight?: boolean;
	footnotes?: Array<string>;
	isVenture?: boolean;
	hideHeader?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	footerFull: false,
	transparentHeader: false,
	transparentHeaderDarkText: false,
	verticallyCentered: false,
	includePadding: true,
	backgroundColorClass: 'bg-color-bg-secondary',
	contentFullHeight: false,
	footnotes: () => [],
	isVenture: false,
	hideHeader: false
});

const appStore = useAppStore();

const footerComponent = computed((): DynamicComponent => {
	if (props.footerFull) {
		return {
			component: FooterFull,
			props: {
				footnotes: props.footnotes,
				isVenture: props.isVenture
			}
		};
	} else {
		return {
			component: FooterBasic
		};
	}
});

const datadogPrivacyLevel = computed(() => {
	return appStore.tokenScope === 'UNAUTHENTICATED' ? 'mask-user-input' : 'mask';
});
</script>
