<template>
	<BaseLinkButton v-if="isLink" :classes="classes" @click="handleClick()">{{ buttonText }}</BaseLinkButton>
	<BaseButton v-else :classes="classes" :is-working="isWorking" :size="buttonSize" @click="handleClick()">{{
		buttonText
	}}</BaseButton>
</template>

<script lang="ts">
export default {
	name: 'AddFundsButton'
};
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { IraEntityMetadata } from 'types/investment-entity';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useRouter } from 'vue-router';
interface Props {
	classes?: string;
	isLink?: boolean;
	buttonSize?: 'medium' | 'mini';
}

const investmentEntityStore = useInvestmentEntityStore();

withDefaults(defineProps<Props>(), {
	classes: '',
	isLink: false,
	buttonSize: undefined
});

const router = useRouter();

const isWorking = ref(false);

const buttonText = computed((): string => {
	if (isRedeemedNoDirectInvestNoPlanAccount.value) {
		return 'Pick a plan';
	} else if (isMtcIra.value && !isIraOnboardingFinished.value) {
		return 'Finish setup';
	} else if (isMtcIra.value || riaPlanName.value) {
		return 'Add funds';
	} else if (investmentEntityStore.hasNeverBeenAnInvestor) {
		return 'Continue investing';
	} else {
		return 'View offerings';
	}
});

const isRedeemedNoDirectInvestNoPlanAccount = computed((): boolean => {
	return investmentEntityStore.isRedeemedNoDirectInvestNoPlanAccount;
});

const isIraOnboardingFinished = computed((): boolean => {
	return iraEntityData.value?.isIraOnboardingFinished ?? false;
});

const isMtcIra = computed((): boolean => {
	return iraEntityData.value?.isMtcIra ?? false;
});

const iraEntityData = computed((): IraEntityMetadata | null => {
	return investmentEntityStore.iraEntityMetadata;
});

const riaPlanName = computed((): string => {
	return investmentEntityStore.currentRiaPlanName;
});

async function handleClick(): Promise<void> {
	isWorking.value = true;
	try {
		if (isRedeemedNoDirectInvestNoPlanAccount.value) {
			await router.push({ name: 'ria-upgrade' });
		} else if (isMtcIra.value) {
			if (!isIraOnboardingFinished.value) {
				await router.push({ name: 'ira-start' });
			} else {
				await router.push({ name: 'ira-add-funds-start' });
			}
		} else if (riaPlanName.value) {
			await router.push({ name: 'account-invest' });
		} else if (investmentEntityStore.hasNeverBeenAnInvestor) {
			await router.push({ name: 'checkout-resume' });
		} else {
			await router.push({ name: 'offerings' });
		}
	} catch (error) {
	} finally {
		isWorking.value = false;
	}
}
</script>
