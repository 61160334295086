import * as Sentry from '@sentry/vue';
import { Address, TaxInfo } from 'types/form';
import { computed, ref } from 'vue';
import { CreateThirdPartyUserResponse, PhoneData, PhoneNumber, User, UserSignupForm } from 'types/user';
import {
	createUser as createUserApi,
	getPhoneData,
	getUserAddress,
	getUser as getUserApi,
	setUserCountry,
	setUserFirstAndLastNames,
	setUserPhoneNumber as setUserPhoneNumberApi,
	updateContactDetails,
	updateTaxDetails
} from '@api/user';
import { getKnownLoginDevices, updateEmailAddress as updateEmailAddressApi } from '@api/settings';
import {
	getOauthAuthorizations,
	revokeOauthAuthorization as revokeOauthAuthorizationApi
} from '@api/oauth-authorization';
import { getTwoFactorStatus, getUserLoginMethods } from '@api/login';
import { identifyMixpanel, trackMixpanelEvent } from '@utils/analytics';
import { LoginMethods, TwoFactorType } from 'types/authentication';
import { datadogRum } from '@datadog/browser-rum';
import { defineStore } from 'pinia';
import { getInvitationDetails } from '@api/referral';
import { getItemFromLocalStorage } from '@utils/web-storage';
import { getShareholderVoteDetails } from '@api/shareholder-vote';
import { getUserEntityById } from '@utils/user';
import { IndividualAccountContactDetails } from 'types/checkout';
import { InvestmentEntity } from 'types/investment-entity';
import { InvitationDetailsResponse } from 'types/referral';
import { KnownDevice } from 'types/settings';
import { logError } from '@utils/error-tracking';
import { OAuthAuthorizationStatus } from 'types/oauth-authorization';
import { RouteLocationRaw } from 'vue-router';
import router from '@router';
import { ShareholderVoteDetail } from 'types/shareholder-vote';
import spacetime from 'spacetime';
import { submitIndividualAccountTaxReportingDetails } from '@api/checkout';
import { SubscriptionStatus } from 'types/fundrise-pro';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useSignupStore } from '@stores/signup';

export const useUserStore = defineStore('user', () => {
	const user = ref<User | null>(null);
	const address = ref<Address>({
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		countryCode: ''
	});
	const devices = ref<Array<KnownDevice>>([]);
	const authorizations = ref<Array<OAuthAuthorizationStatus>>([]);
	const invitationDetails = ref<InvitationDetailsResponse | null>(null);
	const shareholderVoteDetail = ref<ShareholderVoteDetail | null>(null);
	const phoneData = ref<PhoneData>({
		twoFactorPhoneNumber: '',
		currentPhoneNumber: '',
		currentPhoneNumberValidated: false,
		allPhoneNumbers: []
	});
	const twoFactorType = ref<TwoFactorType | null>(null);
	const phoneNumberToVerify = ref<string | null>(null);
	const loginMethods = ref<LoginMethods | null>(null);
	const suggestedFirstName = ref('');
	const suggestedLastName = ref('');

	const countriesSubmitted = computed((): boolean => {
		return !!(user.value?.citizenshipCountryCode && user.value.residenceCountryCode);
	});

	const twoFactorEnabled = computed((): boolean => {
		return !!twoFactorType.value && twoFactorType.value !== 'NONE';
	});

	const isExistingInvestor = computed((): boolean => {
		return !!user.value?.entityDetails?.firstInvestmentDate;
	});

	const hasAddress = computed((): boolean => {
		return !!address.value.address1;
	});

	const hasIraEntity = computed((): boolean => {
		return user.value?.entityDetails?.investmentEntities.some((entity) => entity.entityType === 'IRA') ?? false;
	});

	const hasFirstInvestment = computed((): boolean => {
		return !!user.value?.entityDetails?.firstInvestmentDate;
	});

	const isProOrPremium = computed((): boolean => {
		return isSubscriptionActive.value || isPremiumUser.value;
	});

	const isSubscriptionActive = computed((): boolean => {
		return !!user.value?.isSubscriptionActive;
	});

	const residenceValid = computed((): boolean => {
		return user.value?.residenceCountryCode === 'US';
	});

	const subscriptionStatus = computed((): SubscriptionStatus => {
		return user.value?.subscriptionStatus ?? 'INACTIVE';
	});

	const canCancelFundrisePro = computed((): boolean => {
		const cancellableStates: SubscriptionStatus[] = [
			'ACTIVE',
			'ACTIVE_PAST_DUE',
			'ACTIVE_TRIAL',
			'ACTIVE_LIFETIME'
		];

		return cancellableStates.includes(subscriptionStatus.value);
	});

	const hasActiveProStatus = computed((): boolean => {
		const activeStates: SubscriptionStatus[] = [
			'ACTIVE',
			'ACTIVE_CANCELLED',
			'ACTIVE_PAST_DUE',
			'ACTIVE_LIFETIME',
			'ACTIVE_TRIAL'
		];

		return activeStates.includes(subscriptionStatus.value);
	});

	const hasCancelledFundrisePro = computed((): boolean => {
		const cancelledStates = ['ACTIVE_CANCELLED', 'INACTIVE_CANCELLED'];

		return cancelledStates.includes(subscriptionStatus.value);
	});

	const hasFirstLastName = computed((): boolean => {
		return !!(user.value?.firstName && user.value?.lastName);
	});

	const hasDateOfBirth = computed((): boolean => {
		return !!user.value?.hasSubmittedDob;
	});

	const hasEmailAddress = computed((): boolean => {
		return !!user.value?.email;
	});

	const getDeviceById = computed(() => (deviceId: string): KnownDevice | undefined => {
		return devices.value.find((device) => device.deviceId === deviceId);
	});

	const ssnNeedsUpdating = computed((): boolean => {
		return user.value?.ssnNeedsUpdating || !user.value?.hasSubmittedSsn;
	});

	const iraEntities = computed((): Array<InvestmentEntity> => {
		return user.value?.entityDetails?.investmentEntities.filter((entity) => entity.entityType === 'IRA') ?? [];
	});

	const isEligibleForShareholderVote = computed((): boolean => {
		return user.value?.entityDetails?.investmentEntities.some((ie) => ie.eligibleForShareholderVote) ?? false;
	});

	const isPremiumUser = computed((): boolean => {
		return user.value?.isPremiumUser || false;
	});

	const hasAnyAdvisorRelationship = computed((): boolean => {
		return !!(
			user.value?.investmentAdvisorRelationshipDetailsResponse?.hasActiveRelationship ||
			user.value?.investmentAdvisorRelationshipDetailsResponse?.hasPendingRelationshipRequest
		);
	});

	const hasPasswordLoginMethod = computed((): boolean => {
		return loginMethods.value?.password || false;
	});

	const isInvestedForLessThanAYear = computed((): boolean => {
		const firstInvestmentDate = user.value?.entityDetails?.firstInvestmentDate;

		if (!firstInvestmentDate) return false;

		const investmentDate = spacetime(firstInvestmentDate);
		const oneYearAgo = spacetime.now().subtract(1, 'year');

		return investmentDate.isAfter(oneYearAgo);
	});

	async function storeLoginMethods(): Promise<void> {
		const appStore = useAppStore();
		if (!loginMethods.value && appStore.isVerified) {
			const loginMethodsResponse = await getUserLoginMethods();
			loginMethods.value = loginMethodsResponse;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async function createUser(payload: { userSignupForm: UserSignupForm }): Promise<any> {
		try {
			const appStore = useAppStore();
			const response = await createUserApi(payload.userSignupForm);
			appStore.setUserAuthData(response);
			await getUser();
			return undefined;
		} catch (error) {
			return error;
		}
	}

	async function getUser(): Promise<void> {
		const [updatedUser] = await Promise.all([getUserApi(), storeLoginMethods()]);
		await storeAndIdentifyUser(updatedUser);
	}

	async function setThirdPartyUser(thirdPartyUser: CreateThirdPartyUserResponse): Promise<void> {
		await Promise.all([storeLoginMethods(), storeAndIdentifyUser(thirdPartyUser)]);

		suggestedFirstName.value = thirdPartyUser?.suggestedFirstName ?? '';
		suggestedLastName.value = thirdPartyUser?.suggestedLastName ?? '';
	}

	async function storeAndIdentifyUser(updatedUser: User): Promise<void> {
		user.value = updatedUser;
		const appStore = useAppStore();
		const investmentEntityStore = useInvestmentEntityStore();
		if (!appStore.isReadOnlyAccess) {
			const mixpanelId = `${updatedUser.mixpanelId}`;
			await identifyMixpanel(mixpanelId);
		}

		Sentry.setUser({
			id: updatedUser.id,
			mixpanelId: updatedUser.mixpanelId
		});

		datadogRum.setUser({
			id: updatedUser.id,
			accountCreatedDate: spacetime(updatedUser.entityDetails?.firstInvestmentDate).format('iso-short'),
			isPro: isSubscriptionActive.value,
			proStatus: subscriptionStatus.value
		});

		const entity = await getSelectedInvestmentEntity(updatedUser);
		if (!appStore.isAdvisor && entity) {
			await investmentEntityStore.setInvestmentEntity(entity);
		}

		if (!appStore.isAdvisor && entity && entity.userReferralEligibilityStatus !== 'INELIGIBLE') {
			await setInvitationDetails();
		}
	}

	async function getSelectedInvestmentEntity(selectedUser: User): Promise<InvestmentEntity | null> {
		if (!selectedUser.entityDetails) {
			return null;
		}

		const lastSelectedEntityId = getItemFromLocalStorage('lastSelectedInvestmentEntity');
		return getUserEntityById(lastSelectedEntityId ?? '');
	}

	async function userHasInvestmentEntity(investmentEntityId: string): Promise<boolean> {
		let hasInvestmentEntity =
			user.value?.entityDetails?.investmentEntities?.some(
				(entity) => entity.investmentEntityId === investmentEntityId
			) ?? false;

		if (!hasInvestmentEntity) {
			await getUser();

			hasInvestmentEntity =
				user.value?.entityDetails?.investmentEntities?.some(
					(entity) => entity.investmentEntityId === investmentEntityId
				) ?? false;
		}

		return hasInvestmentEntity;
	}

	async function updateInvestmentEntityAndTrackEvents(investmentEntityId: string): Promise<void> {
		const investmentEntityStore = useInvestmentEntityStore();
		const entityIsValid = await userHasInvestmentEntity(investmentEntityId);
		const previousEntity = user.value?.entityDetails?.investmentEntities.find(
			(entity) => entity.investmentEntityId === investmentEntityStore.investmentEntityId
		);
		const newEntity = user.value?.entityDetails?.investmentEntities.find(
			(entity) => entity.investmentEntityId === investmentEntityId
		);

		if (entityIsValid && newEntity) {
			await investmentEntityStore.setInvestmentEntity(newEntity);

			trackMixpanelEvent('Selected Investment Entity Switched', {
				switchedFrom: previousEntity?.investmentEntityId,
				switchedTo: investmentEntityId,
				fromInvestmentEntityType: previousEntity?.entityType ?? '',
				toInvestmentEntityType: newEntity?.entityType
			});
		} else {
			const error = new Error('Cannot switch to entity not associated with user.');
			logError(error, 'info');
			return Promise.reject(error);
		}
	}

	async function updateSelectedEntity(investmentEntityId: string): Promise<void> {
		await updateInvestmentEntityAndTrackEvents(investmentEntityId);
		router.go(0);
	}

	async function updateSelectedEntityWithoutReload({
		investmentEntityId,
		nextRoute
	}: {
		investmentEntityId: string;
		nextRoute: { route: RouteLocationRaw; return?: boolean; next?: (to?: RouteLocationRaw) => void };
	}): Promise<RouteLocationRaw | undefined> {
		await updateInvestmentEntityAndTrackEvents(investmentEntityId);

		try {
			if (nextRoute.return) {
				return nextRoute.route;
			} else if (nextRoute.next) {
				nextRoute.next(nextRoute.route);
			} else {
				router.push(nextRoute.route);
			}
		} catch {}
	}

	async function refreshUser(): Promise<void> {
		await getUser();
		const investmentEntityStore = useInvestmentEntityStore();
		await investmentEntityStore.refreshPositionsAndPerformance();
	}

	async function storeAddress(force = false): Promise<void> {
		if (!hasAddress.value || force) {
			const addressData = await getUserAddress();
			if (addressData) {
				address.value = addressData;
			}
		}
	}

	async function setInvitationDetails(): Promise<void> {
		const invitationDetailsData = await getInvitationDetails();
		invitationDetails.value = invitationDetailsData;
	}

	async function storeShareholderVoteDetails(): Promise<void> {
		const voteDetails = await getShareholderVoteDetails();
		shareholderVoteDetail.value = voteDetails;
	}

	async function storePhoneData(force = false): Promise<void> {
		if (!phoneData.value.currentPhoneNumber || force) {
			const phoneDataResponse = await getPhoneData();
			phoneData.value = phoneDataResponse;
		}
	}

	async function setFirstAndLastNames(payload: { firstName: string; lastName: string }): Promise<void> {
		await setUserFirstAndLastNames(payload.firstName, payload.lastName);
		await getUser();
	}

	async function setCountrySelections(payload: {
		citizenshipCountryCode: string;
		residenceCountryCode: string;
	}): Promise<void> {
		await setUserCountry({
			citizenshipCountryCode: payload.citizenshipCountryCode,
			residenceCountryCode: payload.residenceCountryCode
		});
		await getUser();
	}

	async function storeDevices(): Promise<void> {
		if (devices.value.length === 0) {
			const devicesResponse = await getKnownLoginDevices();
			devices.value = devicesResponse;
		}
	}

	function clearDevices(): void {
		if (devices.value.length > 0) {
			devices.value = [];
		}
	}

	async function setUserPhoneNumber(payload: {
		phoneNumber: string;
		isPlaidLayer?: boolean;
	}): Promise<RouteLocationRaw> {
		const signupStore = useSignupStore();
		await setUserPhoneNumberApi(payload.phoneNumber);
		signupStore.phoneNumber = payload.phoneNumber;
		return signupStore.completeStep(payload.isPlaidLayer ? 'PHONE_NUMBER_PLAID_LAYER_STEP' : 'PHONE_NUMBER_STEP');
	}

	async function setUserContactDetails(payload: IndividualAccountContactDetails): Promise<RouteLocationRaw> {
		const signupStore = useSignupStore();
		await updateContactDetails(payload);
		return signupStore.completeStep('CONTACT_INFORMATION_STEP');
	}

	async function setUserTaxDetails(payload: TaxInfo): Promise<RouteLocationRaw> {
		const signupStore = useSignupStore();
		await submitIndividualAccountTaxReportingDetails(payload);
		await getUser();
		return signupStore.completeStep();
	}

	async function setUserTaxDetailsFromProfile(payload: TaxInfo): Promise<void> {
		await updateTaxDetails(payload);
		refreshUser();
	}

	async function storeTwoFactorType(force = false): Promise<void> {
		if (!twoFactorType.value || force) {
			const twoFactorTypeResponse = await getTwoFactorStatus();
			twoFactorType.value = twoFactorTypeResponse;
		}
	}

	async function updateEmailAddress(updatedEmail: string): Promise<void> {
		await updateEmailAddressApi(updatedEmail);
		await getUser();
	}

	async function setPhoneNumberToVerify(phoneNumber: string | null): Promise<void> {
		phoneNumberToVerify.value = phoneNumber;
	}

	async function getAuthorizations(): Promise<void> {
		const authorizationsResponse = await getOauthAuthorizations();
		authorizations.value = authorizationsResponse;
	}

	async function revokeAuthorization(clientId: string): Promise<void> {
		await revokeOauthAuthorizationApi(clientId);
		await getAuthorizations();
	}

	async function addPhoneNumber(newPhone: PhoneNumber): Promise<void> {
		if (!phoneData.value.allPhoneNumbers.some((existingPhone) => existingPhone.id === newPhone.id)) {
			phoneData.value.allPhoneNumbers.push(newPhone);
		}
	}

	function $reset(): void {
		user.value = null;
		address.value = {
			address1: '',
			address2: '',
			city: '',
			state: '',
			zip: '',
			countryCode: ''
		};
		devices.value = [];
		invitationDetails.value = null;
		shareholderVoteDetail.value = null;
		phoneData.value = {
			twoFactorPhoneNumber: '',
			currentPhoneNumber: '',
			currentPhoneNumberValidated: false,
			allPhoneNumbers: []
		};
		twoFactorType.value = null;
		phoneNumberToVerify.value = null;
		authorizations.value = [];
		loginMethods.value = null;
		suggestedFirstName.value = '';
		suggestedLastName.value = '';
	}

	return {
		user,
		address,
		devices,
		authorizations,
		invitationDetails,
		shareholderVoteDetail,
		phoneData,
		twoFactorType,
		phoneNumberToVerify,
		loginMethods,
		suggestedFirstName,
		suggestedLastName,
		countriesSubmitted,
		twoFactorEnabled,
		isExistingInvestor,
		hasAddress,
		hasIraEntity,
		hasFirstInvestment,
		isProOrPremium,
		isSubscriptionActive,
		residenceValid,
		subscriptionStatus,
		canCancelFundrisePro,
		hasActiveProStatus,
		hasCancelledFundrisePro,
		hasFirstLastName,
		hasDateOfBirth,
		hasEmailAddress,
		getDeviceById,
		ssnNeedsUpdating,
		iraEntities,
		isEligibleForShareholderVote,
		isPremiumUser,
		hasAnyAdvisorRelationship,
		hasPasswordLoginMethod,
		isInvestedForLessThanAYear,
		storeLoginMethods,
		createUser,
		getUser,
		setThirdPartyUser,
		updateInvestmentEntityAndTrackEvents,
		updateSelectedEntity,
		updateSelectedEntityWithoutReload,
		refreshUser,
		storeAddress,
		storeShareholderVoteDetails,
		storePhoneData,
		setFirstAndLastNames,
		setCountrySelections,
		storeDevices,
		clearDevices,
		setUserPhoneNumber,
		setUserContactDetails,
		setUserTaxDetails,
		setUserTaxDetailsFromProfile,
		storeTwoFactorType,
		updateEmailAddress,
		setPhoneNumberToVerify,
		getAuthorizations,
		revokeAuthorization,
		addPhoneNumber,
		$reset
	};
});
