import { apiRequest, apiSecureRequest } from '@utils/request';
import { InvitationDetailsResponse, InvitationPromoRewardAmounts, InviteUser, ReferrerUser } from 'types/referral';

export async function getInvitationDetails(): Promise<InvitationDetailsResponse> {
	return apiSecureRequest.get('/promo/user/invitation/details').then((response) => response.data);
}

export async function sendInviteEmails(inviteEmails: Array<InviteUser>): Promise<void> {
	await apiSecureRequest.post('/invite-email/new', inviteEmails);
}

export async function getReferralByKey(referralKey: string): Promise<ReferrerUser> {
	const response = await apiRequest.get(`/promo/referrer-by-key/${referralKey}`);
	const referrerUser: ReferrerUser = response.data;
	return referrerUser;
}

export async function getInviteAmounts(): Promise<InvitationPromoRewardAmounts> {
	return apiSecureRequest.get('/promo/user/invitation/amounts').then((response) => response.data);
}
