import { getItemFromSessionStorage, removeItemFromSessionStorage } from '@utils/web-storage';
import { includesVerifyRoute, routesThatRequireEntitySelectionPostLogin } from '@router/auth-guard-helpers';
import { RouteLocationRaw } from 'vue-router';
import { storageKeyLoginRedirect } from '@constants/storage-keys';
import { useAppStore } from '@stores/app';
import { useInvestmentEntityStore } from '@stores/investment-entity';
import { useUserStore } from '@stores/user';

export function handleLoginRedirect(loginRedirect: string): RouteLocationRaw {
	const userStore = useUserStore();
	const entityList = userStore.user?.entityDetails?.investmentEntities ?? [];
	const loginRedirectRequiresEntitySelection =
		entityList.length > 1 &&
		routesThatRequireEntitySelectionPostLogin.some((element) => loginRedirect.includes(element));
	removeItemFromSessionStorage(storageKeyLoginRedirect);

	if (loginRedirectRequiresEntitySelection) {
		return { path: loginRedirect, query: { investmentEntityId: 'select' } };
	} else {
		return loginRedirect;
	}
}

export function determineUserDashboardRoute() {
	const appStore = useAppStore();
	const investmentEntityStore = useInvestmentEntityStore();
	if (appStore.isAdvisor) {
		return { name: 'advisor-dashboard' };
	}
	if (investmentEntityStore.hasNeverBeenAnInvestor) {
		return { name: 'user-overview' };
	}
	return { name: 'account-overview' };
}

export async function determineLoginRoute(forceGetUser = false): Promise<RouteLocationRaw> {
	const loginRedirect = getItemFromSessionStorage(storageKeyLoginRedirect);
	const loginRedirectIncludesVerifyRoute = loginRedirect && includesVerifyRoute(loginRedirect);
	const appStore = useAppStore();
	const investmentEntityStore = useInvestmentEntityStore();
	const userStore = useUserStore();

	if (appStore.isAuthenticated && (!userStore.user || forceGetUser)) {
		await userStore.getUser();
	}

	const userNeedsToVerifyEmail = !appStore.isVerified && !investmentEntityStore.hasNeverBeenAnInvestor;
	const userNeedsToVerifyMobile =
		userStore.user?.shouldPromptUserForMobileVerify && !investmentEntityStore.hasNeverBeenAnInvestor;

	if (appStore.tokenScope === 'GOOGLE_AUTH_SETUP') {
		return { name: 'login-verify-link-google' };
	}

	if (appStore.tokenScope === 'APPLE_AUTH_SETUP') {
		return { name: 'login-verify-link-apple' };
	}

	if (appStore.tokenScope.includes('REQUIRES_ADDITIONAL_AUTH')) {
		return { name: 'login-two-factor' };
	}

	if (!appStore.isVerified && loginRedirectIncludesVerifyRoute) {
		return handleLoginRedirect(loginRedirect);
	}

	if (userNeedsToVerifyEmail) {
		return { name: 'unverified-email' };
	}

	if (userNeedsToVerifyMobile) {
		return { name: 'login-mobile-verify' };
	}

	// New login-redirect logic should be added here.

	if (loginRedirect) {
		return handleLoginRedirect(loginRedirect);
	}

	return determineUserDashboardRoute();
}
