import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { CaptchaError } from 'types/error-tracking';
import { getWafCaptchaToken } from '@utils/waf';
import { logError } from '@utils/error-tracking';
import { useAppStore } from '@stores/app';
import WafCaptchaModal from '@components/modals/waf-captcha-modal.vue';

let isChallengeFetching = false;
let challengeSubscribers: Array<(args: string) => void> = [];

function addChallengeSubscriber(cb: (args: string) => void): void {
	challengeSubscribers.push(cb);
}

function onChallengeTokenFetched(token: string): void {
	challengeSubscribers.forEach((callback) => callback(token));
	challengeSubscribers = [];
}

/**
 * When we receive status code 202 that has a 'x-amzn-waf-action' header of challenge,
 * this retries the request with a new 'x-aws-waf-token' header
 */
export async function WafChallengeResponse(response: AxiosResponse, instance: AxiosInstance): Promise<unknown> {
	const { config } = response;
	const challengeStatusCode = 202;
	const hasChallengeStatus = response?.status === challengeStatusCode;
	const hasChallengeHeader = response?.headers['x-amzn-waf-action'] === 'challenge';

	if (!hasChallengeStatus || !hasChallengeHeader) {
		return Promise.resolve(response);
	}

	if (!isChallengeFetching) {
		isChallengeFetching = true;
		getWafCaptchaToken().then((wafToken) => {
			isChallengeFetching = false;
			onChallengeTokenFetched(wafToken);
		});
	}

	return new Promise((resolve, reject) => {
		addChallengeSubscriber((token: string) => {
			config.headers = { ...config.headers, ...{ 'x-aws-waf-token': token } };
			instance(config).then(resolve).catch(reject);
		});
	});
}

let isCaptchaFetching = false;
let captchaSubscribers: Array<(args: string) => void> = [];

function addCaptchaSubscriber(cb: (args: string) => void): void {
	captchaSubscribers.push(cb);
}

function onCaptchaTokenFetched(token: string): void {
	captchaSubscribers.forEach((callback) => callback(token));
	captchaSubscribers = [];
}

function onCaptchaError(err: AxiosError): void {
	captchaSubscribers.forEach(() => Promise.reject(err));
	captchaSubscribers = [];
}

/**
 * When we receive status code 405 that has a 'x-amzn-waf-action' header of captcha,
 * this renders the WAF captcha modal and handles success/failure.
 */
export async function WafCaptchaResponse(err: AxiosError, instance: AxiosInstance): Promise<unknown> {
	const { config } = err;
	const wafStatusCode = 405;
	const hasWafErrorStatus = err?.response?.status === wafStatusCode;
	const wafActionHeader = err.response?.headers['x-amzn-waf-action'];
	const wafActionIsCaptcha = wafActionHeader === 'captcha';
	const showWafCaptcha = hasWafErrorStatus && wafActionIsCaptcha;

	if (hasWafErrorStatus && wafActionHeader === undefined) {
		logError('405 without x-amzn-waf-action header exposed', 'error');
	}

	if (!showWafCaptcha) {
		return Promise.reject(err);
	}

	if (!isCaptchaFetching) {
		const appStore = useAppStore();
		isCaptchaFetching = true;
		appStore.updateCurrentModal({
			modal: WafCaptchaModal,
			props: {
				resolve: async (captchaToken: string) => {
					isCaptchaFetching = false;
					onCaptchaTokenFetched(captchaToken);
				},
				reject: (captchaError: CaptchaError) => {
					isCaptchaFetching = false;
					logError(captchaError, 'error', { key: 'waf-captcha-error', data: { ...captchaError } });
					onCaptchaError(err);
				}
			}
		});
	}

	return new Promise((resolve, reject) => {
		addCaptchaSubscriber((token: string) => {
			config.headers = { ...config.headers, ...{ 'x-aws-waf-token': token } };
			instance(config).then(resolve).catch(reject);
		});
	});
}

export function attachWafChallengeCaptchaInterceptor(instance: AxiosInstance): number {
	return instance.interceptors.response.use(
		(response: AxiosResponse) => WafChallengeResponse(response, instance),
		(err: AxiosError) => WafCaptchaResponse(err, instance)
	);
}
